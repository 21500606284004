'use strict';
var floatLabel = require('core/floatLabel');
var clientSideValidation = require('core/components/clientSideValidation');
var klarnaClientUtils = require('../klarnaClientUtils');

/** Constants */
const $body = $('body');

/**
 * Insert the google recaptcha script into header
 */
function appendGoogleRecaptcha() {
  const $this = $(this);
  const $giftCardFormGroup = $('.gift-card-form-group');

  if (!$('#recaptcha').length) {
    const $recaptcha = $('meta[name="async-captcha"]');

    $('<script>', {
      id: 'recaptcha',
      src: $recaptcha.attr('content')
    }).appendTo('head');
  }
}

/**
 * fill modal gc form
 *
 * @param {string} checkBalanceUrl - check balance url to be hit
 * @param {string} addGiftCardUrl - add gift card url to be hit
 * Bellow parameters are used for the flow under function checkIfIncompatibleWithPromoEgc on GiftCard-AddGiftCard pipeline
 * @param {string} gcNumber - Gift Card number explicitly passed in case of removing an incompatible promotion
 * @param {string} gcPinCode - Gift Pin number explicitly passed in case of removing an incompatible promotion
 *
 */
function checkBalance(checkBalanceUrl, addGiftCardUrl, gcNumber, gcPinCode) {
  $.spinner().start();
  const $recaptchaToken = $('.g-recaptcha-token');
  var gcNumb = '';
  var gcPin = '';
  if (!gcNumber && !gcPinCode) {
    var gcNumb = $('.gift-card-number .cardNumber').val();
    var gcPin = $('.gift-card-pin .accessNumber').val();
  } else {
    gcNumb = gcNumber;
    gcPin = gcPinCode;
  }

  $('.number-error-required').toggleClass('d-none', !!gcNumb); // ajay to work when to add/remove is-invalid class and span
  $('.pin-error-required').toggleClass('d-none', !!gcPin); // ajay to work when to add/remove is-invalid class and span
  if (!gcNumb || !gcPin) {
    clientSideValidation.checkValidationOnAjax($('.gift-card-form-group'), false, true);
    $('.generic-error').empty().addClass('d-none');
    $.spinner().stop();
    return;
  }
  $.ajax({
    url: addGiftCardUrl,
    method: 'POST',
    data: {
      token: $recaptchaToken.val(),
      apiKey: $recaptchaToken.data('secret'),
      gcNumber: gcNumb,
      gcPin: gcPin,
      checkout: true
    },
    success: function (resp) {
      const $genericError = $('.generic-error');

      if (resp.success) {
        clientSideValidation.checkValidationOnAjax($('.gift-card-form-group'), true, true);
        $genericError.empty().addClass('d-none');
        floatLabel.resetFloatLabel();
        // logic to show back the gift card added
        if (resp.error) {
          $genericError.removeClass('d-none').html(resp.errorMessage);
        } else {
          $genericError.addClass('d-none');
          if (resp.order.giftCard.hasGiftCard) {
            $('.gift-card-applied').html(resp.order.giftCard.giftCardHtml);
            $('.order-summary-payment-applied').html(resp.order.orderSummaryPaymentHtml);
            $('li.nav-item.paypal').addClass('paypal-disable');
            $('#payPalRadioButton').attr('disabled', true);
            if (window.SitePreferences &&
              window.SitePreferences.applePayCartCheckoutToggle &&
              window.dw &&
              window.dw.applepay &&
              window.ApplePaySession &&
              window.ApplePaySession.canMakePayments()) {
              var applePayButtonPlaceOrderStage = $('#apple-pay-checkout-place-order-button');
              var placeOrderButtonDiv = $('.place-order-next-step-button-row');
              var applePayButtonPaymentMethodStage = $('#next-step-button-applepay');
              var reviewOrderButtonDiv = $('.payment-next-step-button-row');
              var klarnaDiv = $('.klarna-payment-item');
              if (applePayButtonPlaceOrderStage.length) {
                applePayButtonPlaceOrderStage.addClass('d-none');
              }
              if (placeOrderButtonDiv.length) {
                placeOrderButtonDiv.removeClass('applePayEnabled');
              }
              if (applePayButtonPaymentMethodStage.length) {
                applePayButtonPaymentMethodStage.addClass('d-none');
              }
              if (reviewOrderButtonDiv.length) {
                reviewOrderButtonDiv.removeClass('applePayEnabled');
              }
              if (klarnaDiv.length) {
                klarnaDiv.addClass('klarna-disabled');
                var klarnaOptioName = klarnaDiv.find('.option-name');
                if (klarnaOptioName.length) {
                  klarnaOptioName.addClass('d-none');
                }
              }
            }
          }

          if (resp.limitReached || resp.amountFinished) {
            const $giftCardFormGroup = $('.gift-card-form-group');
            $giftCardFormGroup.find('input').attr('disabled', 'disabled');
            $giftCardFormGroup.find('.giftcard-apply-submit button').attr('disabled', 'disabled');
          }
        }

        // update Klarna tab
        klarnaClientUtils.toggleKlarnaCheckoutTab(resp.error || resp.isKlarnaEnabled);

        const $giftCardPayment = $('.gift-card-payment');
        const $giftCardPaymentInput = $giftCardPayment.find('input');

        // reCaptcha.callToken('payment');
        $giftCardPaymentInput.val('');
        $giftCardPaymentInput.next('span').remove();
        $giftCardPaymentInput.prev('label').removeClass('is-invalid').removeClass('input-focus');
        $.spinner().stop();
        $giftCardPaymentInput.prev('label').removeClass('input-focus');
        $giftCardPayment.val('');
      }
      else {
        if (resp.limitReached) {
          const $giftCardFormGroup = $('.gift-card-form-group');

          $giftCardFormGroup.find('input').attr('disabled', 'disabled');
          $giftCardFormGroup.find('.giftcard-apply-submit button').attr('disabled', 'disabled');
        }

        const $giftCardPaymentInput = $('.gift-card-payment input');

        // reCaptcha.callToken('payment');
        $giftCardPaymentInput.prev('label').removeClass('input-focus');
        $giftCardPaymentInput.val('');
        $.spinner().stop();
        clientSideValidation.checkValidationOnAjax($('.gift-card-form-group'), true, true);
        $genericError.removeClass('d-none').html(resp.message);
        $genericError;
        floatLabel.resetFloatLabel();
      }
      if (resp.botError) {
        $('.giftcard-apply-submit .giftcard-apply-btn').attr('disabled', 'disabled');
        $genericError.removeClass('d-none').html(resp.error);
      }
      if (resp.incompatibleCoupon && resp.renderedTemplate) {
        if ($('#promoGiftCardValidationModal').length) {
          $('#promoGiftCardValidationModal').remove();
        }
        $('body').append(resp.renderedTemplate);
        $('#promoGiftCardValidationModal').modal('show');
      }
    },
    error: function (errorResp) {
      if ('responseJSON' in errorResp && 'redirectUrl' in errorResp.responseJSON && errorResp.responseJSON.redirectUrl) {
        window.location.href = errorResp.responseJSON.redirectUrl;
      }

      const $giftCardPaymentInput = $('.gift-card-payment input');
      const $genericError = $('.generic-error');

      // reCaptcha.callToken('payment');
      $giftCardPaymentInput.prev('label').removeClass('input-focus');
      $giftCardPaymentInput.val('');
      $.spinner().stop();
      clientSideValidation.checkValidationOnAjax($('.gift-card-form-group'), true, true);
      $genericError.removeClass('d-none').html(errorResp.message);
      if (errorResp.botError) {
        $('.giftcard-apply-submit .giftcard-apply-btn').attr('disabled', 'disabled');
        $genericError.removeClass('d-none').html(errorResp.error);
      }

      floatLabel.resetFloatLabel();
    }
  });
}

/**
 * remove gift card from basket
 *
 * @param {url} removeGiftCardUrl - remove gift card url
 * @param {number} giftCard - gift card number to be removed
 */
function removeGiftCard(removeGiftCardUrl, giftCard, multiGiftCards, addCouponAfterRemoval) {
  $.spinner().start();
  $.ajax({
    url: removeGiftCardUrl,
    method: 'POST',
    data: {
      uuid: giftCard,
      multiGiftCards: multiGiftCards
    },
    success: function (responseData) {
      const $genericError = $('.generic-error');
      const $navItemPayPal = $('li.nav-item.paypal');

      $genericError.addClass('d-none');
      var applePayButtonPlaceOrderStage = $('#apple-pay-checkout-place-order-button');
      var placeOrderButtonDiv = $('.place-order-next-step-button-row');
      var applePayButtonPaymentMethodStage = $('#next-step-button-applepay');
      var reviewOrderButtonDiv = $('.payment-next-step-button-row');
      var klarnaDiv = $('.klarna-payment-item');
      if (responseData.giftCard.hasGiftCard) {
        $('.gift-card-applied').html(responseData.giftCard.giftCardHtml);
        if (!$navItemPayPal.hasClass('paypal-disable')) {
          $navItemPayPal.addClass('paypal-disable');
          $('#payPalRadioButton').attr('disabled', true);
        }
        if (window.SitePreferences &&
          window.SitePreferences.applePayCartCheckoutToggle &&
          window.dw &&
          window.dw.applepay &&
          window.ApplePaySession &&
          window.ApplePaySession.canMakePayments()) {
          if (applePayButtonPlaceOrderStage.length) {
            applePayButtonPlaceOrderStage.addClass('d-none');
          }
          if (placeOrderButtonDiv.length) {
            placeOrderButtonDiv.removeClass('applePayEnabled');
          }
          if (applePayButtonPaymentMethodStage.length) {
            applePayButtonPaymentMethodStage.addClass('d-none');
          }
          if (reviewOrderButtonDiv.length) {
            reviewOrderButtonDiv.removeClass('applePayEnabled');
          }
          if (klarnaDiv.length) {
            klarnaDiv.addClass('klarna-disabled');
            var klarnaOptioName = klarnaDiv.find('.option-name');
            if (klarnaOptioName.length) {
              klarnaOptioName.addClass('d-none');
            }
          }
        }
      } else {
        $('.gift-card-applied').empty();
        $navItemPayPal.removeClass('paypal-disable');
        $('#payPalRadioButton').removeAttr('disabled');
        if (window.SitePreferences &&
          window.SitePreferences.applePayCartCheckoutToggle &&
          window.dw &&
          window.dw.applepay &&
          window.ApplePaySession &&
          window.ApplePaySession.canMakePayments()) {
          if (applePayButtonPlaceOrderStage.length) {
            applePayButtonPlaceOrderStage.removeClass('d-none');
          }
          if (placeOrderButtonDiv.length) {
            placeOrderButtonDiv.addClass('applePayEnabled');
          }
          if (applePayButtonPaymentMethodStage.length) {
            applePayButtonPaymentMethodStage.removeClass('d-none');
          }
          if (reviewOrderButtonDiv.length) {
            reviewOrderButtonDiv.addClass('applePayEnabled');
          }
          if (klarnaDiv.length) {
            klarnaDiv.removeClass('klarna-disabled');
            var klarnaOptioName = klarnaDiv.find('.option-name');
            if (klarnaOptioName.length) {
              klarnaOptioName.removeClass('d-none');
            }
          }
        }
      }
      const $giftCardPaymentInput = $('.gift-card-payment input');
      const $giftCardFormGroup = $('.gift-card-form-group');
      $('.order-summary-payment-applied').html(responseData.orderSummaryPaymentHtml);
      $('.appliedGiftCard').remove();
      $giftCardPaymentInput.prev('label').removeClass('input-focus');
      $giftCardPaymentInput.val('');
      $('.credit-card-form input').removeAttr('disabled');
      $('.credit-card-content [name=dwfrm_billing_paymentMethod]').val('CREDIT_CARD');
      $('.payment-information').data('payment-method-id', 'CREDIT_CARD');
      $giftCardFormGroup.find('input').removeAttr('disabled');
      $giftCardFormGroup.find('.giftcard-apply-submit button').removeAttr('disabled');
      if ($('.saved-payment-instrument-section').length) {
        $('.saved-payment-security-code').removeAttr('disabled');
        $('.credit-card-selection-new').removeClass('disabled');
      }
      // $('.next-step-button:visible').find('button[type="submit"]').attr('disabled', 'disabled');
      $('.enableBillingButton').val(false);
      // reCaptcha .callToken('payment');
      if (responseData.botError) {
        $('.giftcard-apply-submit .giftcard-apply-btn').attr('disabled', 'disabled');
      }

      // update klarna tab
      klarnaClientUtils.toggleKlarnaCheckoutTab(responseData.isKlarnaEnabled);

      //If flows comes from gift card removal confirmation before adding the promo code, 
      //trigger apply button click to add already typed promo code. Feature from PAY-209
      if (addCouponAfterRemoval && $('.promo-code-btn').length) {
        $('.promo-code-btn').click();
      }

      $.spinner().stop();
    },
    error: function (errorRsp) {
      if (errorRsp.responseJSON.redirectUrl) {
        window.location.href = errorRsp.responseJSON.redirectUrl;
      }

      const $genericError = $('.generic-error');
      const $giftCardPaymentInput = $('.gift-card-payment input');

      $genericError.removeClass('d-none').html(errorRsp.responseJSON.message);
      // reCaptcha .callToken('payment');
      $giftCardPaymentInput.prev('label').removeClass('input-focus');
      $giftCardPaymentInput.val('');
      // reCaptcha .callToken('payment');
      if (errorRsp.botError) {
        $('.giftcard-apply-submit .giftcard-apply-btn').attr('disabled', 'disabled');
      }

      $.spinner().stop();
    }
  });
}
/**
 * validate CreditCard form data in the billing -  payment section
 */
function validateCreditCardFormData() {
  $('.credit-card-form input:visible').trigger('blur');
  const $savedCreditCvvHolder = $('.savedCreditCvvHolder input:visible');
  if ($savedCreditCvvHolder.length) {
    $savedCreditCvvHolder.trigger('blur');
  }
}

module.exports = {
  giftCardBalanceCheck: function () {
    $body.on('click', '.giftcard-apply-submit .giftcard-apply-btn', function (e) {
      e.preventDefault();

      const $this = $(this);
      const checkBalanceUrl = $this.data('action');
      const addGiftCardUrl = $this.data('add-card');
      // eslint-disable-next-line no-undef
      grecaptcha.ready(function () {
        // eslint-disable-next-line no-undef
        grecaptcha.execute($('.google-recaptcha-key').html(), { action: 'gcpayment' })
          .then(function (token) {
            $('.g-recaptcha-token').val(token);
            checkBalance(checkBalanceUrl, addGiftCardUrl);
            validateCreditCardFormData();
          });
      });
    });
  },
  giftCardBalanceCheckWithParameters: function (checkBalanceUrl, addGiftCardUrl, gcNumberFromModal, gcPinFromModal) {
    $.each($('.gift-card-form-group .invalid-feedback'), function () {
      if ($(this).html()) {
        return;
      }
    });
    // eslint-disable-next-line no-undef
    grecaptcha.ready(function () {
      // eslint-disable-next-line no-undef
      grecaptcha.execute($('.google-recaptcha-key').html(), { action: 'gcpayment' })
        .then(function (token) {
          $('.g-recaptcha-token').val(token);
          checkBalance(checkBalanceUrl, addGiftCardUrl, gcNumberFromModal, gcPinFromModal);
        });
    });
  },
  removeGiftCardAction: function () {
    // Insert GCAPTCHA script into DOM when giftcard applied in payment section
    if ($('.applied-gift-card').length > 0 && (typeof grecaptcha == 'undefined' || grecaptcha == null)) {
      appendGoogleRecaptcha();
    }
    $body.on('click', '.applied-gift-card button.remove-gift-card', function (e) {
      e.preventDefault();

      const $this = $(this);
      const removeGiftCardUrl = $this.data('action');
      const giftCard = $this.data('uuid');
      // eslint-disable-next-line no-undef
      grecaptcha.ready(function () {
        // eslint-disable-next-line no-undef
        grecaptcha.execute($('.google-recaptcha-key').html(), { action: 'gcpayment' })
          .then(function (token) {
            $('.g-recaptcha-token').val(token);
            removeGiftCard(removeGiftCardUrl, giftCard);
            validateCreditCardFormData();
          });
      });
    });
  },
  removeGiftCardActionWithParameters: function (removeGiftCardUrl, giftCard, multiGiftCards, addCouponAfterRemoval) {
    removeGiftCard(removeGiftCardUrl, giftCard, multiGiftCards, addCouponAfterRemoval);
  }
};
