/* globals google */
'use strict';
var base = require('core/storeLocator/storeLocator');

/** Constants */
const $body = $('body');

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */
function updateStoresResults(data) {
  var $resultsDiv = $('.results');
  var $mapDiv = $('.map-canvas');
  var hasResults = data.stores.length > 0;

  if (!hasResults) {
    $('.store-locator-no-results').show();
  } else {
    $('.store-locator-no-results').hide();
  }

  $resultsDiv.empty().data({
    'has-results': hasResults,
    'radius': data.radius,
    'search-key': data.searchKey
  });

  $mapDiv.attr('data-locations', data.locations);

  if ($mapDiv.data('has-google-api')) {
    maps();
  } else {
    $('.store-locator-no-apiKey').show();
  }

  if (data.storesResultsHtml) {
    $resultsDiv.append(data.storesResultsHtml);
  }
}

/**
 * Search for stores with new zip code
 * @param {HTMLElement} element - the target html element
 * @returns {boolean} false to prevent default event
 */
function search(element) {
  var dialog = element.closest('.in-store-inventory-dialog');
  var spinner = dialog.length ? dialog.spinner() : $.spinner();
  spinner.start();
  var $form = element.closest('.store-locator');
  var radius = $('#inStoreInventoryModal').find('#radius option:selected').text();
  if (radius !== undefined && radius.length) {
    radius = radius.split(' ')[0];
  }
  var url = $form.attr('action');
  var urlParams = {
    radius: radius
  };
  const $btnStoreLocatorSearch = $('.btn-storelocator-search');
  if ($btnStoreLocatorSearch.data('product-pliuuid') !== undefined && $btnStoreLocatorSearch.data('product-pliuuid') !== 'null') {
    urlParams.source = 'cart';
  }
  var payload = $form.is('form')
    ? $form.serialize()
    : {
        postalCode: $form.find('[name="postalCode"]').val()
      };
  // extra payload carrying the product ids with quantity in json serialized
  if (
    element.data('products') &&
    element.data('products') !== undefined &&
    element.data('products') !== 'null' &&
    element.data('products').indexOf('undefined') === -1
  ) {
    payload.products = element.data('products');
  }
  url = appendToUrl(url, urlParams);

  $.ajax({
    url: url,
    type: $form.attr('method'),
    data: payload,
    dataType: 'json',
    success: function (data) {
      spinner.stop();
      if ($body.find('.product-detail').length) {
        $('body').trigger('adobe:bopusSearch', data);
      }
      if ($body.find('.prdt-cart-details').length) {
        var pid = $('.btn-storelocator-search').data('search-pid');
        data.pid = pid;
        $('body').trigger('adobe:bopusCartSearch', data);
      }

      updateStoresResults(data);
      $('.select-store').prop('disabled', true);
    }
  });
  return false;
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
  var newUrl = url;
  newUrl +=
    (newUrl.indexOf('?') !== -1 ? '&' : '?') +
    Object.keys(params)
      .map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
      })
      .join('&');

  return newUrl;
}

/**
 * Removes the specified parameter from url
 *
 * @param {string} url - the url
 * @param {string} parameter - parameter to be removed
 * @returns {string} url - url after removing the parameter
 */
function removeURLParameter(url, parameter) {
  // prefer to use l.search if you have a location/link object
  var urlparts = url.split('?');
  if (urlparts.length === 2 && urlparts[1] === '') {
    urlparts = url.slice(0, -1).split('?');
  }
  if (urlparts.length >= 2) {
    var prefix = encodeURIComponent(parameter) + '=';
    var pars = urlparts[1].split(/[&;]/g);
    // reverse iteration as may be destructive
    for (var i = pars.length; i-- > 0; ) {
      // idiom for string.startsWith
      if (pars[i].lastIndexOf(prefix, 0) !== -1) {
        pars.splice(i, 1);
      }
    }

    // eslint-disable-next-line no-param-reassign
    url = urlparts[0] + '?' + pars.join('&');
    return url;
  }
  return url;
}

/**
 * remove the store selector modal when a store is selected out of results
 */
function removeSelectStoreModal() {
  const $inStoreInventoryModal = $('#inStoreInventoryModal');
  if ($inStoreInventoryModal.length > 0) {
    $inStoreInventoryModal.modal('hide');
    $inStoreInventoryModal.attr('aria-modal', 'false');
    $inStoreInventoryModal.remove();
  }
}

/**
 * Sets the data attribute of Quantity Selector to save its original state.
 * @param {HTMLElement} $quantitySelect - The Quantity Select Element
 */
function setOriginalQuantitySelect($quantitySelect) {
  if (!$quantitySelect.data('originalHTML')) {
    $quantitySelect.data('originalHTML', $quantitySelect.html());
  } // If it's already there, don't re-set it
}

/**
 * Update quantity options. Only display quantity options that are available for the store.
 * @param {sring} searchPID - The product ID of the selected product.
 * @param {number} storeId - The store ID selected for in store pickup.
 * @param {number} storeDistance - The store distance of the store selected for in store pickup.
 * @param {string} searchPlid - unique id of an item
 */
function updateQuantityOptions(searchPID, storeId, storeDistance, searchPlid) {
  var selectorPrefix = '.product-detail[data-pid="' + searchPID + '"]';
  var quantitySelector = selectorPrefix + ' .quantity-select';

  setOriginalQuantitySelect($(quantitySelector));

  var requestData = {
    pid: searchPID,
    quantitySelected: $(quantitySelector).val(),
    storeId: storeId,
    storeDistance: storeDistance,
    searchPlid: searchPlid,
    savetosession: true
  };

  $.ajax({
    url: $('.store-change-link').data('setstore'),
    data: requestData,
    method: 'GET',
    success: function (response) {
      var $productContainer = $('.product-detail[data-pid="' + searchPID + '"]');
      $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
        product: response.product,
        $productContainer: $productContainer
      });
      // update shipping option elements
      window.base.updateShippingOptions(response, $productContainer);
    }
  });
}

/**
 * Updates the store name
 * @param {Object} data - Contains the store info
 */
function updateStoreContent(data) {
  var storeId = data.storeID;
  var storeDistance = data.storeDistance;
  var storeName = data.name.toLowerCase();
  $.spinner().start();
  var setStoreUrl = data.url;
  // form data
  var form = {
    storeId: storeId,
    storeDistance: storeDistance,
    ajax: true,
    savetosession: true,
    noviewdata: true
  };
  if (setStoreUrl) {
    $.ajax({
      url: setStoreUrl,
      data: form,
      success: function (data) {
        var $changeStore = $('.change-store');
        var $storeRefine = $changeStore.parent().closest('.store-refine');
        var $bopisCheck = $storeRefine.find('input[id="bopisCheck"]');
        var valueSelected = $('input[name="refinestore"]:checked').val();
        $changeStore.html(storeName);

        var dataUrl = $bopisCheck.data('href');
        dataUrl = removeURLParameter(dataUrl, 'storeid');
        dataUrl = removeURLParameter(dataUrl, 'srchsrc');
        dataUrl = appendToUrl(dataUrl, { storeid: storeId });

        $bopisCheck.attr('data-href', dataUrl);

        const $bopisCheckGlobal = $('input[id="bopisCheck"]');

        $bopisCheckGlobal.removeAttr('disabled');
        $bopisCheckGlobal.siblings('label').find('span').html(data.bopisDefaultLabel);
        $bopisCheckGlobal.trigger('click');
      },
      error: function () {
        $.spinner().stop();
      }
    });
  }
}

/**
 * updates the store info on select of store from the modal.
 */
$('body').on('store:searchPage', function (e, data) {
  updateStoreContent(data); // if event invoke is from modal, updates store html
  removeSelectStoreModal(); // close modal on successful update
});

/**
 * CUSTOM EVENT
 * event handler triggered with the store selection on modal
 */
$('body').on('store:pdp', function (e, data) {
  const $btnStoreLocatorSearch = $('.btn-storelocator-search');
  var searchPID = $btnStoreLocatorSearch.attr('data-search-pid');
  var searchPlid = $btnStoreLocatorSearch.attr('data-product-pliuuid');
  const $productDetail = $('.product-detail[data-pid="' + searchPID + '"]');
  const $storeElement = $productDetail === undefined ? $('.cart-options[data-pid="' + searchPID + '"]') : $productDetail; // fall back from cart, pick product id
  var $changeStoreButton = $storeElement.find('.change-store');
  $changeStoreButton.data('postal', data.searchPostalCode);
  $changeStoreButton.data('radius', data.searchRadius);
  updateQuantityOptions(searchPID, data.storeID, data.storeDistance, searchPlid);
  const $inStoreInventoryModal = $('#inStoreInventoryModal');
  $inStoreInventoryModal.modal('hide')
  $inStoreInventoryModal
    .attr('data-event-trigger', 'store')
    .attr('aria-modal', 'false')
    .remove();
});

/**
 * CUSTOM EVENT TRIGGER
 * trigger a custom event when store is selected on the store locator modal
 */
base.updateSelectStoreButton = function () {
  $body.on('change', '.select-store-input', function (e) {
    e.preventDefault();
    const $results = $('.results');
    var selectedStore = $(':checked', '.results-card .results');
    var data = {
      storeID: selectedStore.val(),
      searchRadius: $('#radius').val(),
      searchPostalCode: $results.data('search-key') !== undefined ? $results.data('search-key').postalCode : null,
      storeDetailsHtml: selectedStore.siblings('label').find('.store-details').html(),
      storeDistance: selectedStore.data('store-distance') !== undefined ? selectedStore.data('store-distance') : '',
      event: e
    };
    /** ** CUSTOM EVENT TRIGGER ****/
    const $btnStoreLocatorSearch = $('.btn-storelocator-search');
    if ($btnStoreLocatorSearch.data('product-pliuuid') !== undefined && $btnStoreLocatorSearch.data('product-pliuuid') !== 'null') {
      // store select cart
      if ($btnStoreLocatorSearch.data('shipto-selected') === 'shipto') {
        // update pli with store info if 'Ship to' is toggled
        data.pliUUID = $btnStoreLocatorSearch.data('product-pliuuid');
        data.storeName = selectedStore.data('store-info') !== undefined ? selectedStore.data('store-info').name : null;
        data.inStoreFieldLabel = $btnStoreLocatorSearch.data('instorefieldlabel');
        data.storeId = selectedStore.val();
        data.prodid = $btnStoreLocatorSearch.data('search-pid');
        $('body').trigger('store:changeStore', data);
      } else if ($btnStoreLocatorSearch.data('shipto-selected') === 'instore') {
        // // update pli with store info if 'Pick up in store' is toggled
        data.storeId = selectedStore.val();
        data.pliUUID = $btnStoreLocatorSearch.data('product-pliuuid');
        data.url = $('.cart-options').data('toggle-url');
        data.savefromModal = true;
        data.selected = 'instore';
        data.prodid = $btnStoreLocatorSearch.data('search-pid');
        $('body').trigger('store:cart', data);
        if ($btnStoreLocatorSearch.closest('.prdt-cart-details').length) {
          $('body').trigger('adobe:bopusCartSelect', data);
        }
      }
    } else if ($btnStoreLocatorSearch.data('source') === 'search') {
      // store select search page
      data.url = $('.store-refine').data('storeurl');
      data.name = selectedStore.data('store-info').name;
      updateStoreContent(data);
      removeSelectStoreModal();
    } else if($btnStoreLocatorSearch.data('search-pid') != undefined){// store select PDP
      $('body').trigger('store:pdp', data);
    }
  });
};

/**
 * CLICK EVENT
 * event handler triggered with the click of change store in PDP
 */
base.setMyHomeStore = function () {
  $body.on('click', 'a.set-my-store:not(.no-click)', function (e) {
    e.preventDefault();
    e.stopImmediatePropagation();
    var url = e.target.href;
    var storeId = e.target.closest('.store-details').dataset.storeId;
    var storeDistance = e.target.parentElement.dataset.storeDistance;
    if (storeId && storeDistance) {
      $.spinner().start();
      url = appendToUrl(url, { storeID: storeId, distance: storeDistance });
      $.ajax({
        url: url,
        success: function (data) {
          [].forEach.call(document.querySelectorAll('.set-my-store'), function (el) {
            if (el !== e.target && el.matches('span')) {
              // Swap the previous "my store" span for a link
              const link = document.createElement('a');
              link.setAttribute('class', 'set-my-store underline');
              link.href = el.parentElement.dataset.href;
              link.innerText = data.otherStoreMsg;
              el.parentElement.appendChild(link);
              el.parentElement.removeChild(el);
            } else if (el === e.target) {
              // Swap out the clicked on link for a span indicating the home store
              const span = document.createElement('span');
              span.setAttribute('class', 'set-my-store home-store');
              span.innerText = data.myStoreMsg;
              el.parentElement.appendChild(span);
              el.parentElement.removeChild(el);
            }
          });
          $.spinner().stop();
        },
        error: function () {
          $.spinner().stop();
        }
      });
    }
  });
};

/**
 * CLICK AND SUBMIT EVENT
 * event handler triggered with store search on store locator modal
 */
base.search = function () {
  $('.store-locator-container form.store-locator').submit(function (e) {
    e.preventDefault();
    search($(this));
  });
  $('.store-locator-container .btn-storelocator-search[type="button"]').click(function (e) {
    e.preventDefault();
    const $inStoreInventoryModal = $('#inStoreInventoryModal');
    var input = $inStoreInventoryModal.find('#store-postal-code');
    var inputVal = input.val();
    // Some special characters to be allowed for City Search. SFDEV-7932
    var validate = /^[0-9]{5}(-[0-9]{4})?$/.test(inputVal) === false;
    if (validate) {
      $inStoreInventoryModal.find('.store-locator').addClass('error');
      if ($(input).next('span').length === 0) {
        $("<span class='invalid'></span>").insertAfter(input);
      }
      var formData = {};
      formData.errorFields = ['postalCode'];
      formData.formName = 'bopus search';
      $('body').trigger('adobeTagManager:formError', formData);
    } else if (inputVal === '') {
      $inStoreInventoryModal.find('.store-locator').addClass('error');
      input.focus();
      var formData = {};
      formData.errorFields = ['postalCode'];
      formData.formName = 'bopus search';
      $('body').trigger('adobeTagManager:formError', formData);
    } else {
      search($(this));
      $inStoreInventoryModal.find('.store-locator').removeClass('error');
      $(input).next('span').remove();
    }
  });
};

module.exports = base;
