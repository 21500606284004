'use strict';

/**
 * Initialize button.
 */
function applePayButtonInit() {
  var $applePayButtonDiv = $('#apple-pay-cart-button');
  if (window.SitePreferences &&
    window.SitePreferences.applePayCartCheckoutToggle &&
    window.dw &&
    window.dw.applepay &&
    window.ApplePaySession &&
    window.ApplePaySession.canMakePayments() &&
    $applePayButtonDiv.length && !$applePayButtonDiv.hasClass('d-none')
  ) {
    var checkExist = setInterval(function () {
      // Check if apple pay button loaded and manipulate checkout options classes to fit the apple pay button
      if (document.querySelector('.dw-apple-pay-button')) {
        clearInterval(checkExist);
        var $checkoutOptions = $('.checkout-options');
        $checkoutOptions.addClass('applepay-active float-left');

        var $masterPassContainer = $('.masterpass-cart-buttons-container');
        if ($masterPassContainer.length) {
          $masterPassContainer.removeClass('chkout-btn-img float-right');

          var $masterPassParent = $masterPassContainer.parent();
          if ($masterPassParent.length && $masterPassParent.hasClass('MP-wrap')) {
            $masterPassParent.removeClass('MP-wrap').addClass('masterpass-payment chkout-btn-img-iOS');
          }
        }

        var $paymentButtons = $('.chkout-btn-img');
        if ($paymentButtons.length) {
          $paymentButtons.addClass('chkout-btn-img-iOS');
          $('.chkout-btn-img-iOS').removeClass('chkout-btn-img');
        }

        document.querySelector('.dw-apple-pay-button').addEventListener('click', function (e) {
          $('body').trigger('adobeTagManager:exitToPaymentService', 'applepay');
        });
      }
    }, 100);
  } else {
    $applePayButtonDiv.addClass('d-none');

    var $checkoutOptions = $('.checkout-options');
    // Hide "Or Checkout With" if inside the div has only the div with the text
    if ($checkoutOptions.length && $checkoutOptions.children().length === 1 && $checkoutOptions.children()[0].className.indexOf('chkout-optn-text') > -1) {
      $checkoutOptions.addClass('d-none');
      // Hide "Or Checkout With" if inside the div has only the empty apple pay div and no other payment method like paypal, masterpass...
    } else if ($checkoutOptions.length && $checkoutOptions.children().length <= 2) {
      for (var i = 0; i < $checkoutOptions.children().length; i++) {
        var child = $checkoutOptions.children()[i];
        if (child.id === 'apple-pay-cart-button') {
          $checkoutOptions.addClass('d-none');
        }
      }
    }
  }
}

module.exports = {
  applePayButtonInit: applePayButtonInit
};
