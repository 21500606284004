'use strict';

/**
 * Load product object
 * @param {Object} qproduct product from response
 * @returns {Object} product updated product
 */
function loadProductObject(qproduct) {
  var product = {};
  if (qproduct) {
    product.brand = qproduct.brand.name ? qproduct.brand.name : '';
    product.code = qproduct.masterProductID;
    product.name = qproduct.productName;
    var orgPrice = null;
    var currPrice = null;
    if (qproduct.price.type && qproduct.price.type === 'range') {
      orgPrice = qproduct.price.max.list === null ? qproduct.price.max.sales.value : qproduct.price.max.list.value;
      currPrice = qproduct.price.max.sales.value.toString();
    } else if (qproduct.productType === 'set') {
      orgPrice = qproduct.price.list.value === null ? qproduct.price.sales.value : qproduct.price.list.value;
      currPrice = qproduct.price.sales.value;
    } else {
      orgPrice = qproduct.price.list === null ? qproduct.price.sales.value : qproduct.price.list.value;
      currPrice = qproduct.price.sales.value;
    }
    product.original_price = orgPrice !== null ? orgPrice.toString() : '';
    product.price = currPrice ? currPrice.toString() : '';
    product.selected_sku = qproduct.id;
  }
  return product;
}
/**
 * Helper class to provide Cart line item
 * @param {Object} cartModel cart response from model
 * @param {number} hidePrevQuantity add/remove attribute from object
 * @returns {Object} product object
 */
function provideCartLineItem(cartModel, hidePrevQuantity) {
  var products = [];
  var qproducts = [];
  var selectedLineItems = [];
  var recentproduct = cartModel.recentlyMovedProduct || cartModel.product;
  var newBonusDiscountLineItem = typeof cartModel.newBonusDiscountLineItem === 'undefined' ? null : cartModel.newBonusDiscountLineItem;
  cartModel = cartModel.cart ? cartModel.cart : cartModel; //eslint-disable-line
  if (cartModel && cartModel.items && cartModel.items.length && recentproduct) {
    $.each(cartModel.items, function (index, item) {
      if (
        item.id === recentproduct.id ||
        (item.qualifyingProductLineItemForBonusProduct && item.qualifyingProductLineItemForBonusProduct === recentproduct.id)
      ) {
        qproducts.push(item);
        selectedLineItems.push(item);
      }
    });
  }
  if (cartModel.deletedItem) {
    qproducts.push(cartModel.deletedItem);
    selectedLineItems.push(cartModel.deletedItem);
  }

  if (cartModel.deletedBonusProductsIDs && cartModel.deletedBonusProductsIDs.length) {
    $.each(cartModel.deletedBonusProductsIDs, function (index, deletedBonusProductsID) {
      qproducts.push(deletedBonusProductsID);
      selectedLineItems.push(deletedBonusProductsID);
    });
  }

  if (qproducts == null || typeof qproducts == 'undefined') {
    qproducts = [cartModel];
  }

  var deletedLineItem = false;
  if (typeof cartModel.deletedItem !== 'undefined') {
    deletedLineItem = true;
  }

  $.each(qproducts, function (index, qproduct) {
    if (qproduct) {
      var product = loadProductObject(qproduct);
      product.bopus_store_id = qproduct.fromStoreId ? qproduct.fromStoreId : '';
      product.ship_from_store_id = '';
      if (qproduct.quantity == null || typeof qproduct.quantity == 'undefined') {
        product.quantity = deletedLineItem ? '0' : qproduct.selectedQuantity.toString();
      } else {
        product.quantity = deletedLineItem ? '0' : qproduct.quantity.toString();
      }
      if (!hidePrevQuantity && cartModel.previousQuantity) {
        product.prev_quantity = cartModel.previousQuantity.toString();
      }
      products.push(product);
    }
  });
  return {
    product: products,
    selectedLineItem: selectedLineItems
  };
}

/**
 * Provide product info
 * @returns {Object} product get product info from PDP
 */
function provideProductInfoInPdp() {
  var product = {};
  product.brand = $('.product-detail .pdp-standard h1.product-brand-name').length ? $('.product-detail .pdp-standard h1.product-brand-name').text().trim() : '';
  product.code = $('div[data-atm-code]').length ? $('div[data-atm-code]').data('atm-code') : '';
  product.name = $('.product-detail .product-name').first().text().trim();
  var orgPrice = null;
  var tprice = null;
  if ($('.price .range').length) {
    orgPrice = $('.product-detail .bfx-list-price.formatted_price').first().data('unformatted-price').toString();
    tprice = $('.product-detail .bfx-list-price.formatted_price').last().data('unformatted-price').toString();
  } else {
    orgPrice = $('.product-detail .bfx-list-price.formatted_price').data('unformatted-price').toString();
    tprice = $('.product-detail .bfx-sale-price.formatted_price').length
      ? $('.product-detail .bfx-sale-price.formatted_price').data('unformatted-price').toString()
      : $('.product-detail .bfx-list-price.formatted_price').data('unformatted-price').toString();
  }
  product.original_price = orgPrice;
  product.price = tprice;
  product.quantity = '1';
  product.selected_sku = $('.product-detail').data('pid') ? $('.product-detail').data('pid').toString() : '';
  return product;
}

/**
 * Provide product info
 * @returns {Object} product get product info from Cart
 */
function provideProductInfoInCart(pid) {
  var cartProdID = pid;
  var cartProd = $('.prdt-cart-details').find('[data-atm-selectedsku="' + cartProdID + '"]');
  var product = {};
  product.brand = cartProd.find('.brand-name').length ? $('.brand-name').first().text().trim() : '';
  product.code = cartProd.length ? cartProd.data('atm-code') : '';
  product.name = cartProd.find('.bfx-product-name').first().text().trim();
  var orgPrice = null;
  var tprice = null;
  if ($('.price .range').length) {
    orgPrice = cartProd.find('.bfx-list-price.formatted_price').first().data('unformatted-price').toString();
    tprice = cartProd.find('.bfx-list-price.formatted_price').last().data('unformatted-price').toString();
  } else {
    orgPrice = cartProd.find('.bfx-list-price.formatted_price').data('unformatted-price').toString();
    tprice = cartProd.find('.bfx-sale-price.formatted_price').length
      ? cartProd.find('.bfx-sale-price.formatted_price').data('unformatted-price').toString()
      : cartProd.find('.bfx-list-price.formatted_price').data('unformatted-price').toString();
  }
  product.original_price = orgPrice;
  product.price = tprice;
  product.quantity = cartProd.find('.bfx-product-qty').val().toString();
  product.selected_sku = cartProdID.toString();
  return product;
}
/**
 * This ATM is used for search sugestion objec
 * @param {function} callback - callback function
 */
function suggestedSearch(callback) {
  $('body').on('click', '.search_results span.suggeted-item, .suggestion_list .atm-search', function () {
    var $this = $(this);
    var search = {
      term: $this.data('atm-term'),
      type: $this.closest('.search_results').find('.result_heading').data('atm-searchtype'),
      typedText: $('.search-form .search-field').val()
    };
    if (callback) {
      callback(search);
    }
    return true;
  });
}
/**
 * EMail Modal subscription
 * @param {function} callback - callback function
 */
function emailSignupModal(callback) {
  $('body').on('adobeTagManager:emailsignupmodal', function (e, location) {
    var signup = {
      location: location
    };
    if (callback) {
      callback(signup);
    }
  });
}
/**
 * Text / SMS Signup Form custom event
 */
$('body').on('submit', '.wyng-experience form.sign-up-form', function () {
  var fieldError = $('.wyng-experience div').hasClass('error');
  if (!fieldError) {
    let dataValue = {
      firstName: $(this).find('[id^="first_"]').length > 0 ? $(this).find('[id^="first_"]').val() : '',
      lastName: $(this).find('[id^="last_"]').length > 0 ? $(this).find('[id^="last_"]').val() : '',
      phoneNumber: $(this).find('[id^="phone_number_"]').length > 0 ? $(this).find('[id^="phone_number_"]').val() : '',
      zipCode: $(this).find('[id^="zipcode_"]').length > 0 ? $(this).find('[id^="zipcode_"]').val() : '',
    };
    $('body').trigger('adobeTagManager:textSignupForm', dataValue);
  }
});

/**
 * Text / SMS Signup Form
 * @param {function} callback function
 */
function textSignup(callback) {
  $('body').on('adobeTagManager:textSignupForm', function (e, dataValue) {
    if (callback) {
      callback(dataValue);
    }
  });
}
/**
 * Event on click of open minicart
 * @param {function} callback - Callback function
 */
function openMiniCart(callback) {
  $('body').on('adobeTagManager:openMiniCart', function (e, element) {
    var products = [];
    if ($(element).find('.adobelaunch_minicart_open').length > 0) {
      $.each($(element).find('.adobelaunch_minicart_open'), function (index, cartLineItem) {
        var product = {};
        product.brand = $(cartLineItem).find('.mini-cart-details .brand-name').length
          ? $(cartLineItem).find('.mini-cart-details .brand-name').text().trim()
          : '';
        product.bopus_store_id = $(cartLineItem).data('atm-bopusstoreid') ? $(cartLineItem).data('atm-bopusstoreid').toString() : '';
        product.code = $(cartLineItem).data('atm-code');
        product.name = $(cartLineItem).find('.line-item-name span').text().trim();
        var originalPrice = '0';
        if ($(cartLineItem).find('.strike-through').length) {
          originalPrice = $(cartLineItem).find('.strike-through').data('unformatted-amount').toString();
        } else if ($(cartLineItem).find('.line-item-total-price-amount').length) {
          originalPrice = $(cartLineItem).find('.line-item-total-price-amount').data('unformatted-amount').toString();
        }
        product.original_price = originalPrice;
        var price = '0';
        if ($(cartLineItem).find('.grand-tot-holder span').length) {
          price = $(cartLineItem).find('.grand-tot-holder span').data('unformatted-amount').toString();
        } else if ($(cartLineItem).find('.line-item-total-price-amount').length) {
          price = $(cartLineItem).find('.line-item-total-price-amount').data('unformatted-amount').toString();
        }
        product.price = price;
        product.quantity = $(cartLineItem).find('.quantity-val').length ? $(cartLineItem).find('.quantity-val').val() : '1';
        product.selected_sku = $(cartLineItem).data('atm-selectedsku').toString();
        product.ship_from_store_id = '';
        products.push(product);
      });
      if (callback) {
        callback(products);
      }
    }
  });
}
/**
 * On Updation of Minicart
 * @param {function} callback Callback function
 */
function updateMiniCart(callback) {
  $('body').on('adobeTagManager:quantityUpdateFromMiniCart', function (e, obj) {
    var products = provideCartLineItem(obj).product;
    if (callback) {
      callback(products);
    }
  });
}
/**
 * get selected stores
 * @returns {Object} array return all stores
 */
function getStores() {
  var refinementValuesArray = {};
  if ($('#bopisCheck:checked').length) {
    refinementValuesArray.name = $('a.change-store').text().trim();
    refinementValuesArray.type = 'selected';
  }
  return refinementValuesArray;
}
/**
 * Get refinement
 * @param {string} $this - current element
 * @returns {Object} - refinement object
 */
function getRefinement($this) {
  var refinements = [];
  $.each($('.refinements .refinement'), function (index, refinement) {
    var trefinement = {};
    trefinement.name = $(refinement).find('.card-header h2').text().trim();
    trefinement.values = [];
    var refinementValuesArray = {};
    if ($(refinement).hasClass('refinement-category') && $($this).find('.refinement-category').text()) {
      refinementValuesArray.name = $($this).find('.refinement-category').text().trim();
      refinementValuesArray.type = 'selected';
      trefinement.values.push(refinementValuesArray);
    } else if ($(refinement).hasClass('refinement-color')) {
      $.each($('.refinement-color .selected'), function (indexColor, colorRefinement) {
        refinementValuesArray = {};
        refinementValuesArray.name = $(colorRefinement).attr('title');
        refinementValuesArray.type = 'selected';
        trefinement.values.push(refinementValuesArray);
      });
    } else if ($(refinement).hasClass('refinement-price')) {
      if ($($this).hasClass('go-price')) {
        var formElement = $this.form;
        var min = parseInt(formElement.minPrice.value); //eslint-disable-line
        var max = parseInt(formElement.maxPrice.value); //eslint-disable-line
        refinementValuesArray.name = min + ' - ' + max;
        refinementValuesArray.type = 'selected';
        trefinement.values.push(refinementValuesArray);
      }
      $.each($('.refinement-price .selected'), function (indexPrice, priceRange) {
        refinementValuesArray.name = $(priceRange).closest('li').find('.bfx-price').text().trim();
        refinementValuesArray.type = 'selected';
        trefinement.values.push(refinementValuesArray);
      });
    } else if ($(refinement).hasClass('refinement-promotion')) {
      $.each($('.refinement-promotion .selected'), function (indexPromo, promoRefinement) {
        refinementValuesArray.name = $(promoRefinement).next('span').text().trim();
        refinementValuesArray.type = 'selected';
        trefinement.values.push(refinementValuesArray);
      });
    } else if ($(refinement).hasClass('refinement-sales--offers')) {
      $.each($('.refinement-sales--offers .selected'), function (indexPrice, promoRefinement) {
        refinementValuesArray.name = $(promoRefinement).next('span').text().trim();
        refinementValuesArray.type = 'selected';
        trefinement.values.push(refinementValuesArray);
      });
    } else {
      $.each($(refinement).find('ul li .selected'), function (indexother, attrRefinement) {
        refinementValuesArray = {};
        refinementValuesArray.name = $(attrRefinement).text().trim();
        refinementValuesArray.type = 'selected';
        trefinement.values.push(refinementValuesArray);
      });
    }
    refinements.push(trefinement);
  });
  var storeRefinement = {};
  storeRefinement.name = 'stores';
  storeRefinement.values = [];
  var storesArray = getStores();
  if (!jQuery.isEmptyObject(storesArray)) {
    storeRefinement.values.push(storesArray);
  }
  refinements.push(storeRefinement);
  return refinements;
}
/**
 * Get All Products
 * @returns {Object} - return all products
 */
function getProducts() {
  var products = [];
  $.each($('div[data-pid].product'), function (index, item) {
    var product = {};
    product.code = $(item).data('pid') ? $(item).data('pid').toString() : '';
    products.push(product);
  });
  return products;
}
/**
 * Updates product array
 *
 * @param {function} callback - callback function
 */
function productArrayUpdate(callback) {
  $('body').on('adobeTagManager:productArrayUpdate', function (e, $this) {
    var refinements = getRefinement($this);
    var sortType = 'default';
    if ($('.refinement-bar').find('select[name="sort-order"] option:selected').index() > 0) {
      sortType = 'selected';
    }
    var productArray = {
      array_page_number: pageData.product_array ? pageData.product_array.array_page_number : '1', //eslint-disable-line
      refinements: refinements,
      results_per_page: getProducts().length ? getProducts().length.toString() : '0',
      sort: {
        name: $('.refinement-bar').find('select[name="sort-order"] option:selected').data('id'),
        type: sortType
      },
      total_results: $('.search-count').data('search-count') ? $('.search-count').data('search-count').toString() : '0',
      results_across: pageData.product_array ? pageData.product_array.results_across : '1' //eslint-disable-line
    };
    if (callback) {
      callback(productArray, getProducts());
    }
  });
}
/**
 * Quick view events
 * @param {function} callback - Callback function
 */
function showQuickView(callback) {
  $('body').on('adobeTagManager:showQuickView', function (e, qproduct) {
    var products = [];
    var product = loadProductObject(qproduct);
    product.average_rating = qproduct.starRating ? qproduct.starRating.toString() : '';
    delete product.selected_sku;
    product.skus = qproduct.allAvailableProducts;
    var priceType = '';
    if (qproduct.badge.isClearance) {
      priceType = 'clearance';
    } else if (qproduct.badge.isFinalSale) {
      priceType = 'final sale';
    }
    product.tags = {
      feature_type: qproduct.featuredType.value ? qproduct.featuredType.value : '',
      inventory_label: qproduct.availability.messages && qproduct.availability.messages.length > 0 ? qproduct.availability.messages[0] : '',
      pip_text: qproduct.promotions && qproduct.promotions.length > 0 ? qproduct.promotions[0].calloutMsg : '',
      price_type: priceType,
      publish_date: 'new',
      returnable: (!qproduct.isNotReturnable.value).toString()
    };
    product.total_reviews = qproduct.turntoReviewCount ? qproduct.turntoReviewCount.toString() : '';
    products.push(product);
    if (callback) {
      callback(products);
    }
  });
}
/**
 * Add to bag events
 *
 * @param {function} callback - callback function
 */
function addToBag(callback) {
  $('body').on('adobeTagManager:addToBag', function (e, cartModel) {
    var products = [];
    var cartLineItem = provideCartLineItem(cartModel);
    var products = cartLineItem.product;
    $.each(products, function (index, product) {
      product.gwp_flag = cartLineItem.selectedLineItem[index].isBonusProductLineItem.toString();
    });
    if (callback) {
      callback(products);
    }
  });
}
/**
 * Add the product to wishlist
 * @param {function} callback - callback function
 */
function addToFav(callback) {
  $('body').on('adobeTagManager:addToFav', function (e, obj) {
    var products = [];
    var product = loadProductObject(obj);
    product.seller_name = 'HBC';
    product.quantity = '1';
    products.push(product);
    if (callback) {
      callback(products);
    }
  });
}
/**
 * Add the product from wishlist to cart
 * @param {function} callback - callback function
 */
function moveFavToBag(callback) {
  $('body').on('adobeTagManager:moveFavToBag', function (e, cartModel) {
    var products = provideCartLineItem(cartModel, true).product;
    if (callback) {
      callback(products);
    }
  });
}
/**
 * Remove product from wishlist
 * @param {function} callback - callback function
 */
function removeFromFav(callback) {
  $('body').on('adobeTagManager:removeFromFav', function (e, qproduct) {
    if (qproduct && callback) {
      var products = [];
      var product = loadProductObject(qproduct);
      product.seller_name = 'HBC';
      product.quantity = '1';
      products.push(product);
      callback(products);
    }
  });
}
/**
 * Update Cart Quantity
 * @param {function} callback - callback function
 */
function udpateCartQuantity(callback) {
  $('body').on('adobeTagManager:udpateCartQuantity', function (e, cartModel) {
    var products = provideCartLineItem(cartModel).product;
    if (callback) {
      callback(products);
    }
  });
}
/**
 * Move cart to wishlist
 * @param {function} callback - callback function
 */
function cartMoveToFav(callback) {
  $('body').on('adobeTagManager:cartMoveToFav', function (e, cartModel) {
    if (!cartModel.error) {
      var products = provideCartLineItem(cartModel).product;
      if (callback) {
        callback(products);
      }
    }
  });
}
/**
 * Promo code enter
 * @param {function} callback - callback function
 */
function enterPromoCode(callback) {
  $('body').on('adobeTagManager:promoCodeEntered', function (e, promoCoupon) {
    var order = {};
    order.promo_code = promoCoupon.coupon;
    order.promo_code_valid = promoCoupon.status.toString();
    if (callback) {
      callback(order);
    }
  });
}
/**
 * Exit to payment
 * @param {function} callback - callback function
 */
function exitToPaymentService(callback) {
  $('body').on('adobeTagManager:exitToPaymentService', function (e, paymentservice) {
    var order = {};
    order.payment_service = paymentservice;
    if (callback) {
      callback(order);
    }
  });
}
/**
 * Form Error
 * @param {function} callback - callback function
 */
function formError(callback) {
  $('body').on('adobeTagManager:formError', function (e, formData) {
    var form = {};
    form.errorFields = formData.errorFields;
    form.name = formData.formName;
    if (callback) {
      callback(form);
    }
  });
}
/**
 * On step change of checkout
 * @param {function} callback - callback function
 */
function checkoutStepChange(callback) {
  $('body').on('adobeTagManager:checkoutStepChange', function (e, steps) {
    if ($('body').find('.is-invalid').length > 0) {
      return;
    }
    var page = {};
    var visitor = {};
    page.checkoutStep = steps.step;
    visitor.email_opt_in = $('#add-to-email-list').length && steps.step === 'payment' ? $('#add-to-email-list').prop('checked').toString() : 'false';
    if (callback) {
      callback(page, pageData.products, visitor);
    }
  });
}
/**
 * Sharing wishlist
 * @param {function} callback - callback function
 */
function wishlistShare(callback) {
  $('body').on('adobeTagManager:wishlistShare', function () {
    if (callback) {
      callback();
    }
  });
}
/**
 * On changing alt image
 * @param {function} callback - callback function
 */
function altImageView(callback) {
  $('body').on('adobeTagManager:altImageView', function () {
    var products = [];
    var product = provideProductInfoInPdp();
    product.bopus_store_id = '';
    var orgPrice = null; //eslint-disable-line
    var tprice = null; //eslint-disable-line
    if ($('.price .range').length) {
      orgPrice = $('.product-detail .bfx-list-price.formatted_price').first().data('unformatted-price').toString();
      tprice = $('.product-detail .bfx-list-price.formatted_price').last().data('unformatted-price').toString();
    } else {
      orgPrice = $('.product-detail .bfx-list-price.formatted_price').data('unformatted-price').toString();
      tprice = $('.product-detail .bfx-sale-price.formatted_price').length
        ? $('.product-detail .bfx-sale-price.formatted_price').data('unformatted-price').toString()
        : $('.product-detail .bfx-list-price.formatted_price').data('unformatted-price').toString();
    }
    product.prev_quantity = '1';
    product.ship_from_store_id = '';
    products.push(product);
    if (callback) {
      callback(products);
    }
  });
}

/**
 * This is for on click of alternate image
 * @param {function} callback function
 */
function quickViewAltImageView(callback) {
  $('body').on('adobeTagManager:quickViewAltImageView', function () {
    var products = [];
    var product = {};
    product.brand = $('.product-quickview .product-name').length ? $('.product-quickview .product-name').text().trim() : '';
    product.bopus_store_id = '';
    product.code = $('.product-quickview').data('master-pid');
    product.name = $('.product-quickview .product-name').text().trim();
    var orgPrice = null;
    var tprice = null;
    if ($('.price .range').length) {
      orgPrice = $('.product-detail .bfx-list-price.formatted_price').first().data('unformatted-price').toString();
      tprice = $('.product-detail .bfx-list-price.formatted_price').last().data('unformatted-price').toString();
    } else {
      orgPrice = $('.product-detail .bfx-list-price.formatted_price').data('unformatted-price').toString();
      tprice = $('.product-detail .bfx-sale-price.formatted_price').length
        ? $('.product-detail .bfx-sale-price.formatted_price').data('unformatted-price').toString()
        : $('.product-detail .bfx-list-price.formatted_price').data('unformatted-price').toString();
    }
    product.original_price = orgPrice;
    product.price = tprice;
    product.prev_quantity = '1';
    product.quantity = $('.quantity-val').length ? $('.quantity-val').val() : '1';
    product.selected_sku = $('.product-detail').data('pid') ? $('.product-detail').data('pid').toString() : '';
    product.ship_from_store_id = '';
    products.push(product);
    if (callback) {
      callback(products);
    }
  });
}

/**
 * Wait list start
 * @param {function} callback function
 */
function waitListStart(callback) {
  $('body').on('adobe:waitListStart', function () {
    var products = [];
    var product = provideProductInfoInPdp();
    product.quantity = $('.quantity-val').val();
    products.push(product);
    if (callback) {
      callback(products);
    }
  });
}

/**
 * Wait list complete
 * @param {function} callback function
 */
function waitListComplete(callback) {
  $('body').on('adobe:waitListComplete', function () {
    var products = [];
    var product = provideProductInfoInPdp();
    product.quantity = $('.quantity-val').val();
    products.push(product);
    if (callback) {
      callback(products);
    }
  });
}

/**
 * Bopis PDP Start
 * @param {function} callback function
 */
function bopusPDPStart(callback) {
  $('body').on('adobe:bopusStart', function (e, param) {
    var products = [];
    var product;
    if (param && param.page === 'cart' && param.pid && param.quantity) {
      // for cart page
      product = provideProductInfoInCart(param.pid);
      product.quantity = parseInt(param.quantity).toString();
    } else {
      // for PDP
      product = provideProductInfoInPdp();
      product.quantity = $('.quantity-val').val();
    }
    products.push(product);
    if (callback) {
      callback(products);
    }
  });
}

/**
 * All Available stores
 * @param {Object} stores all stores
 * @returns {Object} provide all available stores
 */
function provideAvailableStores(stores) {
  var availableStores = [];
  $.each(stores, function (index, store) {
    if (store.unitsAtStores && store.unitsAtStores > 0) {
      availableStores.push(store);
    }
  });
  return availableStores;
}

/**
 * Bopus PDP Search
 * @param {function} callback function
 */
function bopusPDPSearch(callback) {
  $('body').on('adobe:bopusSearch', function (e, storeObj) {
    if (storeObj && storeObj.stores) {
      var products = [];
      var product = provideProductInfoInPdp();
      product.bopus_stores_available = provideAvailableStores(storeObj.stores).length.toString();
      product.bopus_stores_found = storeObj.stores.length ? storeObj.stores.length.toString() : '0';
      products.push(product);
      if (callback) {
        callback(products);
      }
    }
  });
}

/**
 * Bopus Cart Search
 * @param {function} callback function
 */
function bopusCartSearch(callback) {
  $('body').on('adobe:bopusCartSearch', function (e, storeObj) {
    var products = [];
    if (storeObj) {
      var product = provideProductInfoInCart(storeObj.pid);
      product.bopus_stores_available = '0';
      product.bopus_stores_found = '0';
      if (storeObj.stores) {
        product.bopus_stores_available = provideAvailableStores(storeObj.stores).length.toString();
        product.bopus_stores_found = storeObj.stores.length ? storeObj.stores.length.toString() : '0';
      }
    }
    products.push(product);
    if (callback) {
      callback(products);
    }
  });
}

/**
 * Bopus Store Select
 * @param {function} callback function
 */
function bopusCartSelect(callback) {
  $('body').on('adobe:bopusCartSelect', function (e, storeObj) {
    var products = [];
    if (storeObj) {
      var product = provideProductInfoInCart(storeObj.prodid);
      product.bopus_store_id = storeObj.storeId ? storeObj.storeId.toString() : '';
      product.ship_from_store_id = '';
    }
    products.push(product);
    if (callback) {
      callback(products);
    }
  });
}

/**
 * Bopis find stores
 * @param {function} callback function
 */
function findStoreSearch(callback) {
  $('body').on('click', 'a.schedule-an-appointment', function () {
    var products = [];
    products.push(provideProductInfoInPdp());
    if (callback) {
      callback(products);
    }
  });
}

/**
 * Used for search redirects
 * @param {function} callback - callback function
 */
function searchRedirect(callback) {
  $('body').on('click', '.site-search form.search-form button[name="search-button"]', function (e) {
    var searchTerm = $(this).siblings('input.search-field').val();
    var search = {
      term: searchTerm
    };
    if (callback) {
      callback(search);
    }
  });
}


/**
 * Build object containing error information tracked during checkout
 * @param {Array} products products related to the error tracked
 * @param {String} type determines the page which originates the error
 * @param {String} message a message containing the detailed information about the error
 * @param {Array} source determines if the error is originated from the server or client side
 * @returns {Object} object containing all the error data needed by ATM
 */
function buildErrorInfo (products, type, message, source){
  var errorInfo = {};
  errorInfo.products = products || [];
  errorInfo.type = type || '';
  errorInfo.message = message || '';
  errorInfo.source = source || 'server-side';
  return errorInfo;
}

/**
 * Used for call to order
 * @param {function} callback - callback function
 */
function callToOrder(callback) {
  $('body').on('adobeTagManager:callToOrder', function () {
    if (callback) {
      callback(products);
    }
  })
}

module.exports = {
  suggestedSearch: suggestedSearch,
  emailSignupModal: emailSignupModal,
  textSignup: textSignup,
  openMiniCart: openMiniCart,
  updateMiniCart: updateMiniCart,
  productArrayUpdate: productArrayUpdate,
  showQuickView: showQuickView,
  addToBag: addToBag,
  addToFav: addToFav,
  moveFavToBag: moveFavToBag,
  udpateCartQuantity: udpateCartQuantity,
  removeFromFav: removeFromFav,
  cartMoveToFav: cartMoveToFav,
  enterPromoCode: enterPromoCode,
  exitToPaymentService: exitToPaymentService,
  formError: formError,
  checkoutStepChange: checkoutStepChange,
  wishlistShare: wishlistShare,
  altImageView: altImageView,
  quickViewAltImageView: quickViewAltImageView,
  waitListStart: waitListStart,
  waitListComplete: waitListComplete,
  bopusStart: bopusPDPStart,
  bopusSearch: bopusPDPSearch,
  bopusCartSearch: bopusCartSearch,
  bopusCartSelect: bopusCartSelect,
  findStoreSearch: findStoreSearch,
  searchRedirect: searchRedirect,
  buildErrorInfo: buildErrorInfo,
  provideCartLineItem: provideCartLineItem,
  callToOrder: callToOrder
};
