'use strict';

var cartHelper = require('../cart/cartHelper');
var atmHelper = require('../atm/atmHelper');
var giftCard = require('../checkout/giftcard');

/** Constants */
const $body = $('body');

const signatureToggle = function(signature) {
  if (signature) {
    $('.signature-required').removeClass('d-none');
    $('.signature-checkbox').addClass('d-none');
    $('.signature-is-required').removeClass('d-none');
  } else {
    $('.signature-required').addClass('d-none');
    $('.signature-checkbox').removeClass('d-none');
    $('.signature-is-required').addClass('d-none');
    if ($('.signature-checkbox input.signature').is(':checked')) {
      $('.signature-checkbox input.signature').removeAttr('checked');
    }
  }
}

module.exports = function () {
  $body.on('click', '.delete-coupon-confirmation-btn', function (e) {
    e.preventDefault();

    const $this = $(this);
    var url = $this.data('action');
    var uuid = $this.data('uuid');
    var couponCode = $this.data('code');
    var urlParams = {
      code: couponCode,
      uuid: uuid
    };

    url = cartHelper.appendToUrl(url, urlParams);

    $body.children('.modal-backdrop').remove();

    $.spinner().start();
    $.ajax({
      url: url,
      type: 'get',
      dataType: 'json',
      success: function (data) {
        $('.coupon-uuid-' + uuid).remove();
        cartHelper.updateCartTotals(data);
        cartHelper.updateApproachingDiscounts(data.approachingDiscounts);
        cartHelper.updateDiscountsHtml(data);
        cartHelper.updateAppliedCouponCount(data);
        cartHelper.validateBasket(data);
        $.spinner().stop();
      },
      error: function (err) {
        if (err.responseJSON.redirectUrl) {
          window.location.href = err.responseJSON.redirectUrl;
        } else {
          cartHelper.createErrorNotification(err.responseJSON.errorMessage);
          $.spinner().stop();
        }
      }
    });
  });

  $body.on('submit', '.promo-code-form', function (e) {
    e.preventDefault();
    $.spinner().start();

    const $promoCodeForm = $('.promo-code-form');
    const $promoFormControl = $promoCodeForm.find('.form-control');
    const $couponCodeField = $('.coupon-code-field');
    const $promoCodeBtn = $('.promo-code-btn');
    const $couponMissingError = $('.coupon-missing-error');
    const $couponErrorMessage = $('.coupon-error-message');
    const $promoLabel = $('.promo-input label');
    if (window.innerWidth < 1024 || window.isTouchscreen()) {
      $promoCodeBtn.addClass('promo-code-hovered');
    }
    $couponMissingError.hide();
    $couponErrorMessage.empty();
    if (!$couponCodeField.val()) {
      $promoFormControl.addClass('is-invalid');
      $('.promo-input label').addClass('is-invalid');
      $couponCodeField.next('span.invalid').remove();
      $('<span class="invalid"></span>').insertAfter('.coupon-code-field');
      $promoFormControl.attr('aria-describedby', 'missingCouponCode');
      $couponMissingError.show();
      $.spinner().stop();
      $promoCodeBtn.removeClass('promo-code-hovered');
      let formData = {};
      formData.errorFields = ['coupon code'];
      formData.formName = 'cart';
      $body.trigger('adobeTagManager:formError', formData);
      return false;
    }
    $promoFormControl.removeClass('is-invalid');
    $('.promo-input label').removeClass('is-invalid');
    $couponCodeField.next('.invalid').remove();
    $couponErrorMessage.empty();

    $.ajax({
      url: $promoCodeForm.attr('action'),
      type: 'GET',
      dataType: 'json',
      data: $promoCodeForm.serialize(),
      success: function (data) {
        let promoCoupon = {};
        promoCoupon.status = data && !data.error;
        promoCoupon.coupon = $couponCodeField.val();
        $body.trigger('adobeTagManager:promoCodeEntered', promoCoupon);
        if (data.error) {
          $promoFormControl.addClass('is-invalid');
          $promoLabel.addClass('is-invalid');
          $('<span class="invalid"></span>').insertAfter('.coupon-code-field');
          $promoFormControl.attr('aria-describedby', 'invalidCouponCode');
          $couponErrorMessage.html(data.errorMessage);
          $couponCodeField.val(promoCoupon.coupon);
          if (data.errorMessage) {
            var currentScope = '';
            if (window.pageData && window.pageData.page && window.pageData.page.type) {
              if (window.pageData.page.type === 'shopping bag') {
                currentScope = 'cart error'
              } else {
                currentScope = window.pageData.page.type + ' error';
              }
            }
            var errorInfo = atmHelper.buildErrorInfo([], currentScope, data.errorMessage, 'server-side');
            $body.trigger('adobeTagManager:trackError', errorInfo);
          }
          //Renders Coupon Validation Modal for Incompatible Coupons with Gift Cards applied and attach event to the remove button. Feature from PAY-209
          if (data.incompatibleCoupon && data.renderedTemplate) {
            if ($('#promoGiftCardValidationModal').length) {
              $('#promoGiftCardValidationModal').remove();
            }
            $('body').append(data.renderedTemplate);
            $('#promoGiftCardValidationModal').modal('show');
            $('#removeGiftCard').off('click').on('click', function (e) {
              e.preventDefault();
              var gcNumbersFromModal = $(this).data('gc-numbers');
              var removeGiftCardUrl = $(this).data('action');
              giftCard.removeGiftCardActionWithParameters(removeGiftCardUrl, null, gcNumbersFromModal, true);
            });
          }
        } else if ($('.page.checkout').length > 0 && data.iseligibledbonusitem && data.redirectURL) {
          window.location.href = data.redirectURL;
        } else {
          $('.coupons-and-promos').html(data.totals.discountsHtml);
          cartHelper.updateItemsHTML(data);
          cartHelper.updateCartTotals(data);
          cartHelper.updateApproachingDiscounts(data.approachingDiscounts);
          cartHelper.updateDiscountsHtml(data);
          cartHelper.updateAppliedCouponCount(data);
          cartHelper.updateTotalSavings(data);
          cartHelper.updateOrderSummary(data);
          cartHelper.validateBasket(data);
          $couponCodeField.val('');

          // =========== promo code toast start ===========
          if (
            window.webkit &&
            window.webkit.messageHandlers &&
            window.webkit.messageHandlers.showToast &&
            data.totals &&
            data.totals.discounts
          ) {
            // Grab the previous submitted coupon code from the queryString
            const matchedCoupon = (data.queryString).match(/couponCode=(.*)\&/)

            // find the discount object based on the coupon code
            var lastAppliedCouponCode = matchedCoupon[1] && data.totals.discounts.find(function (discount) {
              return discount.couponCode.toUpperCase() === matchedCoupon[1].toUpperCase()
            })

            if (lastAppliedCouponCode) {
              const couponCode = lastAppliedCouponCode.couponCode.toUpperCase()
              if (lastAppliedCouponCode.valid && lastAppliedCouponCode.applied) {
                window.webkit.messageHandlers.showToast.postMessage({ variant: 'success', title: 'Promo Code Applied', message: couponCode + ' has been applied to your bag.' });
              } else if (lastAppliedCouponCode.valid) {
                if (lastAppliedCouponCode.cpnMessage) {
                  window.webkit.messageHandlers.showToast.postMessage({ variant: 'warning', title: 'Promo Code Not Applied', message: lastAppliedCouponCode.cpnMessage });
                } else {
                  window.webkit.messageHandlers.showToast.postMessage({ variant: 'warning', title: 'Promo Code Not Applied', message: 'The item(s) in your bag do not qualify for ' + couponCode + '.' });
                }
              }
            }
          }
          // =========== promo code toast end ===========


          if (data.iseligibledbonusitem && data.eligibledbonusitem != undefined) {
            $body.trigger('adobe:getbonusproductonAppliedCoupon', data.eligibledbonusitem);
          }
          $body.trigger('klarna:payment-stage', data.order);
        }
        if (data.order && data.order.orderSummaryPaymentHtml) {
          $('.order-summary-payment-applied').empty().html(data.order.orderSummaryPaymentHtml);
        }
        // update signature required
        signatureToggle(data.signatureRequired);

        $body.trigger('checkout:removeAmexPoints');
        $('.amex-main').removeClass('d-none');
        $('.amex-points-container').addClass('d-none');

        window.hbcTooltip.tooltipInit();
        $.spinner().stop();
        $promoCodeBtn.removeClass('promo-code-hovered');
      },
      error: function (err) {
        if (err.responseJSON.redirectUrl) {
          window.location.href = err.responseJSON.redirectUrl;
        } else {
          cartHelper.createErrorNotification(err.errorMessage);
          $.spinner().stop();
          $promoCodeBtn.removeClass('promo-code-hovered');
        }
      }
    });
    return false;
  });

  $body.on('mouseover', '.promo-code-btn', function () {
    if (window.innerWidth > 1023 && !window.isTouchscreen()) {
      const $this = $(this);

      if ($this.attr('disabled') !== 'disabled') {
        $this.addClass('promo-code-hovered');
      }
    }
  });

  $body.on('mouseout', '.promo-code-btn', function () {
    if (window.innerWidth > 1023 && !window.isTouchscreen()) {
      $(this).removeClass('promo-code-hovered');
    }
  });

  $body.on('focusout', '.coupon-code-field', function (e) {
    e.preventDefault();
    const $form = $('.promo-code-form');
    const $couponCodeField = $form.find('.coupon-code-field');
    let couponCode = $('.coupon-code-field').val();
    if (!couponCode) {
      $('.coupon-missing-error').hide();
      $couponCodeField.removeClass('is-invalid');
      $couponCodeField.next('span').remove();
      $form.find('span.coupon-error-message').empty();
      $couponCodeField.next('label').removeClass('is-invalid').removeClass('input-focus');
    }
  });

  $body.on('click', '.remove-coupon', function (e) {
    e.preventDefault();

    const $this = $(this);
    let url = $this.data('action');
    let uuid = $this.data('uuid');
    let couponCode = $this.data('code');
    let urlParams = {
      code: couponCode,
      uuid: uuid
    };

    var addGiftCardUrl = $(this).data('add-card');
    var gcNumberFromModal = $(this).data('gc-number');
    var gcPinFromModal = $(this).data('gc-pin');

    url = cartHelper.appendToUrl(url, urlParams);

    $body.children('.modal-backdrop').remove();

    $body.trigger('checkout:removeAmexPoints');
    $('.amex-main').removeClass('d-none');
    $('.amex-points-container').addClass('d-none');

    $.spinner().start();
    $.ajax({
      url: url,
      type: 'get',
      dataType: 'json',
      success: function (data) {
        $('.coupon-uuid-' + uuid).remove();
        $('.coupons-and-promos').html(data.totals.discountsHtml);
        cartHelper.updateItemsHTML(data);
        cartHelper.updateCartTotals(data);
        cartHelper.updateApproachingDiscounts(data.approachingDiscounts);
        cartHelper.updateAppliedCouponCount(data);
        cartHelper.updateDiscountsHtml(data);
        cartHelper.updateTotalSavings(data);
        cartHelper.validateBasket(data);
        cartHelper.updateOrderSummary(data);
        $body.trigger('klarna:payment-stage', data.order);
        if (data.order && data.order.orderSummaryPaymentHtml) {
          $('.order-summary-payment-applied').empty().html(data.order.orderSummaryPaymentHtml);
        }
        //Adds Gift Card after removing incompatible promo code - Feature from PAY-209
        if (addGiftCardUrl && gcNumberFromModal && gcPinFromModal) {
          giftCard.giftCardBalanceCheckWithParameters(null, addGiftCardUrl, gcNumberFromModal, gcPinFromModal);
        }
        // update signature required
        signatureToggle(data.signatureRequired);
        $.spinner().stop();
      },
      error: function (err) {
        if (err.responseJSON.redirectUrl) {
          window.location.href = err.responseJSON.redirectUrl;
        } else {
          cartHelper.createErrorNotification(err.responseJSON.errorMessage);
          $.spinner().stop();
        }
      }
    });
  });

  $('.promoRewHolder').on('input', 'input.emptyCheck', function () {
    const $this = $(this);

    if ($this.val().length > 0) {
      $this.closest('.promoRewHolder').find('.prmoRewBtnHolder button').removeAttr('disabled');
    } else {
      const $promoRewHolder = $this.closest('.promoRewHolder');
      $promoRewHolder.find('.prmoRewBtnHolder button').attr('disabled', true);
      $promoRewHolder.find('span.coupon-error-message').empty();
      $this.removeClass('is-invalid');
      $this.next('span').remove();
      $this.closest('.promo-code-entry').find('label').removeClass('is-invalid');
    }
  });
};
