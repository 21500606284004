'use strict';
var atmHelper = require('core/atm/atmHelper');

/**
 * Updates product array
 *
 * @param {function} callback - callback function
 */

/**
 * Provide product info
 * @param {Object} pid Cart product ID
 * @returns {Object} product get product info from Cart
 */
function provideProductInfoInCart(pid) {
  var cartProdID = pid;
  var $cartProd = $('.prdt-cart-details').find('[data-atm-selectedsku="' + cartProdID + '"]');
  var product = {};
  var orgPrice = null;
  var tprice = null;
  var $listPrice = $cartProd.find('.bfx-list-price.formatted_price');
  var $salePrice = $cartProd.find('.bfx-sale-price.formatted_price');

  product.brand = $cartProd.find('.brand-name').length ? $('.brand-name').first().text().trim() : '';
  product.code = $cartProd.length ? $cartProd.data('atm-code') : '';
  product.name = $cartProd.find('.bfx-product-name').first().text().trim();

  if ($('.price .range').length) {
    orgPrice = $listPrice.first().data('unformatted-price').toString();
    tprice = $listPrice.last().data('unformatted-price').toString();
  } else {
    orgPrice = $listPrice.data('unformatted-price').toString();
    tprice = $salePrice.length ? $salePrice.data('unformatted-price').toString() : $listPrice.data('unformatted-price').toString();
  }
  product.original_price = orgPrice;
  product.price = tprice;
  product.quantity = $cartProd.find('.bfx-product-qty').val().toString();
  product.selected_sku = cartProdID.toString();
  return product;
}

/**
 * Load product object
 * @param {Object} qproduct product from response
 * @returns {Object} product updated product
 */
function loadProductObject(qproduct) {
  var product = {};
  if (qproduct) {
    product.brand = qproduct.brand.name ? qproduct.brand.name : '';
    product.code = qproduct.masterProductID;
    product.name = qproduct.productName;
    var orgPrice = null;
    var currPrice = null;
    if (qproduct.price.type && qproduct.price.type === 'range') {
      orgPrice = qproduct.price.max.list === null ? qproduct.price.max.sales.value : qproduct.price.max.list.value;
      currPrice = qproduct.price.max.sales.value.toString();
    } else if (qproduct.productType === 'set') {
      orgPrice = qproduct.price.list.value === null ? qproduct.price.sales.value : qproduct.price.list.value;
      currPrice = qproduct.price.sales.value;
    } else {
      orgPrice = qproduct.price.list === null ? qproduct.price.sales.value : qproduct.price.list.value;
      currPrice = qproduct.price.sales.value;
    }
    product.original_price = orgPrice !== null ? orgPrice.toString() : '';
    product.price = currPrice ? currPrice.toString() : '';
    product.selected_sku = qproduct.id;
    if (qproduct.ad_type) {
      product.ad_type = qproduct.ad_type;
    }
    if (qproduct.ad_page_type) {
      product.ad_page_type = qproduct.ad_page_type;
    }
    if (qproduct.ad_position) {
      product.ad_position = qproduct.ad_position;
    }
  }
  return product;
}

/**
 * get selected stores
 * @returns {Object} array return all stores
 */
function getStores() {
  var refinementValuesArray = {};
  if ($('#bopisCheck:checked').length) {
    refinementValuesArray.name = $('a.change-store').text().trim();
    refinementValuesArray.type = 'selected';
  }
  return refinementValuesArray;
}

/**
 * Get refinement
 * @param {HTMLElement} targetElement - event target element with url data
 * @returns {Object} - refinement object
 */
function getRefinement(targetElement) {
  var $targetElement = $(targetElement);
  var refinements = [];

  $.each($('.refinements .refinement'), function (index, refinement) {
    var trefinement = {};
    var refinementValuesArray = {};
    var $refinement = $(refinement);
    var $refinementCategory = $targetElement.find('.refinement-category');

    trefinement.name = $refinement.find('.card-header h2').text().trim();
    trefinement.values = [];

    if ($refinement.hasClass('refinement-category') && $refinementCategory.text()) {
      refinementValuesArray.name = $refinementCategory.text().trim();
      refinementValuesArray.type = 'selected';
      trefinement.values.push(refinementValuesArray);
    } else if ($refinement.hasClass('refinement-color')) {
      $.each($('.refinement-color .selected'), function (indexColor, colorRefinement) {
        refinementValuesArray = {};
        refinementValuesArray.name = $(colorRefinement).attr('title');
        refinementValuesArray.type = 'selected';
        trefinement.values.push(refinementValuesArray);
      });
    } else if ($refinement.hasClass('refinement-price')) {
      $.each($('.refinement-price .selected'), function (indexPrice, priceRange) {
        refinementValuesArray.name = $(priceRange).closest('li').find('.bfx-price').data('pricerange').trim();
        refinementValuesArray.type = 'selected';
        trefinement.values.push(refinementValuesArray);
      });

      var $filterMinPrice = $('input[name$="filterMinprice"]');
      var $filterMaxPrice = $('input[name$="filterMaxprice"]');

      if (trefinement.values.length === 0 && $filterMinPrice.length > 0 && $filterMaxPrice.length > 0) {
        const minVal = parseInt($filterMinPrice.val());
        const maxVal = parseInt($filterMaxPrice.val());

        if (!isNaN(minVal) && !isNaN(maxVal)) {
          refinementValuesArray.name = '$' + minVal + '-$' + maxVal;
          refinementValuesArray.type = 'selected';
          trefinement.values.push(refinementValuesArray);
        }
      }

      var minPrice = $('input[name$="minPrice"]').val();
      var maxPrice = $('input[name$="maxPrice"]').val();

      if (trefinement.values.length === 0 && minPrice !== '' && maxPrice !== '') {
        const minVal = parseInt(minPrice);
        const maxVal = parseInt(maxPrice);
        if (!isNaN(minVal) && !isNaN(maxVal)) {
          refinementValuesArray.name = '$' + minVal + '-' + '$' + maxVal;
          refinementValuesArray.type = 'selected';
          trefinement.values.push(refinementValuesArray);
        }
      }
    } else if ($refinement.hasClass('refinement-promotion')) {
      $.each($('.refinement-promotion .selected'), function (indexPromo, promoRefinement) {
        refinementValuesArray.name = $(promoRefinement).next('span').text().trim();
        refinementValuesArray.type = 'selected';
        trefinement.values.push(refinementValuesArray);
      });
    } else if ($refinement.hasClass('refinement-sales--offers')) {
      $.each($('.refinement-sales--offers .selected'), function (indexPrice, promoRefinement) {
        refinementValuesArray.name = $(promoRefinement).next('span').text().trim();
        refinementValuesArray.type = 'selected';
        trefinement.values.push(refinementValuesArray);
      });
    } else if ($refinement.hasClass('refinement-get-it-fast')) {
      $.each($('#refinement-get-it-fast input:checked'), function (indexPrice, getitfastRefinement) {
        refinementValuesArray.name = $(getitfastRefinement).val().trim();
        refinementValuesArray.type = 'selected';
        trefinement.values.push(refinementValuesArray);
      });
    } else {
      $.each($refinement.find('ul li .selected'), function (indexother, attrRefinement) {
        refinementValuesArray = {};
        refinementValuesArray.name = $(attrRefinement).clone().children().remove().end().text().trim();
        refinementValuesArray.type = 'selected';
        trefinement.values.push(refinementValuesArray);
      });
    }
    refinements.push(trefinement);
  });

  var storeRefinement = {};
  storeRefinement.name = 'stores';
  storeRefinement.values = [];
  var storesArray = getStores();
  if (!jQuery.isEmptyObject(storesArray)) {
    storeRefinement.values.push(storesArray);
  }
  refinements.push(storeRefinement);
  return refinements;
}

/**
 * Get All Products
 * @returns {Object} - return all products
 */
function getProducts() {
  var products = [];

  $.each($('div[data-pid].product'), function (index, item) {
    var product = {};
    var pid = $(item).data('pid');

    product.code = pid ? pid.toString() : '';
    products.push(product);
  });

  return products;
}

/**
 * Provide product info
 * @returns {Object} product get product info from PDP
 */
function provideProductInfoInPdp() {
  var product = {};
  var $productBrandName = $('.product-detail h1.product-brand-name');
  var $atmCode = $('div[data-atm-code]');
  var orgPrice = null;
  var tprice = null;

  var $listPrice = $('.product-detail .bfx-list-price.formatted_price');
  var $salePrice = $('.product-detail .bfx-sale-price.formatted_price');

  product.brand = $productBrandName.length ? $productBrandName.first().text().trim() : '';
  product.code = $atmCode.length ? $atmCode.data('atm-code') : '';
  product.name = $('.product-detail .product-name').first().text().trim();

  if ($('.price .range').length) {
    orgPrice = $listPrice.first().data('unformatted-price').toString();
    tprice = $listPrice.last().data('unformatted-price').toString();
  } else {
    orgPrice = $listPrice.data('unformatted-price').toString();
    tprice = $salePrice.length ? $salePrice.data('unformatted-price').toString() : $listPrice.data('unformatted-price').toString();
  }

  product.original_price = orgPrice;
  product.price = tprice;
  product.quantity = '1';
  product.selected_sku = $('.product-detail').data('pid') ? $('.product-detail').data('pid').toString() : '';
  return product;
}

/**
 * Provide product info
 * @returns {Object} product get product info from Saks landing page
 */
function provideProductInfoInSaksplus() {
  var product = {};
  var saksplusProductPrice = $('input[class*="saksplus-product-price"]').data('price').toString();

  product.brand = $('input[class*="saksplus-product-brandname"]').data('brandname').toString();
  product.code = $('input[class*="saksplus-product-atm-code"]').data('atm-code').toString();
  product.name = $('input[class*="saksplus-product-name"]').data('product-name').toString();
  product.original_price = saksplusProductPrice;
  product.price = saksplusProductPrice;
  product.quantity = '1';
  product.selected_sku = $('input[class*="product-detail"]').data('pid').toString();
  return product;
}

/**
 * Retrieve information from prouct tile element
 * @param {jQuery} tileElement Product Tile Element
 * @returns {Object} product information extracted from product tile
 */
function getProductDataFromTile (tileElement) {
  var productClickData = [];
  if (tileElement) {
    var $tileElement = $(tileElement);
    var productData = {};
    var priceType = '';
    if ($tileElement.hasClass('criteo-sponsored-product')) {
      productData.ad_type = 'sponsored product';
      productData.ad_position = 'in-grid';
    } else if (location.search.indexOf('retail_network') > -1) {
      var url = new URL(document.location);
      var [pageType, adType, adPosition] = url.searchParams.get('retail_network') ? url.searchParams.get('retail_network').split('^') : '';
      productData.ad_page_type = pageType || '';
      productData.ad_type = adType || '';
      productData.ad_position = adPosition || '';
    } else {
      productData.ad_page_type = '';
      productData.ad_type = '';
      productData.ad_position = '';
    }
    var productEl = $tileElement.find('.product');
    productData.average_rating = $tileElement.find('.TTteaser').data('starrating') !== '0.0' ? $tileElement.find('.TTteaser').data('starrating').toString() : '';
    productData.brand = $($tileElement.find('.product-brand')[0]).text() || '';
    productData.code = $($tileElement.find('.tileproduct-atm-code')[0]).data('atm-code') || '';
    productData.name = $($tileElement.find('.link')[0]).text() || '';
    productData.original_price = ($tileElement.find('.price').find('.list').find('formatted_price').data('unformatted-price') || $tileElement.find('.js-final-sale-price').data('unformatted-price') || '').toString();
    productData.price = $tileElement.find('.js-final-sale-price').data('unformatted-price').toString();
    productData.tags = {};
    productData.tags.feature_type = productEl.data('feature-type').toString();
    productData.tags.inventory_label = $tileElement.find('.tile-inventory-message').find('span').text() || '';
    productData.tags.pip_text = $($tileElement.find('.promotion-txt-label')[0]).text().replace(/\n/g, '').trim();
    if (productEl.data('is-clearance')) {
      priceType = 'clearance';
    } else if (productEl.data('is-final-sale')) {
      priceType = 'final sale';
    }
    productData.tags.price_type = priceType;
    productData.tags.publish_date = productEl.data('publish-date');
    productData.tags.returnable = productEl.data('returnable').toString();
    productData.total_reviews = productEl.data('review-count').toString();
    productClickData.push(productData);
  }
  return productClickData;
}

atmHelper.productArrayUpdate = function (callback) {
  $('body').on('adobeTagManager:productArrayUpdate', function (e, $this) {
    var refinements = getRefinement($this);
    var selectedOption = $('.sort-selection-block').find('select[name="sort-order"]')[0].selectedOptions[0];
    var sortType = $(selectedOption).index() > 0 ? 'selected' : 'default';

    var productArray = {
      array_page_number: pageData.product_array ? pageData.product_array.array_page_number : '1', //eslint-disable-line
      model_view: pageData.product_array ? pageData.product_array.model_view : 'off',
      refinements: refinements,
      results_per_page: getProducts().length ? getProducts().length.toString() : '0',
      sort: {
        name: $(selectedOption).data('id'),
        type: sortType
      },
      total_results: $('.search-count').data('search-count') ? $('.search-count').data('search-count').toString() : '0',
      results_across: pageData.product_array ? pageData.product_array.results_across : '1' //eslint-disable-line
    };
    if (callback) {
      callback(productArray, getProducts());
    }
    if (window.pageData) {
      window.pageData.product_array = productArray;
      window.pageData.products = getProducts();
    }
    if (window.pageDataObj) {
      window.pageDataObj.product_array = productArray;
      window.pageDataObj.products = getProducts();
    }
  });
};

/**
 * On step change of checkout
 * @param {function} callback - callback function
 */

atmHelper.checkoutStepChange = function (callback) {
  $('body').on('adobeTagManager:checkoutStepChange', function (e, steps) {
    if ($('body').find('.is-invalid').length > 0) {
      return;
    }
    var page = {};
    if (!pageData.visitor) {
      pageData.visitor = {};
    }
    pageData.visitor.amex_points_available = steps.hasAmexPoint;
    pageData.visitor.amex_points_check = false;
    pageData.visitor.amex_points_apply = false;
    page.checkoutStep = steps.step;
    var $addToEmail = $('#add-to-email-list');
    pageData.visitor.email_opt_in = $addToEmail.length && steps.step === 'payment' ? $addToEmail.prop('checked').toString() : 'false';
    if (steps.optIn) {
      pageData.visitor.email_opt_in = 'true';
    }
    pageData.visitor.new_account = steps.new_account != 'undefined' && steps.new_account ? 'true' : 'false';
    pageData.visitor.new_payment = steps.newpayment != 'undefined' && steps.newpayment ? 'true' : 'false';
    if (callback) {
      callback(page, pageData.products, pageData.visitor);
    }
  });
};

atmHelper.checkAmexPoints = function (callback) {
  $('body').on('checkout:updateAmexForm', function () {
    var maxTotal = parseFloat($('.grand-total-sum').text().replace('$', '').replace(',', ''));

    $('#amex-amount').data('amount', maxTotal);
    $('.amex-order-total').text('$' + maxTotal);

    if (!pageData.visitor) {
      pageData.visitor = {};
    }
    pageData.visitor.amex_points_check = true;
    if (callback) {
      callback();
    }
  });
};

atmHelper.applyAmexPoints = function (callback) {
  $('body').on('checkout:applyAmex', function () {
    if ($('body').find('.is-invalid').length > 0) {
      return;
    }
    if (!pageData.visitor) {
      pageData.visitor = {};
    }
    pageData.visitor.amex_points_apply = true;
    if (callback) {
      callback();
    }
  });
};

/**
 * Quick view events
 * @param {function} callback - Callback function
 */
atmHelper.showQuickView = function (callback) {
  $('body').on('adobeTagManager:showQuickView', function (e, qproduct) {
    var products = [];
    var product = loadProductObject(qproduct);
    var priceType = '';

    product.average_rating = qproduct.starRating ? qproduct.starRating.toString() : '';
    delete product.selected_sku;
    product.skus = qproduct.allAvailableProducts;

    if (qproduct.badge.isClearance) {
      priceType = 'clearance';
    } else if (qproduct.badge.isFinalSale) {
      priceType = 'final sale';
    }

    product.tags = {
      feature_type: qproduct.featuredType.value ? qproduct.featuredType.value : '',
      inventory_label: qproduct.availability.messages && qproduct.availability.messages.length > 0 ? qproduct.availability.messages[0] : '',
      pip_text: qproduct.promotions && qproduct.promotions.length > 0 ? qproduct.promotions[0].calloutMsg : '',
      price_type: priceType,
      publish_date: qproduct.preOrder && qproduct.preOrder.applicable == true ? 'preorder' : 'new',
      returnable: (!qproduct.isNotReturnable.value).toString()
    };
    if (qproduct.adType) {
      product.ad_type = qproduct.adType.replace('_', ' ');
      product.ad_position = 'in-grid';
    } else if (location.search.indexOf('retail_network') > -1) {
      var url = new URL(document.location);
      var [pageType, adType, adPosition] = url.searchParams.get('retail_network') ? url.searchParams.get('retail_network').split('^') : '';
      product.ad_page_type = pageType || '';
      product.ad_type = adType || '';
      product.ad_position = adPosition || '';
    }
    product.total_reviews = qproduct.turntoReviewCount ? qproduct.turntoReviewCount.toString() : '';
    products.push(product);
    if (callback) {
      callback(products);
    }
  });
};

/**
 * trigger on select modelview
 * @param {function} callback function
 */

atmHelper.toggleModelView = function (callback) {
  $('body').on('adobeTagManager:toggleModelView', function (e, $this) {
    var enablemodelview = 'off';
    var productArray = {};
    var refinements = getRefinement($this);
    var sortType = 'default';
    var $sortSelect = $('.sort-selection-block').find('select[name="sort-order"] option:selected');

    if ($($this).hasClass('model-view-on')) {
      enablemodelview = 'on';
    }

    if ($sortSelect.index() > 0) {
      sortType = 'selected';
    }

    if ($($this).closest('.wishlist-header-wrap').length > 0) {
      productArray = {
        array_page_number: pageData.product_array ? pageData.product_array.array_page_number : '1', //eslint-disable-line
        model_view: enablemodelview,
        results_per_page: getProducts().length ? getProducts().length.toString() : '0',
        total_results: getProducts().length ? getProducts().length.toString() : '0',
        results_across: pageData.product_array ? pageData.product_array.results_across : '1' //eslint-disable-line
      };
    } else {
      const searchCount = $('.search-count').data('search-count');

      productArray = {
        array_page_number: pageData.product_array ? pageData.product_array.array_page_number : '1', //eslint-disable-line
        model_view: enablemodelview,

        refinements: refinements,
        results_per_page: getProducts().length ? getProducts().length.toString() : '0',
        sort: {
          name: $sortSelect.data('id'),
          type: sortType
        },
        total_results: searchCount ? searchCount.toString() : '0',
        results_across: pageData.product_array ? pageData.product_array.results_across : '1' //eslint-disable-line
      };
    }

    if (callback) {
      callback(productArray, getProducts());
    }
  });
};

/**
 * Wait list start
 * @param {function} callback function
 */
atmHelper.waitListStart = function (callback) {
  $('body').on('adobe:waitListStart', function () {
    var products = [];
    var product = {};

    if ($('#waitlist-form-saksplus').length) {
      product = provideProductInfoInSaksplus();
    } else {
      product = provideProductInfoInPdp();
      product.quantity = $('.quantity-val').val();
    }

    products.push(product);

    if (callback) {
      callback(products);
    }
  });
};

/**
 * Wait list complete
 * @param {function} callback function
 */

atmHelper.waitListComplete = function (callback) {
  $('body').on('adobe:waitListComplete', function (e, data) {
    var products = [];
    var product = {};

    if ($('#waitlist-form-saksplus').length) {
      product = provideProductInfoInSaksplus();
    } else {
      product = provideProductInfoInPdp();
      product.quantity = $('.quantity-val').val();
    }

    products.push(product);
    let hashEmail;
    let hashPhone;
    if (data.emailSHA256) {
      window.pageData.visitor.SHA256_hash_email_address = data.emailSHA256;
      hashEmail = data.emailSHA256;
    }
    if (data.customerPhoneSHA256) {
      window.pageData.visitor.SHA256_hash_phone = data.customerPhoneSHA256;
      hashPhone = data.customerPhoneSHA256;
    }
    if (callback) {
      callback(products, hashEmail, hashPhone);
    }
  });
};

/**
 * trigger on cancel order
 * @param {function} callback - callback function
 */
atmHelper.cancelOrder = function (callback) {
  $('body').on('adobeTagManager:cancelOrder', function (e, orderNo) {
    if (callback) {
      callback(orderNo);
    }
  });
};

/**
 * Move cart to wishlist
 * @param {function} callback - callback function
 */
atmHelper.cartMoveToFav = function (callback) {
  $('body').on('adobeTagManager:cartMoveToFav', function (e, product) {
    var products = [];
    products.push(product);
    if (callback) {
      callback(products);
    }
  });
};

atmHelper.selectbonusProduct = function (callback) {
  $('body').on('adobe:selectbonusProduct', function (e, response) {
    var products = [];
    $.each(response.selectedbonusitems, function (index, item) {
      products.push(item);
    });
    if (callback) {
      callback(products);
    }
  });
};

atmHelper.getbonusproductonAppliedCoupon = function (callback) {
  $('body').on('adobe:getbonusproductonAppliedCoupon', function (e, item) {
    var products = [];
    products.push(item);
    if (callback) {
      callback(products);
    }
  });
};

/**
 * Used for search redirects
 * @param {function} callback - callback function
 */
atmHelper.searchRedirect = function (callback) {
  $('body').on('click', 'form.search-form button[name="search-button"]', function (e) {
    var searchTerm = $(this).siblings('input.search-field').val();
    var search = {
      term: searchTerm
    };
    if (callback) {
      callback(search);
    }
  });
};

/**
 * This ATM is used for search sugestion objec
 * @param {function} callback - callback function
 */
atmHelper.suggestedSearch = function (callback) {
  $('body').on('click', '.search-suggestions .search-atm', function () {
    var $this = $(this);
    var search = {
      term: $this.data('term'),
      type: $this.closest('.search-suggestions').find('header h3').data('atm-searchtype'),
      typedText: $('.search-form .search-field').val()
    };

    if (callback) {
      callback(search);
    }
    return true;
  });
};

atmHelper.pdpShopTheLook = function (callback) {
  $('body').on('adobeTagManager:shopTheLook', function (e, data) {
    const qproduct = data.product;
    let products = [];
    const product = loadProductObject(qproduct);
    let priceType = '';

    product.average_rating = qproduct.starRating ? qproduct.starRating.toString() : '';
    delete product.selected_sku;

    product.ctlProductCode = data.recommender;
    product.ctlSlotNumber = data.posinset;

    if (qproduct.badge.isClearance) {
      priceType = 'clearance';
    } else if (qproduct.badge.isFinalSale) {
      priceType = 'final sale';
    }

    product.tags = {
      feature_type: qproduct.featuredType.value ? qproduct.featuredType.value : '',
      inventory_label: qproduct.availability.messages && qproduct.availability.messages.length > 0 ? qproduct.availability.messages[0] : '',
      pip_text: qproduct.promotions && qproduct.promotions.length > 0 ? qproduct.promotions[0].calloutMsg : '',
      price_type: priceType,
      publish_date: qproduct.preOrder && qproduct.preOrder.applicable === true ? 'preorder' : 'new',
      returnable: (!qproduct.isNotReturnable.value).toString()
    };
    product.total_reviews = qproduct.turntoReviewCount ? qproduct.turntoReviewCount.toString() : '';
    products.push(product);

    if (callback) {
      callback(products);
    }
  });
};

/**
 * Track of errors from Cart and Checkout pages
 * @param {function} callback - callback function
 */

atmHelper.trackError = function (callback) {
  $('body').on('adobeTagManager:trackError', function (e, errorInfo) {
    if (errorInfo) {
      var products = errorInfo.products || [];
      var error = {};

      error.type = errorInfo.type;
      error.message = errorInfo.message;
      error.source = errorInfo.source;

      if (callback) {
        callback(products, error);
      }
    }
  });
};

atmHelper.closeWidget = function (callback) {
  $('body').on('adobeTagManager:closeWidget', function (e, payload) {
    if (callback) {
      callback(payload);
    }
  });
};

/**
 * trigger on cancel pre order
 * @param {function} callback - callback function
 */
atmHelper.cancelPreOrder = function (callback) {
  $('body').on('adobeTagManager:cancelPreOrder', function (e, orderNo) {
    if (callback) {
      callback(orderNo);
    }
  });
};

atmHelper.updateGlobalData = function () {
  if (typeof globalData === 'undefined') {
    globalData = {};
  }

  // Push page specific data to GTM if exists
  if (pageDataObj && Object.keys(pageDataObj).length !== 0) {
    for (var prop in pageDataObj) {
      if (prop && pageDataObj.hasOwnProperty(prop)) {
        globalData[prop] = pageDataObj[prop];
      }
    }
  }

  window.pageData = globalData;
  pageData.page = pageData.page || {};

  var urlEmail = decodeURIComponent(window.location.href);
  urlEmail = urlEmail.split('emailuser=').length > 1 ? urlEmail.split('emailuser=')[1] : '';

  if (urlEmail.split('&').length > 1) {
    urlEmail = urlEmail.split('&')[0];
  }

  if (urlEmail && urlEmail !== '') {
    window.pageData.visitor.SHA256_hash_email_address = urlEmail;
  }

  // overwrite search.type & search.term from mobile app injection
  if (window.mobileApp) {
    if (window.mobileApp.search) {
      // we're injecting into pages that may not have search at pageData level, so we need to ensure pageData.search exists
      // before we attempt to add anything in to it
      if (!window.pageData.search) {
        window.pageData.search = {};
      }
      if (window.mobileApp.search.type) {
        window.pageData.search.type = window.mobileApp.search.type;
      }
      if (window.mobileApp.search.term) {
        window.pageData.search.term = window.mobileApp.search.term;
      }
    }
  }
}


atmHelper.visitorDataPopulated = function (callback) {
  $('body').on('adobeTagManager:visitorDataPopulated', function (e, payload) {
    if (callback) {
      callback(payload);
    }
  });
};

/**
 * Add to bag events
 *
 * @param {function} callback - callback function
 */
atmHelper.addToBag = function (callback) {
  $('body').on('adobeTagManager:addToBag', function (e, cartModel) {
    var products = [];
    var cartLineItem = atmHelper.provideCartLineItem(cartModel);
    var products = cartLineItem.product;
    $.each(products, function (index, product) {
      product.gwp_flag = cartLineItem.selectedLineItem[index].isBonusProductLineItem.toString();
      if ($('.product-grid').find('div.criteo-sponsored-product[data-id=' + product.code + ']').length > 0) {
        product.ad_type = 'sponsored product';
        product.ad_position = 'in-grid';
      } else if (location.search.indexOf('retail_network') > -1) {
        var url = new URL(document.location);
        var [pageType, adType, adPosition] = url.searchParams.get('retail_network') ? url.searchParams.get('retail_network').split('^') : '';
        product.ad_page_type = pageType || '';
        product.ad_type = adType || '';
        product.ad_position = adPosition || '';
      }
    });
    if (callback) {
      callback(products);
    }
  });
}

/**
 * Track product clicks for product tiles in product arrays
 * @param {function} callback - callback function
 */

atmHelper.productClick = function (callback) {
  $('body').on('adobeTagManager:productClick', function (e, productTile) {
    if (productTile) {
      var productClickData = getProductDataFromTile (productTile);
      if (callback) {
        callback(productClickData);
      }
    }
  });
};

/**
 * Add the product to wishlist
 * @param {function} callback - callback function
 */
atmHelper.addToFav = function (callback) {
  $('body').on('adobeTagManager:addToFav', function (e, obj) {
    var products = [];
    var product = loadProductObject(obj);
    product.seller_name = 'HBC';
    product.quantity = '1';
    products.push(product);
    if (callback) {
      callback(products);
    }
  });
}

/**
 * Remove product from wishlist
 * @param {function} callback - callback function
 */
atmHelper.removeFromFav = function (callback) {
  $('body').on('adobeTagManager:removeFromFav', function (e, qproduct) {
    if (qproduct && callback) {
      var products = [];
      var product = loadProductObject(qproduct);
      product.seller_name = 'HBC';
      product.quantity = '1';
      products.push(product);
      callback(products);
    }
  });
}

/**
 * Used for call to order
 * @param {function} callback - callback function
 */
atmHelper.callToOrder = function(callback) {
  $('body').on('adobeTagManager:callToOrder', function (e) {
    var products = [];
    var product = provideProductInfoInPdp();
    product.bopus_store_id = '';
    product.gwp_flag = '';
    product.prev_quantity = '1';
    product.ship_from_store_id = '';
    products.push(product);
    if (callback) {
      callback(products);
    }
  })
}

/**
 * Bread Fin application status details
 * @param {function} callback - callback function
 */
atmHelper.saksCardSubmit = function (callback) {
  $('body').on('adobeTagManager:saksCardSubmit', function (e, cardResult) {
    if (cardResult && callback) {
      var cardStatus = {
          card_type: cardResult.cardType,
          card_result: cardResult.result
      };
    }
    if (callback) {
      callback(cardStatus);
    }
  });
}

module.exports = atmHelper;
