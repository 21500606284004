'use strict';

/**
 * Reset float label.
 */
function resetFloatLabel() {
  // eslint-disable-next-line consistent-return
  $('.form-group input, .form-group select, .form-group textarea').each(function () {
    var $label = $(this).closest('.form-group').find('label');
    // Handled auto-focus issue only in case of IE for address select box CO flow.
    if ($(this).hasClass('selected-option') && $(this).text().length !== '') {
      return false;
    }
    if ($(this).val() !== null) {
      if (!$(this).hasClass('do-not-float')) {
        if (!$(this).val().length && $label.hasClass('input-focus')) {
          $label.removeClass('input-focus');
        }
        if ($(this).val().length) {
          $label.addClass('input-focus');
        }
      }
    } else if ($label.hasClass('input-focus')) {
      $label.removeClass('input-focus');
    }
  });
}

module.exports = {
  resetFloatLabel: resetFloatLabel
};
