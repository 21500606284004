/**
 * load VideoViewer.js and FlyoutViewer.js on user interaction
 * @param {string} videoViewerURL - url of VideoViewer.js
 * @param {string} flyoutViewerUrl - url of FlyoutViewer.js
 * @returns {Promise} promise with fulfill status if scripts are correctly loaded, rejected if one of them fails to load.
 */
function loadS7ViewersScripts(videoViewerURL, flyoutViewerUrl) {
  var promises = [];

  if (videoViewerURL) {
    promises.push(
      new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.onload = resolve;
        script.onerror = reject;
        script.src = videoViewerURL;
        script.async = false;
        document.head.appendChild(script);
      })
    );
  }

  if (flyoutViewerUrl) {
    promises.push(
      new Promise(function (resolve, reject) {
        var script = document.createElement('script');
        script.onload = resolve;
        script.onerror = reject;
        script.src = flyoutViewerUrl;
        script.async = false;
        document.head.appendChild(script);
      })
    );
  }

  return Promise.all(promises);
}

module.exports = {
  loadS7ViewersScripts: loadS7ViewersScripts
};
