'use strict';

const hasGiftCard = items => items.some(
    ({ hbcProductType }) => hbcProductType === 'giftcard'
);

function toggleKlarnaOrderSummary(amount, items = []) {
  if (amount && window.KlarnaOnsiteService && !hasGiftCard(items)) {
    const kosm = document.getElementsByClassName('kosm-cart')[0];
    if (!kosm) return;

    kosm.classList.remove('d-none');

    const klarnaTag = kosm.getElementsByTagName('klarna-placement')[0];
    klarnaTag.setAttribute('data-purchase-amount', Math.round(amount * 100));
    window.KlarnaOnsiteService.push({ eventName: 'refresh-placements' });
  }
}

module.exports = {
  toggleKlarnaOrderSummary: toggleKlarnaOrderSummary,

  /**
   * change klarna payment checkout tab looks
   * @param show
   */
  toggleKlarnaCheckoutTab: show => {
    const $klarnaTabNav = $('.nav-item.klarna-payment-item');
    if ($klarnaTabNav.length) {
      $klarnaTabNav.show(); // always have klarna show if in isml
      $klarnaTabNav.find('label .no-tooltip').toggleClass('d-none', !show);
      $klarnaTabNav.find('label .custom-tooltip').toggleClass('d-none', show);
      $klarnaTabNav.find('input').prop('disabled', !show);
    }
  }
};
