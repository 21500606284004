'use strict';

var HBC_CARD_TYPE = [
  {
    name: 'TCC',
    label: 'Saks Temporary Credit Card',
    expiration: true,
    pattern: [/^600304\d*$/],
    length: [29],
    isCapOne: true
  },
  {
    name: 'TCC',
    label: 'Saks Temporary Mastercard',
    expiration: true,
    pattern: [539662, /^5(22944|39226|39662)\d*$/],
    length: [29],
    isCapOne: true
  },
  {
    name: 'HBC',
    label: 'HBC Credit Card',
    expiration: false,
    pattern: [
      600291,
      600292,
      600293,
      600295,
      600296,
      600297,
      600298,
      600299,
      600300,
      /(600291|600292|600293|600295|600296|600297|600298|600299|600300)[0-9]{10}/
    ],
    length: [16, 17, 18, 19]
  },
  {
    name: 'HBCMC',
    label: 'HBC Mastercard',
    expiration: true,
    pattern: [524336, 524337, /524337[0-9]{10}/],
    length: [16]
  },
  {
    name: 'SAKSMC',
    label: 'SAKS Mastercard',
    expiration: true,
    pattern: [539662, /^5(22944|39226|39662)\d*$/],
    length: [16],
    isCapOne: true
  },
  {
    name: 'SAKS',
    label: 'Saks Store Card',
    expiration: false,
    pattern: [73, 78, 10, 29, 39, 48, 49, 68, 75, 77, 80],
    length: [10]
  },
  {
    name: 'LAT',
    label: 'Lord & Taylor Rewards Card',
    expiration: false,
    pattern: [/^(6026(0[1-48]|[1-3]4|91))\d*$/],
    length: [16]
  },
  {
    name: 'MPA',
    label: 'SaksFirst Store Card',
    expiration: false,
    pattern: [
      8360,
      8361,
      8362,
      8363,
      8364,
      8365,
      8366,
      8367,
      8368,
      8369,
      /(83[6-8][0-9]{7}|839[0-8][0-9]{6}|8399[0-8][0-9]{5}|83999[0-8][0-9]{4}|839999[0-8][0-9]{3}|8399999[0-8][0-9]{2}|83999999[0-8][0-9]|839999999[0-9])/
    ],
    length: [10]
  },
  {
    name: 'SAKS',
    label: 'SaksFirst Store Card',
    expiration: true,
    pattern: [/^60030(4|5)\d*$/],
    length: [16],
    isCapOne: true
  },
  {
    name: 'SAKS',
    label: 'SaksFirst Store Card',
    expiration: true,
    pattern: [/^223569\d*$/],
    length: [16]
  },
  {
    name: 'SAKSMC',
    label: 'SAKS Mastercard',
    expiration: true,
    pattern: [/^543414\d*$/],
    length: [16]
  }
];

function saksCard(cardNumber) {
  if (cardNumber.length === 8) {
    return {
      name: 'SAKS',
      label: 'Saks Store Card',
      expiration: false,
      length: [8]
    };
  }
}

/**
 * Get the BIN Range
 * @param {Object} value - Card Number
 * @returns {Object} return the BIN Range
 */
function getBin(value) {
  return value.toString().replace(/\D/g, '').substr(0, 6);
}

/**
 * Check Pattern of the Card
 * @param {Object} bin - BIN range
 * @param {Object} pattern - Card Pattern
 * @returns {boolean} Return True/False if Pattern found
 */
function checkPattern(bin, pattern) {
  if (pattern instanceof RegExp) {
    return pattern.test(bin);
  }
  pattern = pattern.toString(); // eslint-disable-line
  return bin.substr(0, pattern.length) === pattern;
}

/**
 * Check the BIN range based on Card Type
 * @param {Object} bin - BIN Range
 * @param {Object[]} min [min, max] - MiX and Max of Range
 * @returns {boolean} Return True/False if Range found
 */
function checkRange(bin, [min, max]) {
  var length = min.toString().length;
  var value = parseInt(bin.substr(0, length)); // eslint-disable-line
  return value >= min && value <= max;
}

/**
 * Check the Card based on BIN range or Pattern.
 * @param {Object} bin - BIN range
 * @param {Object} pattern - Type Pattern
 * @returns {boolean} Return True/False Bif card is based or Pattern or range
 */
function checkCard(bin, pattern) {
  return Array.isArray(pattern) ? checkRange(bin, pattern) : checkPattern(bin, pattern);
}

/**
 * Check the Card number length to make sure it's not too long for the Card Type
 * @param {Object} card - Entered Card
 * @param {Object} pattern - Type Pattern
 * @returns {boolean} Return True/False Bif card is under max length for the Card Type
 */
function checkLength(card, lengthArray) {
  var cardLength = card.toString().length;
  return lengthArray.some(function (typeLength) {
    return cardLength <= typeLength;
  });
}

/**
 * Adds bread credit card types to the provided HBC_CARD_TYPE array.
 * 
 * @param {Array} HBC_CARD_TYPE - The array of card types to add the bread types to.
 */
function addBreadCardTypes(HBC_CARD_TYPE) {
  HBC_CARD_TYPE.push(
    {
      name: 'TCC',
      label: 'Saks Temporary Credit Card',
      expiration: true,
      pattern: [/^223569\d*$/],
      length: [29]
    },
    {
      name: 'SAKS',
      label: 'SaksFirst Store Card',
      expiration: true,
      pattern: [/^223569\d*$/],
      length: [16]
    },
    {
      name: 'TCC',
      label: 'Saks Temporary Mastercard',
      expiration: true,
      pattern: [/^543414\d*$/],
      length: [29]
    },
    {
      name: 'SAKSMC',
      label: 'SAKS Mastercard',
      expiration: true,
      pattern: [/^543414\d*$/],
      length: [16]
    });
  return HBC_CARD_TYPE;
}

/**
 * Check the Card Type of entered Card.
 * @param {Object} card - Entered Card
 * @returns {Object} Found Card
 */
function cardType(card, breadEnabled) {
  var bin = getBin(card);
  var detectedTypes = [];
  /**This code is also used in the backend (/scripts/checkout/TCCHelpers.js)
   * so it's not possible to use window.SitePreferences.breadEnabled to check if bread is enabled
   * So in this case we are receiving the flag from the source that is calling this method
   **/
  if (breadEnabled) {
    HBC_CARD_TYPE = addBreadCardTypes(HBC_CARD_TYPE);
  }
  HBC_CARD_TYPE.forEach(function (type) {
    type.pattern.forEach(function (pattern) {
      if (checkCard(bin, pattern) && checkLength(card, type.length)) {
        detectedTypes.unshift(type);
      }
    });
  });

  return detectedTypes[0] || {};
}

module.exports = {
  cardType: cardType,
  saksCard: saksCard
};
