'use strict';
var base = require('base/components/clientSideValidation');
var floatLabel = require('../floatLabel');
var creditCardValidator = require('../components/creditCardValidator');
var HBCCards = require('../checkout/HBCCards');

/**
 * function to validate form
 *
 * @param {event} event - trigger event
 * @returns {boolean} - returns validity of form
 */
function validateForm(event) {
  var valid = true;
  if (this.checkValidity && !this.checkValidity()) {
    // safari
    valid = false;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
    $(this)
      .find('input, select')
      .each(function () {
        if (!this.validity.valid) {
          $(this).trigger('invalid', this.validity);
        }
      });
  }
  return valid;
}
/**
 * Get All parent invalid fields
 * @returns
 */
function getAllinvalidFields(element) {
  // ATM for create account form or sign in
  if ($(element).closest('form.registration').length || $(element).closest('form.login').length) {
    if ($('label.is-invalid').length) {
      var formData = {};
      var errorData = [];
      $.each($('label.is-invalid'), function (index, item) {
        errorData.push($(item).text().trim());
      });
      formData.errorFields = errorData;
      formData.formName = $(element).closest('form.registration').length ? 'create account' : 'sign in';
      // $('body').trigger('adobeTagManager:formError', formData);
    }
  }
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element} form - Form to be cleared
 * @returns {void}
 */
function clearForm(form) {
  $(form).find('.form-control.is-invalid').not('.cardExpiration, #cardNumber, #gcCardNumber, #accessNumber, .saks-footer-email, #newPassword, #registration-form-password').removeClass('is-invalid');
  window.setTimeout(function () {
    getAllinvalidFields(form);
  }, 500);
}
/**
 * check CC
 *
 * @param {string} creditCard - credit card number
 * @returns {boolean} - return validtity check
 */
function luhnCheck(creditCard) {
  if (/[^0-9-\s]+/.test(creditCard)) return false;
  let nCheck = 0;
  let bEven = false;
  // eslint-disable-next-line no-param-reassign
  creditCard = creditCard.replace(/\D/g, '');
  for (var n = creditCard.length - 1; n >= 0; n--) {
    var cDigit = creditCard.charAt(n);
    var nDigit = parseInt(cDigit, 10);
    // eslint-disable-next-line no-cond-assign
    if (bEven && (nDigit *= 2) > 9) nDigit -= 9;
    nCheck += nDigit;
    bEven = !bEven;
  }
  return nCheck % 10 === 0;
}

/**
 * refreshing form before opening price override pop-up
 *
 */
$('#editCSCPrice').on('show.bs.modal', function () {
  clearForm($(this).find('.price-override-form'));
  $('.price-override-form .form-group').find('span').remove();
  $('#editCSCPrice #newPrice').parents('.form-group').find('.form-control-label').removeClass('is-invalid input-focus');
});

/**
 * validate form on blur
 *
 * @param {event} event - trigger event
 * @returns {boolean} - returns validity of form
 */
function validateFormonBlur(event) {
  var valid = true;
  var validationMessage;
  if (this.checkValidity && !this.checkValidity()) {
    // safari
    valid = false;
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }
    if (!this.validity.valid) {
      this.setCustomValidity('');
      if (!this.validity.valid) {
        validationMessage = $(this).data('default-error') || this.validationMessage;
        $(this).addClass('is-invalid');
        $(this).closest('.form-group').find('.form-control-label').addClass('is-invalid');
        if ($(this).next('span').length === 0) {
          $('<span></span>').insertAfter(this);
          $(this).next('span').addClass('invalid');
        }
        if ($(this).next('span').hasClass('valid')) {
          $(this).next('span').removeClass('valid').addClass('invalid');
        }
        if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
          validationMessage = $(this).data('pattern-mismatch');
        }
        if ((this.validity.rangeOverflow || this.validity.rangeUnderflow) && $(this).data('range-error')) {
          validationMessage = $(this).data('range-error');
        }
        if ((this.validity.tooLong || this.validity.tooShort) && $(this).data('range-error')) {
          validationMessage = $(this).data('range-error');
        }
        if (this.validity.valueMissing && $(this).data('missing-error')) {
          validationMessage = $(this).data('missing-error');
        }
        $(this).parents('.form-group').find('.invalid-feedback').text(validationMessage);
      }
      // ATM for create account form or sign in
      getAllinvalidFields(this);
    }
  } else {
    if ($(this).attr('id') === 'cardNumber') {
      var isValidCardLength = false;
      //Make sure "You've Been Issued a New Saks Card" message starts hidden during validation
      if (window.SitePreferences.breadEnabled && $('.card-number-wrapper').data('is-capital-one')) {
        $('.credit-card-form, .add-payment-form').find('.bread-card-wrapper.type-alert').addClass('d-none');
      }
      if (!$('.card-number-wrapper').attr('data-type')) {
        var sakscreditCard = $(this).val().replace(/\D/g, '');
        if (sakscreditCard.length === 8 && luhnCheck($(this).val())) {
          var cartType = HBCCards.saksCard(sakscreditCard);
          if (cartType && cartType.name) {
            $('.card-number-wrapper').attr('data-type', cartType.name);
            $('.card-number-wrapper').attr('data-plcccard', 'true');
            $('#cardType').val(cartType.name);
            isValidCardLength = true;
          }
        } else {
          isValidCardLength = true;
        }
        // If we have empty card number on page load, don't validate it
      } else if ($('.card-number-wrapper').attr('data-type') && $('.card-number-wrapper').attr('data-type') !== 'unknown') {
        isValidCardLength = creditCardValidator.validateCreditCardTypeLength($(this).val(), $('.card-number-wrapper').attr('data-type'));
      }
      var cardType = $('.card-number-wrapper').attr('data-type') && $('.card-number-wrapper').attr('data-type');
      if (cardType === 'HBC' || cardType === 'SAKS' || cardType === 'MPA') {
        // Skip LUHN Check for HBC Card.
        if (!isValidCardLength) {
          validationMessage = $(this).data('pattern-mismatch');
          $(this).prev('.form-control-label').addClass('is-invalid');
          $(this).parents('.form-group').find('.invalid-feedback').text(validationMessage);
          $(this).addClass('is-invalid');
          if ($(this).next('span').length === 0) {
            $('<span></span>').insertAfter(this);
            $(this).next('span').addClass('invalid');
          }
          if ($(this).next('span').hasClass('valid')) {
            $(this).next('span').removeClass('valid').addClass('invalid');
          }
          valid = false;
          var form = {};
          form.formName = 'payment';
          form.errorFields = ['cardNumber'];
          // $('body').trigger('adobeTagManager:formError', form);
          return valid;
        }
      } else if (!luhnCheck($(this).val()) || !isValidCardLength) {
        validationMessage = $(this).data('pattern-mismatch');
        $(this).closest('.form-group').find('.form-control-label').addClass('is-invalid');
        $(this).parents('.form-group').find('.invalid-feedback').text(validationMessage);
        $(this).addClass('is-invalid');
        if ($(this).next('span').length === 0) {
          $('<span></span>').insertAfter(this);
          $(this).next('span').addClass('invalid');
        }
        if ($(this).next('span').hasClass('valid')) {
          $(this).next('span').removeClass('valid').addClass('invalid');
        }
        valid = false;
        var form = {};
        form.formName = 'payment';
        form.errorFields = ['cardNumber'];

        //Display "You've Been Issued a New Saks Card" message for customer trying to add a new capital one card
        if (window.SitePreferences.breadEnabled && $('.card-number-wrapper').data('is-capital-one')) {
          var breadCardWrapper = $('.credit-card-form, .add-payment-form').find('.bread-card-wrapper.new-card-issued');
          //Check if the wrapper has any contents inside before removing the class in case the content slot is disabled, otherwise it will show an empty yellow block on the page
          if (breadCardWrapper && breadCardWrapper.length && breadCardWrapper.children().length) {
            breadCardWrapper.removeClass('d-none');
          }
        }

        // $('body').trigger('adobeTagManager:formError', form);
        return valid;
      }
    }

    if ($(this).attr('id') === 'tccCardNumber') {
      var isValidCardLength = false;
      isValidCardLength = creditCardValidator.validateCreditCardTypeLength($(this).val(), $('.tcc-card-number-wrapper').attr('data-type'));
      var cardType = $('.tcc-card-number-wrapper').attr('data-type');
      if (!isValidCardLength) {
        validationMessage = $(this).data('pattern-mismatch');
        $(this).prev('.form-control-label').addClass('is-invalid');
        $(this).parents('.form-group').find('.invalid-feedback').text(validationMessage);
        $(this).addClass('is-invalid');
        if ($(this).next('span').length === 0) {
          $('<span></span>').insertAfter(this);
          $(this).next('span').addClass('invalid');
        }
        if ($(this).next('span').hasClass('valid')) {
          $(this).next('span').removeClass('valid').addClass('invalid');
        }
        valid = false;
        var form = {};
        form.formName = 'payment';
        form.errorFields = ['tccCardNumber'];
        // $('body').trigger('adobeTagManager:formError', form);
        return valid;
      } else {
        if ($('#tccCardNumber').val() !== '') {
          // IF Card length is valid 29 digit, check if card itself is valid or not.
          var tccCardNumber = $('#tccCardNumber').data('cleave').getRawValue();
          var tccVerifyURL = $('.tcc-card-number').data('tccverify');
          var validateDate = $('.tcc-card-number').data('validateDate');
          var fullTCCUrl = tccVerifyURL + '?tcc=' + tccCardNumber + '&verifydate=' + validateDate;
          var cacheTCCfield = $(this);
          var isValidTCC = true;
          $('body').spinner().start();
          $.ajax({
            url: fullTCCUrl,
            type: 'get',
            dataType: 'json',
            async: false,
            success: function (data) {
              if (!data.validTCC) {
                validationMessage = $(cacheTCCfield).data('pattern-mismatch');
                if (data.validationMessage) {
                  validationMessage = data.validationMessage;
                }
                $(cacheTCCfield).prev('.form-control-label').addClass('is-invalid');
                $(cacheTCCfield).parents('.form-group').find('.invalid-feedback').text(validationMessage);
                $(cacheTCCfield).addClass('is-invalid');
                if ($(cacheTCCfield).next('span').length === 0) {
                  $('<span></span>').insertAfter(cacheTCCfield);
                  $(cacheTCCfield).next('span').addClass('invalid');
                }
                if ($(cacheTCCfield).next('span').hasClass('valid')) {
                  $(cacheTCCfield).next('span').removeClass('valid').addClass('invalid');
                }
                isValidTCC = false;
                var form = {};
                form.formName = 'payment';
                form.errorFields = ['tccCardNumber'];
              }
              $('body').spinner().stop();
            },
            error: function (data) {
              validationMessage = $(cacheTCCfield).data('pattern-mismatch');
              $(cacheTCCfield).prev('.form-control-label').addClass('is-invalid');
              $(cacheTCCfield).parents('.form-group').find('.invalid-feedback').text(validationMessage);
              $(cacheTCCfield).addClass('is-invalid');
              if ($(cacheTCCfield).next('span').length === 0) {
                $('<span></span>').insertAfter(this);
                $(cacheTCCfield).next('span').addClass('invalid');
              }
              if ($(cacheTCCfield).next('span').hasClass('valid')) {
                $(cacheTCCfield).next('span').removeClass('valid').addClass('invalid');
              }
              isValidTCC = false;
              var form = {};
              form.formName = 'payment';
              form.errorFields = ['tccCardNumber'];
              $('body').spinner().stop();
            }
          });
          if (!isValidTCC) {
            return false;
          }
        }
      }
    }

    if ((($(this).prop('required') && $(this).val() !== '') || $(this).attr('data-validation'))
    && (this.id !== 'newPassword' && this.id !== 'registration-form-password')) {
      $(this).removeClass('is-invalid');
      $(this).closest('.form-group').find('.form-control-label').removeClass('is-invalid');
      $(this).closest('div').find('.invalid-feedback').text('');
      if ($(this).next('span').length === 0) {
        $('<span></span>').insertAfter(this);
        $(this).next('span').addClass('valid');
      }
      if ($(this).next('span').length !== 0 && $(this).next('span').hasClass('invalid')) {
        $(this).next('span').removeClass('invalid').addClass('valid');
      }
    }
  }
  if (!valid) {
    if ($(this).closest('.shipping-form').length) {
      let formShipping = {};
      formShipping.name = 'shipping address';
      let errorFields = [];
      $.each($('.shipping-form input[type="text"]'), function (index, item) {
        if (!item.validity.valid) {
          errorFields.push($(item).attr('name'));
        }
      });
      formShipping.errorFields = errorFields.join(',');
      // $('body').trigger('adobeTagManager:formError', formShipping);
    } else if ($(this).closest('.billing-form').length) {
      let errorFields = [];
      $.each($('.billing-form input[type="text"]'), function (index, item) {
        if (!item.validity.valid) {
          errorFields.push($(item).attr('name'));
        }
      });
      if (errorFields.length) {
        let formBilling = {};
        formBilling.name = 'payment';
        formBilling.errorFields = errorFields.join(',');
        // $('body').trigger('adobeTagManager:formError', formBilling);
      }
    }
  }
  return valid;
}
/**
 * check form validity of checkout
 *
 * @returns {boolean} - validity check return
 */
function checkFormvalidCheckout() {
  var valid = true;
  if ($(this).closest('.checkout-primary-section').length !== 0) {
    // eslint-disable-next-line consistent-return
    $(this)
      .closest('form:visible')
      .find('input:visible, select:visible')
      .each(function () {
        if ($(this).prop('required') && $(this).parents('.gift-card-form-group').length === 0 && !$(this).prop('disabled')) {
          if ($(this).next('span').length !== 0 && $(this).next('span').hasClass('valid')) {
            valid = true;
          } else if ($(this).attr('type') === 'radio' && $(this).closest('.radio-holder').find('input[type="radio"]:checked').length === 1) {
            valid = true;
          } else if ($(this).attr('type') === 'checkbox') {
            valid = true;
          } else {
            valid = false;
            return false;
          }
        }
      });
  }
  return valid;
}
base.invalid = function () {
  $('form input, form select').on('invalid', function (e) {
    e.preventDefault();
    this.setCustomValidity('');
    if (!this.validity.valid) {
      var validationMessage = $(this).data('default-error') || this.validationMessage;
      $(this).closest('.gift-card-form-group').find('.generic-error').empty();
      $(this).addClass('is-invalid');
      $(this).closest('.form-group').find('.form-control-label').addClass('is-invalid');
      if ($(this).parents('.price-override-form')) {
        if ($(this).next('span').length === 0) {
          $('<span></span>').insertAfter(this);
        }
        $(this).next('span').addClass('invalid');

        if ($(this).next('span').hasClass('valid')) {
          $(this).next('span').removeClass('valid').addClass('invalid');
        }
      }
      if (this.id !== 'newPassword' && this.id !== 'registration-form-password') {
        if (this.validity.patternMismatch && $(this).data('pattern-mismatch')) {
          validationMessage = $(this).data('pattern-mismatch');
        }
        if ((this.validity.rangeOverflow || this.validity.rangeUnderflow) && $(this).data('range-error')) {
          validationMessage = $(this).data('range-error');
        }
        if ((this.validity.tooLong || this.validity.tooShort) && $(this).data('range-error')) {
          validationMessage = $(this).data('range-error');
        }
        if (this.validity.valueMissing && $(this).data('missing-error')) {
          validationMessage = $(this).data('missing-error');
        }
        $(this).parents('.form-group').find('.invalid-feedback').text(validationMessage);
      } else if (!$(this).val().trim()){
        //Trigger blur to fully validate password fields (/password/passwordHelpers.js > validatePassword)
        $(this).trigger('blur');
      }
    } else {
      $(this).parents('.form-group').find('label').removeClass('is-invalid');
    }
  });
};
base.submit = function () {
  $('form').on('submit', function (e) {
    return validateForm.call(this, e);
  });
};
base.buttonClick = function () {
  $('form button[type="submit"], form input[type="submit"]').on('click', function () {
    // clear all errors when trying to submit the form
    clearForm($(this).parents('form'));
  });
};
module.exports = {
  invalid: base.invalid,
  clearForm: base.clearForm,
  submit: base.submit,
  buttonClick: base.buttonClick,
  validateFormonBlur: validateFormonBlur,
  checkFormvalidCheckout: checkFormvalidCheckout,
  onblurValidation: function () {
    $('body').on('blur', 'form input, form select', function (e) {
      if ($(this).prop('required') || $(this).attr('data-validation')) {
        if ($(this).hasClass('CA-po-Validate') && !$.trim(this.value) == '' && $(this).closest('form').find('select[name$="country"]').val() == 'CA') {
          var zipVal = $(this).val().trim().replace(/\s/g, '').toUpperCase();
          var formatedVal = zipVal.substr(0, 3) + ' ' + zipVal.substr(3, zipVal.length);
          $(this).val(formatedVal);
        } else if ($(this).hasClass('cartPOTaxCAonly')) {
          var zipVal = $(this).val().trim().replace(/\s/g, '').toUpperCase();
          var formatedVal = zipVal.substr(0, 3) + ' ' + zipVal.substr(3, zipVal.length);
          $(this).val(formatedVal);
        }
        if($(this).val() && $(this).val().trim().length == 0) {
          $(this).val('');
        }
        validateFormonBlur.call(this, e);
      }
      var result = checkFormvalidCheckout.call(this);
      /* if (!($(this).parents('.gift-card-form-group').length > 0 || $(this).parents('.promo-form-group').length > 0)) {
                if (result && $(this).closest('.checkout-primary-section').length !== 0) {
                    $('.next-step-button:visible').find('button[type="submit"]').removeAttr('disabled');
                } else {
                    $('.next-step-button:visible').find('button[type="submit"]').attr('disabled', 'disabled');
                }
            }*/
      // show error message on restricted state code address entry on shipping form
      if ($(e.currentTarget.closest('.shipping-form')).length > 0) {
        var restrictedStates = $('.shipping-address-block').length ? $('.shipping-address-block').data('restricted-sates') : null;
        if (
          restrictedStates &&
          restrictedStates !== 'null' &&
          restrictedStates.length > 0 &&
          $('select[name$=_shippingAddress_addressFields_states_stateCode]', '.shipping-form').val().length > 0 &&
          $('.restricted-state-message').length
        ) {
          var data = $('select[name$=_shippingAddress_addressFields_states_stateCode]', '.shipping-form').val();
          // eslint-disable-next-line no-mixed-operators
          if ((data && restrictedStates.indexOf(data.toLowerCase()) >= 0) || restrictedStates.indexOf(data.toUpperCase()) >= 0) {
            $('.restricted-state-message').removeClass('d-none');
            // $('.next-step-button:visible').find('button[type="submit"]').attr('disabled', 'disabled');
          } else {
            $('.restricted-state-message').addClass('d-none');
          }
        }
        // show shipping methods only on the complete shipping address entry
        if (result && $(this).closest('.checkout-primary-section').length !== 0) {
          $('.shipping-method-heading.saks-only').removeClass('d-none');
          $('.shipping-method-list').removeClass('d-none');
          $('.shipping-method-list').removeClass('no-address');
          $('.hbc-shipping-method-description').removeClass('d-none');
          if ($('.no-shipping-method-msg').attr('data-has-methods') != undefined && $('.no-shipping-method-msg').attr('data-has-methods') === 'true') {
            $('.no-shipping-method-msg').addClass('d-none');
            $('.next-step-button:visible').find('button[type="submit"]').removeAttr('disabled');
          } else if ($('.no-shipping-method-msg').attr('data-has-methods') != undefined && $('.no-shipping-method-msg').attr('data-has-methods') === 'false') {
            $('.no-shipping-method-msg').removeClass('d-none');
            $('.next-step-button-disable:visible').find('button[type="submit"]').attr('disabled', 'disabled');
          }
          $('.dr-msg').removeClass('d-none');
        } else if (!$('.gift-message.gift-content-section').is(':visible')) {
          $('.shipping-method-heading.saks-only').addClass('d-none');
          $('.shipping-method-list').addClass('d-none');
          $('.hbc-shipping-method-description').addClass('d-none');
          $('.no-shipping-method-msg').addClass('d-none');
          $('.next-step-button:visible').find('button[type="submit"]').removeAttr('disabled');
          $('.dr-msg').addClass('d-none');
        }
      }
    });
  },
  checkValidationOnAjax: function ($form, noValidate, noValidateShippingMethods, removeValidMark, hasUSPSRestricted) {
    if ($form) {
      $form.find('input:visible, select:visible').each(function () {
        validateFormonBlur.call(this);
        if (noValidate) {
          $(this).removeClass('is-invalid');
          $(this).closest('.form-group').find('.form-control-label').removeClass('is-invalid');
          $(this).next('.invalid').remove();
          $(this).parent().find('.invalid-feedback').empty();
        }
        if (removeValidMark) {
          if ($(this).next('span').hasClass('valid')) {
            $(this).next('span').removeClass('valid');
          }
        }
        var result = checkFormvalidCheckout.call(this);
        /* if ($(this).parents('.gift-card-form-group').length === 0) {
                    if (result && $(this).closest('.checkout-primary-section').length !== 0) {
                        $('.next-step-button:visible').find('button[type="submit"]').removeAttr('disabled');
                    } else {
                        $('.next-step-button:visible').find('button[type="submit"]').attr('disabled', 'disabled');
                    }
                }*/
        if ($('.shipping-method-list').length > 0 && (!noValidateShippingMethods || noValidateShippingMethods === undefined)) {
          // show shipping methods only on the complete shipping address entry
          if (result && $(this).closest('.checkout-primary-section').length !== 0) {
            $('.shipping-method-heading.saks-only').removeClass('d-none');
            $('.shipping-method-list').removeClass('d-none');
            $('.shipping-method-list').removeClass('no-address');
            $('.hbc-shipping-method-description').removeClass('d-none');
            if ($('.no-shipping-method-msg').attr('data-has-methods') != undefined && $('.no-shipping-method-msg').attr('data-has-methods') === 'true') {
              $('.no-shipping-method-msg').addClass('d-none');
              $('.next-step-button:visible').find('button[type="submit"]').removeAttr('disabled');
            } else if (
              $('.no-shipping-method-msg').attr('data-has-methods') != undefined &&
              $('.no-shipping-method-msg').attr('data-has-methods') === 'false'
            ) {
              $('.no-shipping-method-msg').removeClass('d-none');
              $('.next-step-button-disable:visible').find('button[type="submit"]').attr('disabled', 'disabled');
            }
            $('.dr-msg').removeClass('d-none');
          } else {
            $('.shipping-method-heading.saks-only').addClass('d-none');
            $('.shipping-method-list').addClass('d-none');
            $('.hbc-shipping-method-description').addClass('d-none');
            $('.no-shipping-method-msg').addClass('d-none');
            $('.next-step-button:visible').find('button[type="submit"]').removeAttr('disabled');
            $('.dr-msg').addClass('d-none');
          }
        }
      });
      if (noValidate && !hasUSPSRestricted && !$('.data-checkout-stage').attr('data-checkout-stage') === 'shipping') {
        if ($('.po-check-message').is(':visible')) {
          $('.po-check-message').addClass('d-none');
        }

        if ($('.restricted-state-message').is(':visible')) {
          $('.restricted-state-message').addClass('d-none');
        }
      }
    }
  },
  updatePoPatterWithCountry: function ($form) {
    if ($form !== undefined && $form.find('.validateZipCode').length > 0) {
      var country_id = $form.find('select[name$="country"] option:selected').attr('id');
      var zipfield = $form.find('.validateZipCode');
      if ($form.find('.billing-addr-form').length != 0) {
        country_id = $form.find('.billing-addr-form').find('#billingCountry option:selected').attr('id');
        zipfield = $form.find('.billing-addr-form').find('.billingZipCode');
      }
      var pattern_ca = zipfield.attr('data-pattern-ca').toString();
      var pattern_us = zipfield.attr('data-pattern-us').toString();
      var pattern_uk = zipfield.attr('data-pattern-uk').toString();
      if (country_id == 'CA') {
        zipfield.attr('pattern', pattern_ca);
        zipfield.attr('minlength', '');
        zipfield.attr('maxlength', '7');
      } else if (country_id == 'US') {
        zipfield.attr('pattern', pattern_us);
        zipfield.attr('minlength', '5');
        zipfield.attr('maxlength', '12');
      } else if (country_id == 'UK') {
        zipfield.attr('pattern', pattern_uk);
        zipfield.attr('minlength', '6');
        zipfield.attr('maxlength', '8');
        $('.state-drop-down').addClass('d-none');
        $('.state-drop-down').find('.form-group').removeClass('required');
        $('.state-drop-down').find('.remove-required-uk-address').prop('required', false);
        $('.state-drop-down').find('.remove-required-uk-address').removeClass('is-invalid');
        $('.state-input').removeClass('d-none');
      }
    }
  },
  checkIfFormElementFocused: function () {
    $(document).ready(function () {
      $('body')
        .find('form:visible')
        .each(function () {
          // eslint-disable-next-line consistent-return
          $(this)
            .find('input:visible')
            .each(function () {
              if ($(this).attr('autofocus') === 'autofocus') {
                $(this).closest('.form-group').find('.form-control-label').addClass('input-focus');
                return false;
              }
            });
        });
    });
  },
  functions: {
    validateForm: function (form, event) {
      validateForm.call($(form), event || null);
    },
    clearForm: clearForm
  }
};
