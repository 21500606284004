'use strict';
var formValidation = require('core/components/formValidation');
var clientSideValidation = require('core/components/clientSideValidation');
let setTimeOut; // required to clear the set timeout if the user click sign up again and again.

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function customCreateErrorNotification(element) {
  if ($(element).find('.alert-dismissible').length > 0) {
    $('.error-messaging.create-accot-page-error').removeClass('show');
  }
  let errorHtml =
    '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
    'fade show" role="alert">' +
    '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
    '</button>' +
    '<span class="error-content"></span>'+
    '</div>';
  $(element).html(errorHtml);
  $('.error-messaging.create-accot-page-error').addClass('show');
  clearTimeout(setTimeOut);
  setTimeOut = setTimeout(() => {
    $(element).removeClass('show');
  }, 6000);
  $(element).find('button.close').on('click', function () {
    $('.error-messaging.create-accot-page-error').removeClass('show');
  });
}

/**
 * Handles login form submission. Validates input, calls recaptcha, 
 * submits login request, and handles response.
 */
function login() {
  $('body').on('submit', 'form.login', function (e) {
    e.preventDefault();
    if(window.mobileApp) {
      if(!($('.login #login-form-email').val() === '') && !($('.login #login-form-password').val() === '')) {
        $('.login label.form-control-label').addClass('input-focus');
      }
    }
    var $form = $(this);
    $('.error-messaging').addClass('create-accot-page-error');

    if (typeof grecaptcha !== 'undefined' && grecaptcha !== null) {
      grecaptcha.ready(function () {
        grecaptcha.execute($('.google-recaptcha-key').html(), { action: 'login' }).then(function (token) {
          $('.g-recaptcha-token').val(token);

          var url = $form.attr('action');
          $form.spinner().start();

          $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
              $form.spinner().stop();
              if (!data.success) {
                formValidation($form, data);
              } else {
                location.href = data.redirectUrl;
              }
              if (data.botError) {
                $('form.login button.account-btn').attr('disabled', 'disabled');
                $('form.login button.sign-up-btn').attr('disabled', 'disabled');
              }
            },
            error: function (data) {
              if (data.responseJSON && data.responseJSON.action == 'CSRF-AjaxFail') {
                window.location.href = data.responseJSON.failLoginRedirectURL;
              } else if (data.responseJSON && data.responseJSON.redirectUrl) {
                window.location.href = data.responseJSON.redirectUrl;
              } else if (data.responseJSON && data.responseJSON.error && data.responseJSON.error.length > 0 && data.responseJSON.slasError) {
                customCreateErrorNotification($('.error-messaging'));
                $('.error-messaging.create-accot-page-error .error-content').html(data.responseJSON.error[0]);
                $form.spinner().stop();
              } else {
                formValidation($form, data.responseJSON);
                $form.spinner().stop();
              }
              if (data.botError) {
                $('form.login button.account-btn').attr('disabled', 'disabled');
                $('form.login button.sign-up-btn').attr('disabled', 'disabled');
              }
            }
          });
          return false;
        });
      });
    }
  });
}

/**
 * Adds click handler to back to sign in link to open login popup.
 */
function backToSignInForm() {
  $('.back-to-sign').on('click', function (e) {
    e.preventDefault();
    openLoginPopup($(this).data('sign-in-form-url'));
  });
}

/**
 * Adds click handler to checkout create account form button to open 
 * create account form in drawer.
 */
function checkoutCreateAccountFormBtnListener() {
  $('#checkout-sign-up-btn').on('click', function (e) {
    e.preventDefault();
    var $checkoutSignInSignUpDrawer = $('.sign-up-flyout');
    $checkoutSignInSignUpDrawer.spinner().start();
    $.ajax({
      url: $(this).data('checkout-create-account-form'),
      type: 'GET',
      success: function (data) {
        $('.sign-up-flyout').html(data);
        clientSideValidation.submit();
        clientSideValidation.invalid();
        clientSideValidation.buttonClick();
        backToSignInForm();
        //Fix issue preventing customer to click on the X button to close drawer
        let $modalColumn = $('.modal-column');
        if ($modalColumn.hasClass('veiled')) {
          $modalColumn.removeClass('veiled');
          $modalColumn.removeAttr('style');
        }
        $checkoutSignInSignUpDrawer.spinner().stop();
      },
      error: function () {
        $checkoutSignInSignUpDrawer.spinner().stop();
      }
    });
  });
}

/**
 * Generates the modal window on the first call.
 *
 * @param {Object} data - render data
 */
function appendModalHtmlElement(data) {
  let $signInModal = $('#signInModal');

  if ($signInModal.length !== 0) {
    $signInModal.empty();
  }
  let htmlString =
    '<!-- Modal -->' +
    '<div class="modal-dialog">' +
    '<div class="modal-content">' +
    '<div class="modal-header-cart">' +
    '<div class="cancel-icon">' +
    '<button type="button" class="close svg-svg-22-cross svg-svg-22-cross-dims" data-dismiss="modal" aria-label="Close Modal Box"></button>' +
    '</div>' +
    '</div>' +
    '<div class="modal-body modal-body-checkout">' +
    '<div class="no-gutters modal-row align-items-start modal-contents">' +
    '<div class="modal-column">' +
    data.template +
    '</div>' +
    '</div>' +
    '</div>' +
    '</div>' +
    '</div>';
  if ($signInModal.length) {
    $signInModal.append(htmlString);
  }
}

/**
 * open login model
 * @param {string} url url to be open with
 */
function openLoginPopup(url) {
  var $checkoutSignInSignUpDrawer = $('.sign-up-flyout');
  $checkoutSignInSignUpDrawer.spinner().start();
  $.ajax({
    url: url,
    dataType: 'json',
    success: function (data) {
      $checkoutSignInSignUpDrawer.spinner().stop();
      if (data.redirect) {
        window.location.href = data.redirect;
      } else {
        appendModalHtmlElement(data);
        login();
        checkoutCreateAccountFormBtnListener();
        $('#signInModal').modal();
        let elementToCopy = $('.checkout-benefits-container').find('.benifits-section.benefits-main-div');
        let clonedElement = elementToCopy.clone();
        clonedElement.insertAfter($('#signInModal .card-header'));
        setTimeout(function () {
          window.formFields.adjustForAutofill();
        }, 300);
      }
    },
    error: function () {
      $checkoutSignInSignUpDrawer.spinner().stop();
    }
  });
}

module.exports = {
  login: login,
  openLoginPopup: openLoginPopup
}
