'use strict';

var pdpInstoreInventory = require('../product/pdpInstoreInventory');

/** constants */
const $body = $('body');

/**
 * Update tax totals
 *
 * @param {Object} data - Tax totals data
 */
function updateTaxTotal(data) {
  const $taxPst = $('.tax-pst');
  const $taxGst = $('.tax-gst');
  const $taxQst = $('.tax-qst');
  const $taxRst = $('.tax-rst');
  const $taxEco = $('.tax-eco');
  const $taxNormal = $('.tax-normal');

  if (data.totals.canadaTaxation && data.totals.canadaTaxation.PST) {
    $('.tax-PST-total').html(data.totals.canadaTaxation.PST);
    $taxPst.removeClass('d-none');
  } else if (!$taxPst.hasClass('d-none')) {
    $taxPst.addClass('d-none');
  }

  if (data.totals.canadaTaxation && data.totals.canadaTaxation['GST/HST']) {
    $('.tax-GST-total').html(data.totals.canadaTaxation['GST/HST']);
    $taxGst.removeClass('d-none');
  } else if (!$taxGst.hasClass('d-none')) {
    $taxGst.addClass('d-none');
  }

  if (data.totals.canadaTaxation && data.totals.canadaTaxation.QST) {
    $('.tax-QST-total').html(data.totals.canadaTaxation.QST);
    $taxQst.removeClass('d-none');
  } else if (!$taxQst.hasClass('d-none')) {
    $taxQst.addClass('d-none');
  }

  if (data.totals.canadaTaxation && data.totals.canadaTaxation.RST) {
    $('.tax-RST-total').html(data.totals.canadaTaxation.RST);
    $taxRst.removeClass('d-none');
  } else if (!$taxRst.hasClass('d-none')) {
    $taxRst.addClass('d-none');
  }

  if (data.totals.canadaTaxation && data.totals.canadaTaxation.ECO) {
    $('.tax-ECO-total').html(data.totals.canadaTaxation.ECO);
    $taxEco.removeClass('d-none');
  } else if (!$taxEco.hasClass('d-none')) {
    $taxEco.addClass('d-none');
  }

  if (!data.totals.canadaTaxation) {
    $('.tax-total').html(data.totals.totalTax);
    $taxNormal.removeClass('d-none');
  } else if (!$taxNormal.hasClass('d-none')) {
    $taxNormal.addClass('d-none');
  }
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
  const $shippingMethodPrice = $('.shipping-method-price');

  $shippingMethodPrice.html('- ' + data.totals.totalShippingCost);

  updateTaxTotal(data);

  $('.grand-total-sum').html(data.totals.grandTotal);
  $('.grand-total-value').html(data.totals.grandTotalValue);
  $('.sub-total').html(data.totals.subTotal);
  if (data.totals.orderLevelDiscountTotal.value > 0) {
    $('.order-discount').removeClass('hide-order-discount');
    $('.order-discount-total').html('- ' + data.totals.orderLevelDiscountTotal.formatted);
  } else {
    $('.order-discount').addClass('hide-order-discount');
    $('.order-discount-total').html('- ' + data.totals.orderLevelDiscountTotal.formatted);
  }
  if (data.totals.totalShippingCostUnformatted == 0 || data.totals.totalShippingCostUnformatted == data.totals.shippingLevelDiscountTotal.value) {
    $('.shipping-total-cost').html(data.totals.freeShippingText);
    $shippingMethodPrice.html('- ' + data.totals.freeShippingText);
  } else {
    $('.shipping-total-cost').html(data.totals.totalShippingCost);
  }
  if (data.totals.shippingLevelDiscountTotal.value > 0 && data.totals.totalShippingCostUnformatted != data.totals.shippingLevelDiscountTotal.value) {
    $('.shipping-discount').removeClass('hide-shipping-discount');
    $('.shipping-discount-total').html('- ' + data.totals.shippingLevelDiscountTotal.formatted);
  } else {
    $('.shipping-discount').addClass('hide-shipping-discount');
    $('.shipping-discount-total').html('- ' + data.totals.shippingLevelDiscountTotal.formatted);
  }
  // append hidden values for bfx-order discount. This tag excludes the product promo price unlike the above orderLevetotalDiscount
  if (data.totals.orderLevelDiscTotalExc.value > 0) {
    $('.order-discount-exlc-total').html('- ' + data.totals.orderLevelDiscTotalExc.formatted);
  }

  // Update Retail Delivery Fee
  if (data.retailDeliveryFee && data.retailDeliveryFee.applied || data.retailDeliveryFee.newTotalTax) {
    $('.retail-delivery-fee-item').removeClass('d-none');
    $('.retail-delivery-fee-cost').text(data.retailDeliveryFee.charge);
    if (data.retailDeliveryFee.newTotalTax) {
      $('.tax-total').empty().append(data.retailDeliveryFee.newTotalTax);
    }
  } else {
    $('.retail-delivery-fee-item').addClass('d-none');
  }

  if (data.totals.totalSavings.value > 0) {
    $('.grand-total-saving-container').removeClass('d-none');
    $('.checkout-total-savings').html(data.totals.totalSavings.formatted);
  } else {
    $('.grand-total-saving-container').addClass('d-none');
  }
}

/**
 * triggers change store option for a product matching the UUID parameter
 * @param {sting} pliUUID - item uuid to fetch the precise container
 */
function triggerChangeStore(pliUUID, noInventory) {
  var $container = $('.cart-options[data-product-uuid="' + pliUUID + '"]');
  var shipToContainer = $container
    .find('input[value=instore]')
    .siblings('label[for=instore_' + pliUUID + ']')
    .find('a');
  if (noInventory) {
    shipToContainer.addClass('no-inventory');
  } else {
    shipToContainer.removeClass('no-inventory');
  }
  if (shipToContainer.length) {
    shipToContainer.trigger('click');
  }
}

/**
 * remove the store selector modal when a store is selected out of results
 */
function removeSelectStoreModal() {
  const $inStoreInventoryModal = $('#inStoreInventoryModal');
  if ($inStoreInventoryModal.length > 0) {
    $inStoreInventoryModal.modal('hide');
    $inStoreInventoryModal.attr('aria-modal', 'false');
    $inStoreInventoryModal.remove();
    $('.modal-backdrop').remove();
  }
}

/**
 * Updates the store name in the radio label with the new store selected from modal.
 * @param {Object} data - Contains the store info
 * @param {sting} pliUUID - item uuid to fetch the precise container
 */
function updateStoreContent(data, pliUUID) {
  var $container = $('.cart-options[data-product-uuid="' + pliUUID + '"]');
  var shipToContainer = $container
    .find('input[value=instore]')
    .siblings('label[for=instore_' + pliUUID + ']')
    .find('a');
  shipToContainer.removeClass('no-inventory');
  // content update if store is found
  if (data.storeName && data.storeName !== undefined && data.storeId && data.storeId !== undefined && shipToContainer.length > 0) {
    $container.find('input[value=instore]').attr('data-store-id', data.storeId).removeClass('change-store');
    shipToContainer.html(data.storeName.toLowerCase());
  } else if (data.changeStoreHtml) {
    $container
      .find('input[value=instore]')
      .siblings('label[for=instore_' + pliUUID + ']')
      .html(data.changeStoreHtml);
  }
  if (data.storeName && data.storeName !== undefined && data.storeId && data.storeId !== undefined) {
    $container.find('input[value=instore]').removeClass('change-store');
  }
}

base.updateStoreCart = function () {
  $('body').on('store:changeStore', function (e, data) {
    var pliUUID = data.pliUUID;
    $.spinner().start();
    updateStoreContent(data, pliUUID); // if event invoke is from modal, updates store html with radio
    removeSelectStoreModal(); // close modal on successful update
    $.spinner().stop();
  });
};

module.exports = {
  /** *initiate change store event***/
  changeStore: pdpInstoreInventory.changeStore,
  /** *initiate select store from modal event***/
  selectStoreWithInventory: pdpInstoreInventory.selectStoreWithInventory,
  /** *trigger click with enter on store search***/
  triggerClickOnEnter: pdpInstoreInventory.triggerClickOnEnter,
  /** *********CUSTOM EVENT**************
   * updates ship to home and ship to store option on toggle of radio
   * updates item with the store info
   * */
  toggleShippingOption: function () {
    $('body').on('store:cart', function (e, reqdata) {
      var pliUUID = reqdata.pliUUID;
      var storeId = reqdata.storeId;
      var prodid = reqdata.prodid;
      $.spinner().start();
      var changeShiptoUrl = reqdata.url;
      // form data
      var form = {
        storeId: storeId,
        uuid: pliUUID,
        prodid: prodid
      };
      if (changeShiptoUrl) {
        $.ajax({
          url: changeShiptoUrl,
          data: form,
          success: function (data) {
            //Hide apple pay for mixed cart scenarios and show for non mixed cart scenarios
            if (window.dw &&
              window.dw.applepay &&
              window.ApplePaySession &&
              window.ApplePaySession.canMakePayments()) {
              var $applePayCartButton = $('#apple-pay-cart-button');
              if (!data.isApplePayEnabled) {
                if ($applePayCartButton.length) {
                  $applePayCartButton.addClass('d-none')
                }
                var checkoutOptions = $('.checkout-options');
                //Hide "Or Checkout With" if inside the div has only the div with the text
                if (checkoutOptions.length && checkoutOptions.children().length === 1 && checkoutOptions.children()[0].className.indexOf('chkout-optn-text') > -1){
                    checkoutOptions.addClass('d-none');
                //Hide "Or Checkout With" if inside the div has only the empty apple pay div and no other payment method like paypal, masterpass...
                } else if (checkoutOptions.length && checkoutOptions.children().length <= 2) {
                    for (var i = 0; i < checkoutOptions.children().length; i++) {
                        var child = checkoutOptions.children()[i];
                        if (child.id === 'apple-pay-cart-button') {
                            checkoutOptions.addClass('d-none');
                        }
                    }
                }
              } else {
                //getRequest call needed to prepare the payment sheet request body after changing shipping modes (ship to/pickup)
                $.ajax({url: window.dw.applepay.action.getRequest});
                if ($applePayCartButton.length) {
                  $applePayCartButton.removeClass('d-none')
                }
                var checkoutOptions = $('.checkout-options');
                checkoutOptions.removeClass('d-none');
                var checkoutOptions = $('.checkout-options');
                if (checkoutOptions.length) {
                    checkoutOptions.addClass('applepay-active');
                    checkoutOptions.addClass('float-left');
                }
                var masterPassContainer = $('.masterpass-cart-buttons-container');
                if (masterPassContainer.length) {
                    masterPassContainer.removeClass('chkout-btn-img');
                    masterPassContainer.removeClass('float-right');
                    var masterPassParent = masterPassContainer.parent();
                    if (masterPassParent.length && masterPassParent.hasClass('MP-wrap')) {
                        masterPassParent.removeClass('MP-wrap');
                        masterPassParent.addClass('masterpass-payment');
                        masterPassParent.addClass('chkout-btn-img-iOS');
                    }
                }
                var paymentButtons = $('.chkout-btn-img');
                if (paymentButtons.length) {
                    paymentButtons.addClass('chkout-btn-img-iOS');
                    paymentButtons = $('.chkout-btn-img-iOS')
                    if (paymentButtons.length) {
                        paymentButtons.removeClass('chkout-btn-img')
                    }
                }
              }
            }
            var $cartLimitedMsgCont = $('.product-info.uuid-' + pliUUID).find('.limited-inventory.cond-3');
            if (reqdata.savefromModal) {
              if (reqdata.selected === 'instore' && !data.storeId) {
                removeSelectStoreModal();
                triggerChangeStore(pliUUID);
              } else {
                updateStoreContent(data, pliUUID); // if event invoke is from modal, updates store html with radio
                removeSelectStoreModal(); // close modal on successful update
              }
            }
            if (data.limitedInventory && $cartLimitedMsgCont.length > 0) {
              $cartLimitedMsgCont.removeClass('d-none');
            } else {
              $cartLimitedMsgCont.addClass('d-none');
            }
            /*
                      if (data.basketModel.totals.totalShippingCostUnformatted == 0) {
                          $(".shipping-total-cost").empty().append(data.basketModel.totals.freeShippingText);
                      } else {
                          $(".shipping-total-cost").empty().append(data.basketModel.totals.totalShippingCost);
                      } */
            updateCartTotals(data.basketModel);

            setTimeout(function () {
              $.spinner().stop();
            }, 2000);
          },
          error: function () {
            $.spinner().stop();
          }
        });
      }
    });
  },
  /**
   * CUSTOM EVENT TRIGGER
   * on toggle of radio button in cart
   */
  selectStoreCart: function () {
    $('.cart-options').on('change', 'input:not(.change-store)', function (e) {
      const $this = $(this);
      // in input field has to change store event trigger required
      if (window.sessionStorage.getItem('change-store-click') === 'true') {
        e.preventDefault();
        window.sessionStorage.removeItem('change-store-click');
        return;
      }

      // prepare data
      var data = {
        url: $('.cart-options').data('toggle-url'),
        pliUUID: $this.closest('.cart-options').data('product-uuid') !== undefined ? $this.closest('.cart-options').data('product-uuid') : null,
        storeId: $this.attr('data-store-id') !== undefined && $this.attr('data-store-id') !== 'null' ? $this.attr('data-store-id') : null,
        selected: $this.val(),
        prodid: $this.closest('.cart-options').data('pid') !== undefined ? $this.closest('.cart-options').data('pid') : null,
        event: e
      };
      // Trigger bopus start when radio is toggled to pick up in store
      if ($this.val() === 'instore') {
        var quantity =
          $this.closest('.cart-options').data('product-qty') && $this.closest('.cart-options').data('product-qty') != 'null'
            ? $this.closest('.cart-options').data('product-qty')
            : '';
        $body.trigger('adobe:bopusStart', { pid: data.prodid, page: 'cart', quantity: quantity });
      }
      /** CUSTOM EVENT TRIGGER**/
      $body.trigger('store:cart', data);
    });
  },
  /**
   * Trigger bopus start when radio is toggled to pick up in store
   */
  triggerBopusEventCart: function () {
    $('.cart-options').on('click', 'input.change-store', function () {
      const $this = $(this);
      var selectedValue = $this.val();
      if (selectedValue === 'instore') {
        var quantity =
          $this.closest('.cart-options').data('product-qty') && $this.closest('.cart-options').data('product-qty') != 'null'
            ? $this.closest('.cart-options').data('product-qty')
            : '';
        var prodid = $this.closest('.cart-options').data('pid') !== undefined ? $this.closest('.cart-options').data('pid') : null;
        $body.trigger('adobe:bopusStart', { pid: prodid, page: 'cart', quantity: quantity });
      }
    });
  },
  /**
   * updates the store info on select of store from the modal.
   * non-transactional and updates html only.
   * USE CASE : change store with 'ship to' option selected
   * on toggle of radio button in cart
   */
  updateStoreCart: function () {
    $('body').on('store:changeStore', function (e, data) {
      var pliUUID = data.pliUUID;
      $.spinner().start();
      updateStoreContent(data, pliUUID); // if event invoke is from modal, updates store html with radio
      removeSelectStoreModal(); // close modal on successful update
      $.spinner().stop();
    });
  },
  /**
   * update the radio button to 'Ship to' if store selector modal is closed
   */
  switchShiptoOnModalClose: function () {
    function maybeSwitchShipTo() {
      var $container, $changeStoreLink;
      if ($('.btn-storelocator-search').data('shipto-selected') === 'instore' && $('.btn-storelocator-search').data('product-pliuuid') !== undefined) {
        $container = $('.cart-options[data-product-uuid="' + $('.btn-storelocator-search').data('product-pliuuid') + '"]');
        if ($container.length > 0 && $container.find('input[value=instore]').length > 0) {
          $changeStoreLink = $container
            .find('input[value=instore]')
            .siblings('label[for=instore_' + $('.btn-storelocator-search').data('product-pliuuid') + ']')
            .find('a');
          // only if Pick up in store has no store selected
          if (
            ($changeStoreLink.length === 0 && $changeStoreLink.data('store-set') === undefined) ||
            ($changeStoreLink.length > 0 && $changeStoreLink.hasClass('no-inventory'))
          ) {
            $changeStoreLink.removeClass('no-inventory');
            window.sessionStorage.removeItem('change-store-click');
            $container.find('input[value=shipto]').trigger('click');
          }
        }
      }
    }

    $('body').on('hidden.bs.modal', '#inStoreInventoryModal', function () {
      $('.modal-backdrop').remove();
      maybeSwitchShipTo();
    });

    //handle back navigation
    $(window).on('beforeunload', function () {
      if ($('#inStoreInventoryModal').hasClass('show')) {
        maybeSwitchShipTo();
      }
    });
  }
};
