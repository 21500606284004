'use strict';

/**
 * Get Card Type and their length
 * @param type
 * @returns {object} detectedType
 */
function creditCardType(type) {
  var card_types = [
    {
      name: 'amex',
      valid_length: [15]
    },
    {
      name: 'diners',
      valid_length: [14]
    },
    {
      name: 'jcb',
      valid_length: [16]
    },
    {
      name: 'visa',
      valid_length: [13, 16]
    },
    {
      name: 'mastercard',
      valid_length: [16]
    },
    {
      name: 'discover',
      valid_length: [16]
    },
    {
      name: 'unionPay',
      valid_length: [16]
    },
    {
      name: 'HBC',
      valid_length: [16]
    },
    {
      name: 'HBCMC',
      valid_length: [16]
    },
    {
      name: 'SAKSMC',
      valid_length: [16]
    },
    {
      name: 'SAKS',
      valid_length: [8, 10, 16]
    },
    {
      name: 'LAT',
      valid_length: [16]
    },
    {
      name: 'MPA',
      valid_length: [10]
    },
    {
      name: 'tcc',
      valid_length: [29]
    }
  ];
  var detectedType = card_types.find(function (cartType) {
    return cartType.name === type;
  });
  if (detectedType) {
    return detectedType;
  }
  return null;
}

module.exports = {
  validateCreditCardTypeLength: function (creditCard, type) {
    var cardType = creditCardType(type);
    if (cardType && cardType.valid_length) {
      var cc = creditCard.replace(/\D/g, '');
      var cardLength = cc.length;
      var enteredCardLength = cardType.valid_length.find(function (len) {
        return len === cardLength;
      });
      if (enteredCardLength) {
        return true;
      }
    }
    return false;
  }
};
