'use strict';

var klarnaClient = require('../klarnaClientUtils');
var atmHelper = require('../atm/atmHelper');

/** constants */
const $body = $('body');

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} message - Error message to display
 */
function createErrorNotification(message) {
  var errorInfo = atmHelper.buildErrorInfo([], 'cart error', message, 'server-side');
  $body.trigger('adobeTagManager:trackError', errorInfo);
  let errorHtml =
    '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
    'fade show" role="alert">' +
    '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
    '<span aria-hidden="true">&times;</span>' +
    '</button>' +
    message +
    '</div>';
  $('.cart-error').html(errorHtml);
}

/**
 * Updates the order summary on cart.
 * @param {Object} data - Updates the Total Savings value on Cart update.
 */
function updateOrderSummary(data) {
  var $cardSummary = $('.card.order-product-summary');

  if ($cardSummary.length > 0) {
    $cardSummary.html($(data.orderProductSummary).children());
  }
}

/**
 * Checks whether the basket is valid. if invalid displays error message and disables
 * checkout button
 * @param {Object} data - AJAX response from the server
 */
function validateBasket(data) {
  const $checkoutButtons = $('.checkout-btn, .proxy-checkout-btn');

  if (data.valid.error) {
    if (data.valid.message) {
      let errorHtml =
        '<div class="alert alert-danger alert-dismissible valid-cart-error ' +
        'fade show" role="alert">' +
        '<button type="button" class="close" data-dismiss="alert" aria-label="Close">' +
        '<span aria-hidden="true">&times;</span>' +
        '</button>' +
        data.valid.message +
        '</div>';
      $('.cart-error').html(errorHtml);
      const errorInfo = atmHelper.buildErrorInfo([], 'cart error', data.valid.message, 'server-side');
      $body.trigger('adobeTagManager:trackError', errorInfo);
    } else {
      $('.cart').html('<div class="row"><div class="col-12 text-center"><h1>' + data.resources.emptyCartMsg + '</h1></div></div>');
      $('.number-of-items').html(data.resources.numberOfItems);
      $('.minicart-quantity').html(data.numItems);
      $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
      });
      const $popover = $('.minicart .popover');

      $popover.empty();
      $popover.removeClass('show');
    }

    $checkoutButtons.addClass('disabled');
  } else {
    $checkoutButtons.removeClass('disabled');
  }
}

/**
 * Updates the Total Savings value on Cart update.
 * @param {Object} data - Updates the Total Savings value on Cart update.
 */
function updateTotalSavings(data) {
  if (data.totals.totalSavings.value > 0) {
    $('.estm-saved').removeClass('d-none');
    $('.total-savings').html(data.totals.totalSavings.formatted);
  } else {
    $('.estm-saved').addClass('d-none');
  }
}

/**
 * Updates the promo code count in the header of the Coupon Section
 * @param {Object} data - updates the promo code count in the header of the Coupon Section
 */
function updateAppliedCouponCount(data) {
  let html = '';
  $('.promo-code-form .form-control').removeClass('is-invalid');
  $('.coupon-error-message').empty();

  // eslint-disable-next-line radix
  if (parseInt(data.totalAppliedCoupons) === 0) {
    html = data.noCouponMsg;
    // eslint-disable-next-line radix
  } else if (parseInt(data.totalAppliedCoupons) === 1) {
    html = data.singleCouponMsg + ' (' + data.totalAppliedCoupons + ')';
  } else {
    html = data.multipleCouponMsg + ' (' + data.totalAppliedCoupons + ')';
  }
  $('.promo-heading.promo-label').html(html);
}

/**
 * replace content of modal
 * @param {Object} data - discount updation
 *
 */
function updateDiscountsHtml(data) {
  let productLineItems = data.items;
  productLineItems.forEach(function (productLineItem) {
    if (!productLineItem || !productLineItem.isBonusProductLineItem) {
      $('.price_discount-' + productLineItem.UUID).html(productLineItem.discountTotalHtml);
    }
  });
}

/**
 * re-renders the approaching discount messages
 * @param {Object} approachingDiscounts - updated approaching discounts for the cart
 */
function updateApproachingDiscounts(approachingDiscounts) {
  let html = '';
  if (approachingDiscounts.length > 0) {
    approachingDiscounts.forEach(function (item) {
      html += '<div class="single-approaching-discount text-center">' + item.discountMsg + '</div>';
    });
  }
  $('.approaching-discounts').html(html);
}


/**
 * Updates details of a product line item in the Cart Section
 * @param {Object} data - AJAX response from the server
 */
function updateItemsHTML(data) {
  if ($('.cart-page-content').length && $('.prdt-cart-details').length === 0 && data && data.items.length > 0) {
    window.location.reload();
  } else {
    $('.container.cart-page-content .cart-plis.product-line-item').empty().html($(data.itemsHTML).html());
    window.hbcTooltip.tooltipInit();
    window.lazyload.lazyLoadImages();
  }
}

/**
 * Update tax totals
 *
 * @param {Object} data - Tax totals data
 */
function updateTaxTotal(data) {
  const $taxPst = $('.tax-pst');
  const $taxGst = $('.tax-gst');
  const $taxQst = $('.tax-qst');
  const $taxRst = $('.tax-rst');
  const $taxEco = $('.tax-eco');
  const $taxNormal = $('.tax-normal');

  if (data.totals.canadaTaxation && data.totals.canadaTaxation.PST) {
    $('.tax-PST-total').html(data.totals.canadaTaxation.PST);
    $taxPst.removeClass('d-none');
  } else if (!$taxPst.hasClass('d-none')) {
    $taxPst.addClass('d-none');
  }

  if (data.totals.canadaTaxation && data.totals.canadaTaxation['GST/HST']) {
    $('.tax-GST-total').html(data.totals.canadaTaxation['GST/HST']);
    $taxGst.removeClass('d-none');
  } else if (!$taxGst.hasClass('d-none')) {
    $taxGst.addClass('d-none');
  }

  if (data.totals.canadaTaxation && data.totals.canadaTaxation.QST) {
    $('.tax-QST-total').html(data.totals.canadaTaxation.QST);
    $taxQst.removeClass('d-none');
  } else if (!$taxQst.hasClass('d-none')) {
    $taxQst.addClass('d-none');
  }

  if (data.totals.canadaTaxation && data.totals.canadaTaxation.RST) {
    $('.tax-RST-total').html(data.totals.canadaTaxation.RST);
    $taxRst.removeClass('d-none');
  } else if (!$taxRst.hasClass('d-none')) {
    $taxRst.addClass('d-none');
  }

  if (data.totals.canadaTaxation && data.totals.canadaTaxation.ECO) {
    $('.tax-ECO-total').html(data.totals.canadaTaxation.ECO);
    $taxEco.removeClass('d-none');
  } else if (!$taxEco.hasClass('d-none')) {
    $taxEco.addClass('d-none');
  }

  if (!data.totals.canadaTaxation) {
    $('.tax-total').html(data.totals.totalTax);
    $taxNormal.removeClass('d-none');
  } else if (!$taxNormal.hasClass('d-none')) {
    $taxNormal.addClass('d-none');
  }
}

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
  var newUrl = url;
  newUrl +=
    (newUrl.indexOf('?') !== -1 ? '&' : '?') +
    Object.keys(params)
      .map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
      })
      .join('&');

  return newUrl;
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
  const $shippingTotalCost = $('.shipping-total-cost');
  const $shippingMethodPrice = $('.shipping-method-price');

  $('.number-of-items').html(data.resources.numberOfItems);
  $shippingTotalCost.html(data.totals.totalShippingCost);

  $shippingMethodPrice.html('- ' + data.totals.totalShippingCost);
  updateTaxTotal(data);
  $('.grand-total-sum').html(data.totals.grandTotal);
  $('.grand-total-value').html(data.totals.grandTotalValue);
  klarnaClient.toggleKlarnaOrderSummary(data.totals.grandTotalValue, data.items);
  $body.trigger('checkout:updateApplicablePaymentMethods', [data.applicablePaymentMethods]);

  $('.sub-total').html(data.totals.subTotal);
  $('.mini-sub-total').html(data.totals.miniCartEstimatedTotal);
  $('.minicart-quantity').html(data.numItems);
  $('.minicart-link').attr({
    'aria-label': data.resources.minicartCountOfItems,
    title: data.resources.minicartCountOfItems
  });

  if (data.totals.orderLevelDiscountTotal.value > 0) {
    $('.order-discount').removeClass('hide-order-discount');
    $('.order-discount-total')
      .empty()
      .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
  } else {
    $('.order-discount').addClass('hide-order-discount');
    $('.order-discount-total')
      .empty()
      .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
  }
  if (data.totals.totalShippingCostUnformatted == 0 || data.totals.totalShippingCostUnformatted == data.totals.shippingLevelDiscountTotal.value) {
    $shippingTotalCost.html(data.totals.freeShippingText);
    $shippingMethodPrice.html('- ' + data.totals.freeShippingText);
  } else {
    $shippingTotalCost.html(data.totals.totalShippingCost);
  }
  if (data.totals.shippingLevelDiscountTotal.value > 0 && data.totals.totalShippingCostUnformatted != data.totals.shippingLevelDiscountTotal.value) {
    $('.shipping-discount').removeClass('hide-shipping-discount');
    $('.shipping-discount-total').html('- ' + data.totals.shippingLevelDiscountTotal.formatted);
  } else {
    $('.shipping-discount').addClass('hide-shipping-discount');
    $('.shipping-discount-total').html('- ' + data.totals.shippingLevelDiscountTotal.formatted);
  }
  // append hidden values for bfx-order discount. This tag excludes the product promo price unlike the above orderLevetotalDiscount
  if (data.totals.orderLevelDiscTotalExc.value >= 0) {
    $('.order-discount-exlc-total').html('- ' + data.totals.orderLevelDiscTotalExc.formatted);
  }
  // Update Retail Delivery Fee
  if (data.retailDeliveryFee && data.retailDeliveryFee.applied || data.retailDeliveryFee.newTotalTax) {
    $('.retail-delivery-fee-item').removeClass('d-none');
    $('.retail-delivery-fee-cost').text(data.retailDeliveryFee.charge);
    if (data.retailDeliveryFee.newTotalTax) {
      $('.tax-total').empty().append(data.retailDeliveryFee.newTotalTax);
    }
  } else {
    $('.retail-delivery-fee-item').addClass('d-none');
  }
  if (data.totals.totalSavings.value > 0) {
    $('.grand-total-saving-container').removeClass('d-none');
    $('.checkout-total-savings').html(data.totals.totalSavings.formatted);
  } else {
    $('.grand-total-saving-container').addClass('d-none');
  }

  data.items.forEach(function (item) {
    if (item.checkoutDiscountTotalHtml) {
      $('.checkout_discount-' + item.UUID).html(item.checkoutDiscountTotalHtml);
    }
    if (item.price.list !== null && item.price.list !== undefined) {
      $('.product-info[data-atm-selectedsku=' + item.id + ']').find('.sale-bfx-price').addClass('bfx-sale-price').removeClass('bfx-list-price');
      $('.prdt-section[data-atm-selectedsku=' + item.id + ']').find('.sale-bfx-price').addClass('bfx-sale-price').removeClass('bfx-list-price');
    }
    if (item.discountTotal.isDisount) {
      $('.product-info[data-atm-selectedsku=' + item.id + ']').find('.sale-bfx-price').removeClass('bfx-sale-price').addClass('bfx-list-price');
      $('.prdt-section[data-atm-selectedsku=' + item.id + ']').find('.sale-bfx-price').removeClass('bfx-sale-price').addClass('bfx-list-price');
    }
  });

  if (data.totals.associateOrFDDMsg !== '') {
    $('.associate-fdd-promo').removeClass('d-none');
    $('.associate-promo-msg').html(data.totals.associateOrFDDMsg);
  } else {
    $('.associate-fdd-promo').addClass('d-none');
  }

  if (data.totals.hudsonpoint > 0) {
    $('.hudson-reward-points .hudson-point').html(data.totals.hudsonpoint);
  } else {
    $('.hudson-reward-points').addClass('d-none');
  }

  try {
    $body.trigger('checkout:amexSummaryUpdate', data);

    if (data.totals.preorder && data.totals.preorder && data.totals.preorder.hasOnePreOrderItem) {
      let bfxpreorderhtml =
        '<div class="hidden d-none bfxpreorder"><div class="bfx-customData-label hidden d-none">PreOrder-CustomData</div>' +
        '<div class="bfx-customData-value hidden d-none">' +
        'RequestProcessed:Y,RequestAmount:' +
        data.totals.preorder.RequestAmount +
        ',ExtnTenderMaxLimit:' +
        data.totals.preorder.ExtnTenderMaxLimit +
        '</div></div>';
      $('.bfxpreorder').remove();
      $('.cart-summary-details').before(bfxpreorderhtml);
    }
  } catch (e) {}
}

module.exports = {
  createErrorNotification: createErrorNotification,
  updateOrderSummary: updateOrderSummary,
  validateBasket: validateBasket,
  updateTotalSavings: updateTotalSavings,
  updateAppliedCouponCount: updateAppliedCouponCount,
  updateDiscountsHtml: updateDiscountsHtml,
  updateApproachingDiscounts: updateApproachingDiscounts,
  updateItemsHTML: updateItemsHTML,
  updateTaxTotal: updateTaxTotal,
  appendToUrl: appendToUrl,
  updateCartTotals: updateCartTotals
};
