'use strict';

module.exports = function () {
  $('body').on('product:updateAddToCart', function (e, response) {
    const $productContainer = response.$productContainer;
    const limitedVarinatQtyUpdated = response.product.limitedVarinatQtyUpdated
      ? response.product.limitedVarinatQtyUpdated
      : !response.product.available && response.product.availability.ats > 0;
    if (!limitedVarinatQtyUpdated) {
      $('button.add-to-cart-global').text(response.product.availability.buttonName);
    }

    const $updateCartProductGlobal = $('.update-cart-product-global');

    if ($(e.target).closest('#editProductModal').length) {
      $updateCartProductGlobal.text($updateCartProductGlobal.data('update-text'));
    }

    $updateCartProductGlobal.attr('disabled', !response.product.available);

    if (!response.product.available && response.product.waitlistable) {
      $('.js-cart-update').addClass('d-none');
    } else {
      $('.js-cart-update').removeClass('d-none');
    }
    var isCartPageContent = $('.cart-page-content').length === 0;
    if (response.product.waitlistable && response.product.productType !== 'master' && !response.product.available && !limitedVarinatQtyUpdated) {
      const action = response.product.pdpURL + '#waitlistenabled';

      if ($('.page').data('producttype') === 'set') {
        if ($('body.cart-page').length && $productContainer.closest('#editProductModal').length) {
          window.formFields.adjustForAutofill();
        }
        $productContainer.find('.add-to-waitlist-link').attr('href', action);
        $productContainer.find('.add-to-cart-global').addClass('d-none');
        $productContainer.find('.js-add-to-cart ').find('.hideInWaitList').addClass('d-none');
        $productContainer.find('.update-cart-btn').addClass('d-none');
        $productContainer.find('.js-wait-list-form').removeClass('d-none');
        $productContainer.find('.js-add-to-waitlist-link').removeClass('d-none');
        $productContainer.find('.js-wait-list-form').find('.waitlist-product-id').val(response.product.id);
        $productContainer.find('.wait-list-success').empty();
        $productContainer.find('.wait-list-success').removeClass('d-none');
        $productContainer
          .find('.js-wait-list-form')
          .find('input.js-waitlist-email')
          .on('focus', function () { // TODO should it triggered only once?
            $('body').trigger('adobe:waitListStart');
          });
        $productContainer
          .find('.js-wait-list-form')
          .find('input.js-waitlist-email')
          .on('blur', function () {
            $productContainer.find('.js-wait-list-form').find('input.js-waitlist-email').off('focus');
          });
      } else {
        if ($('body.cart-page').length && $productContainer.closest('#editProductModal').length) {
          window.formFields.adjustForAutofill();
        }
        $productContainer.find('.add-to-waitlist-link').attr('href', action);
        $productContainer.find('.add-to-cart-global').addClass('d-none');
        $productContainer.find('.js-add-to-cart ').find('.hideInWaitList').addClass('d-none');
        $productContainer.find('.update-cart-btn').addClass('d-none');
        $productContainer.find('.js-wait-list-form').removeClass('d-none');
        if (isCartPageContent && ($productContainer.parent().attr('id') !== "completeTheLookDrawer")) {
          $('.js-add-to-waitlist-link').removeClass('d-none');
        }
        else {
          $productContainer.find('.js-waitlist-wrapper').removeClass('d-none');
        }
        $productContainer.find('.js-wait-list-form').find('.waitlist-product-id').val(response.product.id);
        $productContainer.find('.wait-list-success').empty();
        $productContainer.find('.wait-list-success').removeClass('d-none');
        $productContainer.find('.js-wait-list-form')
          .find('input.js-waitlist-email')
          .on('focus', function () {
            $('body').trigger('adobe:waitListStart');
          });
          $productContainer.find('.js-wait-list-form')
          .find('input.js-waitlist-email')
          .on('blur', function () {
            $('.js-wait-list-form').find('input.js-waitlist-email').off('focus');
          });
      }
    } else {
      if ($('.page').data('producttype') === 'set') {
        $productContainer.find('.add-to-cart-global').removeClass('d-none');
        $productContainer.find('.update-cart-btn').removeClass('d-none');
        $productContainer.find('.js-add-to-cart ').find('.hideInWaitList').removeClass('d-none');
        $productContainer.find('.js-add-to-waitlist-link').addClass('d-none');
        $productContainer.find('.js-wait-list-form').addClass('d-none');
        $productContainer.find('.add-to-cart-global').removeClass('d-none');
        $productContainer.find('.wait-list-success').empty();
        $productContainer.find('.wait-list-success').addClass('d-none');
      } else {
        $productContainer.find('.add-to-cart-global').removeClass('d-none');
        $productContainer.find('.update-cart-btn').removeClass('d-none');
        $productContainer.find('.js-add-to-cart ').find('.hideInWaitList').removeClass('d-none');
        $productContainer.find('.js-add-to-waitlist-link').addClass('d-none');
        $productContainer.find('.js-wait-list-form').addClass('d-none');
        $productContainer.find('.wait-list-success').addClass('d-none');
        if (isCartPageContent && ($productContainer.parent().attr('id') === "completeTheLookDrawer")) {
          $('.complete-the-look.modal .js-waitlist-wrapper').removeClass('d-none');
        }
          $productContainer.find('.wait-list-success').empty();
      }
    }
  });
};
