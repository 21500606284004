'use strict';

var base = {};
var storeLocator = require('../storeLocator/storeLocator');

/**
 * Updates the product shipping option DOM elements post Ajax call
 * @param {UpdatedQuantity[]} data - response of an Ajax
 * @param {jQuery} $productContainer - DOM container for a given product
 */
var updateShippingOptions = function (data, $productContainer) {
  var $storeDataElement = $productContainer.find('.shipping-option');
  var product = data.product;
  const $productPis = $storeDataElement.find('.product-pis');
  if (data && data.isBopisEnabled && $storeDataElement.length > 0) {
    // show elements if bopis is enabled
    $productPis.removeClass('change-store');
    $storeDataElement.show();
    // update store id in DOM
    if (data.storeInfo) {
      $productPis.attr('data-store-id', data.storeInfo.ID);
    }
    // update store change link
    const $changeStore = $('a.change-store');
    if ($changeStore.length > 0) {
      if (data.storeInfo && $storeDataElement.attr('data-changestore-text')) {
        $changeStore.text($storeDataElement.attr('data-changestore-text'));
      } else if ($storeDataElement.attr('data-choosestore-text')) {
        $changeStore.text($storeDataElement.attr('data-choosestore-text'));
      }
    }
    // set add to bag button text
    var selectedValue = $storeDataElement.find('input[name=shipto]:checked').val();
    var addToStoreText = $storeDataElement.data('addtostore-text');
    var addToCartButton = $storeDataElement.closest('.product-detail').find('.add-to-cart');
    if (addToStoreText !== undefined && addToStoreText.length && addToStoreText !== '') {
      if (selectedValue === 'instore') {
        addToCartButton.html(addToStoreText);
      }
    }
    if (product.productType !== 'variant') {
      // product is a master
      $storeDataElement.find('input[value=instore]').attr('disabled', true);
      $storeDataElement.find('.store-change-link').hide();
    } else if (data.storeInfo && (data.storeInfo.unitsAtStores === 0 || data.storeInfo.unitsAtStores < product.selectedQuantity)) {
      // product is variant and units are unavailable
      if (data.availabilityMessage) {
        $storeDataElement.find('.store-info').text(data.availabilityMessage);
      }
      if (data.storeInfo.distanceInUnits && data.storeInfo.distanceInUnits !== '0') {
        $storeDataElement.find('.distance-info').text(data.storeInfo.distanceInUnits);
      }
      $storeDataElement.find('input[value=instore]').attr('disabled', true);
      $storeDataElement.find('.store-change-link').show();
      if ($storeDataElement.find('input[name=shipto]:checked').val() === 'instore') {
        $storeDataElement.find('input[value=shipto]').trigger('click');
      }
    } else {
      // product is variant and units are available
      $productPis.removeClass('change-store');
      if (data.storeInfo && data.storeInfo.distanceInUnits && data.storeInfo.distanceInUnits !== '0') {
        $storeDataElement.find('.distance-info').text(data.storeInfo.distanceInUnits);
      }

      if (data.storeInfo && data.availabilityMessage) {
        $storeDataElement.find('.store-info').text(data.availabilityMessage);
      } else {
        $storeDataElement.find('.store-info').text(data.availabilityPromptText);
        $productPis.addClass('change-store');
      }
      $storeDataElement.find('input[value=instore]').removeAttr('disabled');
      $storeDataElement.find('.store-change-link').show();
    }
  } else {
    // hide elements if bopis is disabled
    $productPis.removeClass('change-store');
    $storeDataElement.hide();
  }
};

/**
 * Sets the data attribute of Quantity Selector to save its original state.
 * @param {HTMLElement} $quantitySelect - The Quantity Select Element
 */
function setOriginalQuantitySelect($quantitySelect) {
  if (!$quantitySelect.data('originalHTML')) {
    $quantitySelect.data('originalHTML', $quantitySelect.html());
  } // If it's already there, don't re-set it
}

/**
 * Update quantity options. Only display quantity options that are available for the store.
 * @param {sring} searchPID - The product ID of the selectedx product.
 * @param {number} storeId - The store ID selected for in store pickup.
 * @param {number} storeDistance - The store distance of the store selected for in store pickup.
 * @param {string} searchPlid - unique id of an item
 */
function updateQuantityOptions(searchPID, storeId, storeDistance, searchPlid) {
  var selectorPrefix = '.product-detail[data-pid="' + searchPID + '"]';
  var quantitySelector = selectorPrefix + ' .quantity-select';

  setOriginalQuantitySelect($(quantitySelector));

  var requestData = {
    pid: searchPID,
    quantitySelected: $(quantitySelector).val(),
    storeId: storeId,
    storeDistance: storeDistance,
    searchPlid: searchPlid,
    savetosession: true
  };

  $.ajax({
    url: $('.store-change-link').data('setstore'),
    data: requestData,
    method: 'GET',
    success: function (response) {
      var $productContainer = $('.product-detail[data-pid="' + searchPID + '"]');
      $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
        product: response.product,
        $productContainer: $productContainer
      });
      // update shipping option elements
      updateShippingOptions(response, $productContainer);
    }
  });
}

/**
 * Bopis selection after page load
 */
function bopisSelectionByDefault() {
  const $shippingOption = $('.shipping-option');
  var shippingOptions = $shippingOption.find('input');
  if (shippingOptions && shippingOptions.length && shippingOptions.length>1) {
    var bopisOption = shippingOptions[1];
    if (bopisOption && bopisOption.checked) {
      var addToStoreText = $shippingOption.data('addtostore-text');
      var addToCartButton = $('.product-detail').find('.add-to-cart');
      addToCartButton.html(addToStoreText);
      $('body').trigger('adobe:bopusStart');
      $('.zip-information .available-messaging').addClass('d-none');
    }
  }
}

/**
 * Postal code formatting for in store
 */
function bopisPostalCodeValidation() {
  $('#store-postal-code').on('keyup', function () {
    const $this = $(this);
    var postalCode = $this.val();
    if (/[a-zA-Z][0-9][a-zA-Z]/.test(postalCode.substring(0, 3))) {
      if (postalCode.length === 4 && postalCode.indexOf(' ') === 3) {
        return;
      }
      if (postalCode.length === 5 && postalCode.lastIndexOf(' ') === 4) {
        $this.val(postalCode.substring(0, postalCode.length - 1));
        return;
      }
      var formattedString = postalCode
        .replace(/ /g, '')
        .replace(/(\w{3})/, '$1 ')
        .replace(/(^\s+|\s+$)/, ' ')
        .toUpperCase()
        .trim();
      $this.val(formattedString);
    }
  });
}

/**
 * Generates the modal window on the first call. Modal head and
 * main text is appended through parameter
 * @param {string} modalBodyText - main text in the modal
 * @param {string} modalCloseText - close title
 * @param {string} modalHeadText - modal header text
 */
function getModalHtmlElement(modalBodyText, modalCloseText, modalHeadText, event) {
  const $inStoreInventoryModal = $('#inStoreInventoryModal');
  const $storeChangeLink = $('.store-change-link');
  if ($inStoreInventoryModal.length !== 0) {
    $inStoreInventoryModal.modal('hide').remove();
  }
  var modalHead = modalHeadText !== undefined ? modalHeadText : $storeChangeLink.data('modal-header-text'); // fallback to get content from DOM, PDP
  var modalClose = modalCloseText !== undefined ? modalCloseText : $storeChangeLink.data('modal-header-text'); // fallback to get content from DOM, PDP
  var modalBody = modalBodyText !== undefined ? modalBodyText : $storeChangeLink.data('modal-body-text'); // fallback to get content from DOM, PDP
  // set empty to avoid display of null
  modalHead = modalHead === null ? '' : modalHead;
  modalClose = modalClose === null ? '' : modalClose;
  modalBody = modalBody === null ? '' : modalBody;
  var htmlString =
    '<!-- Modal -->' +
    '<div class="modal inStoreInventoryModal" id="inStoreInventoryModal" role="dialog" aria-modal="true">' +
    '<div class="modal-dialog in-store-inventory-dialog">' +
    '<!-- Modal content-->' +
    '<div class="modal-content">' +
    '<div class="modal-header">' +
    '<input type="text" class="set-focus" id="setFocus" />' +
    '<span>' +
    modalHead +
    '</span>' +
    '    <button type="button" class="close svg-36-avenue-Up_Copy svg-36-avenue-Up_Copy-dims" data-dismiss="modal" aria-label="Close inventory modal" title="' +
    modalClose +
    '">' + // eslint-disable-line
    '    </button>' +
    '</div>' +
    '<div class="change-a-store">' +
    modalBody +
    '</div>' +
    '<div class="modal-body"></div>' +
    '<div class="modal-footer"></div>' +
    '</div>' +
    '</div>' +
    '</div>';
  $('body').append(htmlString);
  $('#inStoreInventoryModal').modal('show');
  $('.change-store[aria-haspopup=true]').attr('aria-controls', 'inStoreInventoryModal');
}

/**
 * Remove the selected store.
 * @param {HTMLElement} $container - the target html element
 */
function deselectStore($container) {
  var $storeElement = $($container).find('.selected-store-with-inventory');
  $storeElement.find('.card-body').empty();
  $storeElement.addClass('display-none');
  $storeElement.find('.btn-get-in-store-inventory').show();
  $storeElement.find('.quantity-select').removeData('originalHTML');
}

/**
 * Replaces the content in the modal window with find stores components and
 * the result store list.
 * @param {string} pid - The product ID to search for
 * @param {number} quantity - Number of products to search inventory for
 * @param {number} selectedPostalCode - The postal code to search for inventory
 * @param {number} selectedRadius - The radius to search for inventory
 * @param {string} pliUUID - unid id of an item
 * @param {string} selectedOption - selected option between shipto and instore
 * @param {string} instoreFieldLabel - default lable for instore
 * @param {string} source - source of invoke
 */
function fillModalElement(pid, quantity, selectedPostalCode, selectedRadius, pliUUID, selectedOption, instoreFieldLabel, source) {
  var requestData = {};
  if (pid && quantity) {
    requestData.products = pid + ':' + quantity;
  }
  if (selectedRadius) {
    requestData.radius = selectedRadius;
  }

  if (selectedPostalCode) {
    requestData.postalCode = selectedPostalCode;
  }

  const $inStoreInventoryModal = $('#inStoreInventoryModal');
  $inStoreInventoryModal.spinner().start();
  $.ajax({
    url: $('.change-store').data('open-action'),
    data: requestData,
    method: 'GET',
    success: function (response) {
      const $productDetailsPage = $('.product-details-page');

      $inStoreInventoryModal.find('.modal-body').html(response.storesResultsHtml);
      storeLocator.search();
      storeLocator.changeRadius();
      $productDetailsPage && $productDetailsPage.length > 0 ? window.formFields.updateSelect(true) : window.formFields.updateSelect();
      window.formFields.adjustForAutofill();

      const $btnStoreLocatorSearch = $('.btn-storelocator-search');

      $btnStoreLocatorSearch
        .attr('data-search-pid', pid)
        .attr('data-product-pliuuid', pliUUID)
        .attr('data-shipto-selected', selectedOption)
        .attr('data-instorefieldlabel', instoreFieldLabel);
      $inStoreInventoryModal.find('#setFocus').remove();
      $btnStoreLocatorSearch.attr('data-source', source);
      if (selectedRadius) {
        $('#radius').val(selectedRadius);
      }

      if (selectedPostalCode) {
        $('#store-postal-code').val(selectedPostalCode);
      }

      const $result = $('.results');
      const $storeLocatorNoResult = $('.store-locator-no-results');

      if (!$result.data('has-results')) {
        $storeLocatorNoResult.show();
      }
      // do not show error message with the modal open
      if (!$result.data('searchexecuted')) {
        $storeLocatorNoResult.hide();
      }
      $inStoreInventoryModal.modal('show');
      $inStoreInventoryModal.spinner().stop();
      $inStoreInventoryModal.find('#store-postal-code').focus();
      bopisPostalCodeValidation();
    },
    error: function () {
      $inStoreInventoryModal.spinner().stop();
    }
  });
}

/**
 * CLICK EVENT
 * event handler triggered with the click of change store in PDP
 */
base.changeStore = function () {
  $('body').on('click', '.change-store, .change-zipcode', function (e) {
    const $this = $(this);
    const $inStore = $('#instore');
    const isStoreDisabled = $inStore.attr('disabled');

    if ($this.prop('tagName') === 'A' && ($inStore.length && (!isStoreDisabled && !$inStore.prop('checked')))) {
      return;
    }

    window.sessionStorage.setItem('change-store-click', true);

    const $productDetail = $this.closest('.product-detail');
    const $cartOptions = $this.closest('.cart-options');
    var pid = $productDetail.attr('data-pid') === undefined ? $this.closest('.cart-options').data('product-id') : $productDetail.attr('data-pid');
    var quantity = $productDetail.find('.quantity-select').val() === undefined ? $this.closest('.cart-options').data('product-qty') : $this.closest('.product-detail').find('.quantity-select').val();
    var modalBodyText = $cartOptions.data('modal-body-text');
    var modalCloseText = $cartOptions.data('modal-close-text');
    var modalHeadText = $cartOptions.data('modal-header-text');
    var selectedOption = $cartOptions.find('input[name*="shipto"]:checked').val();
    var pliUUID = $cartOptions.data('product-uuid');
    var instoreFieldLabel = $cartOptions.data('instorefieldlabel');
    getModalHtmlElement(modalBodyText, modalCloseText, modalHeadText, e);
    $('#inStoreInventoryModal').find('input:visible:enabled:first').focus();
    var source = $('.change-store').parent().closest('.store-refine').length > 0 ? 'search' : null;
    fillModalElement(pid, quantity, $this.data('postal'), $this.data('radius'), pliUUID, selectedOption, instoreFieldLabel, source);
  });
};

/**
 * Changes add to bag text dynamically on toggle of shipping options
 */
base.changeButtonText = function () {
  $('.shipping-option').on('click', 'input:not(.change-store)', function () {
    const $this = $(this);
    const $shippingOption = $('.shipping-option');
    var selectedValue = $this.val();
    var addToBagText = $shippingOption.data('addtobag-text');
    var addToStoreText = $shippingOption.data('addtostore-text');
    var addToCartButton = $this.closest('.product-detail').find('.add-to-cart');
    if (addToStoreText !== undefined && addToStoreText.length && addToStoreText !== '') {
      if (selectedValue === 'instore') {
        addToCartButton.html(addToStoreText);
        $('body').trigger('adobe:bopusStart');
        $('.zip-information .available-messaging').addClass('d-none');
      } else {
        $('.zip-information .available-messaging').removeClass('d-none');
        addToCartButton.html(addToBagText);
      }
    }
  });
};

/**
 * CUSTOM EVENT
 * event handler triggered with the store selection on modal
 */
base.selectStoreWithInventory = function () {
  $('body').on('store:selected', function (e, data) {
    const $btnStoreLocatorSearch = $('.btn-storelocator-search');
    var searchPID = $btnStoreLocatorSearch.attr('data-search-pid');
    var searchPlid = $btnStoreLocatorSearch.attr('data-product-pliuuid');
    const $productDetail = $('.product-detail[data-pid="' + searchPID + '"]');
    var storeElement = $productDetail === undefined ? $('.cart-options[data-pid="' + searchPID + '"]') : $productDetail; // fall back from cart, pick product id
    var $changeStoreButton = $(storeElement).find('.change-store');
    $changeStoreButton.data('postal', data.searchPostalCode);
    $changeStoreButton.data('radius', data.searchRadius);
    updateQuantityOptions(searchPID, data.storeID, data.storeDistance, searchPlid);

    $('#inStoreInventoryModal')
        .attr('data-event-trigger', 'store')
        .attr('aria-modal', 'false')
        .modal('hide')
        .remove();
  });
};

/**
 * CLICK EVENT
 * event handler triggered with the click of change store in PDP
 */
base.updateAddToCartFormData = function () {
  $('body').on('updateAddToCartFormData', function (e, form) {
    var storeID = null; // eslint-disable-line
    var instoreStoreID;
    if (form.pidsObj) {
      var pidsObj = JSON.parse(form.pidsObj);
      pidsObj.forEach(function (product) {
        var storeElement = $('.product-detail[data-pid="' + product.pid + '"]').find('.shipping-option');
        $(':checked', '.shipping-option').val();
        instoreStoreID =
          $(storeElement).length && $(storeElement).find('input[name*="shipto"]').length
            ? $('input[name*="shipto"]:checked', '.shipping-option').data('store-id')
            : null;
        product.storeId = instoreStoreID && instoreStoreID !== undefined && instoreStoreID !== '' ? instoreStoreID : null; // eslint-disable-line
        storeID = product.storeId;
      });

      form.pidsObj = JSON.stringify(pidsObj); // eslint-disable-line no-param-reassign
    }

    var storeElement = $('.product-detail[data-pid="' + form.pid + '"]').find('.shipping-option');

    instoreStoreID =
      $(storeElement).length && $(storeElement).find('input[name*="shipto"]').length ? $('input[name*="shipto"]:checked', storeElement).attr('data-store-id') : null;
    instoreStoreID = instoreStoreID && instoreStoreID !== undefined && instoreStoreID !== '' ? instoreStoreID : null;

    if (instoreStoreID) {
      form.storeId = instoreStoreID; // eslint-disable-line
    }
  });
};

/**
 * Assign aria-checked boolean values to radio group items based on user input
 */
base.selectRadioFromGroupList = function () {
  $('.radio-group-list[role=radiogroup]').on('click', '.radio-group-trigger', function () {
    var $this = $(this);
    $this.closest('.radio-group-list').find('[role=radio]').attr('aria-checked', 'false');
    $this.closest('[role=radio]').attr('aria-checked', 'true');
  });
};

/**
 * Trigger bopus start when radio is toggled to pick up i store
 */
base.triggerBopusEvent = function () {
  $('.shipping-option').on('click', 'input.change-store', function () {
    var selectedValue = $(this).val();
    if (selectedValue === 'instore') {
      $('body').trigger('adobe:bopusStart');
    }
  });
  $('.shipping-option').on('click', 'a.change-store', function () {
    $('body').trigger('adobe:bopusStart');
  });
};

/**
 * update the radio button to 'Ship to' if store selector modal is closed
 */
base.switchShiptoOnModalClosePDP = function () {
  $('body').on('hidden.bs.modal', '#inStoreInventoryModal', function () {
    var $container = $('.shipping-option');
    const $shipTo = $container.find('input[name=shipto]:checked');

    if ($shipTo.val() === 'instore') {
      const $inStore = $container.find('input[value=instore]');

      if ($inStore.length > 0) {
        const storeId = $inStore.attr('data-store-id');
        const $inStoreInventoryModal = $('#inStoreInventoryModal');
        const eventTrigger = $inStoreInventoryModal.attr('data-event-trigger');

        // only if Pick up in store has no store selected
        if ((storeId === undefined || storeId === '') && !eventTrigger) {
          $container.find('input[value=shipto]').trigger('click');
        }
      }
    }
  });
};

base.updateSelectStore = function () {
  $('body').on('product:updateAddToCart', function (e, response) {
    $('.btn-get-in-store-inventory', response.$productContainer).attr(
      'disabled',
      !response.product.readyToOrder || !response.product.available || !response.product.availableForInStorePickup
    );
  });
};

base.removeSelectedStoreOnAttributeChange = function () {
  $('body').on('product:afterAttributeSelect', function (e, response) {
    response.container.attr('data-pid', response.data.product.id);
    deselectStore(response.container);
  });
};

base.showInStoreInventory = function () {
  $('.btn-get-in-store-inventory').on('click', function (e) {
    const $this = $(this);
    var pid = $this.closest('.product-detail').attr('data-pid');
    var quantity = $this.closest('.product-detail').find('.quantity-select').val();
    getModalHtmlElement();
    fillModalElement(pid, quantity);
    e.stopPropagation();
  });
};

base.removeStoreSelection = function () {
  $('body').on('click', '#remove-store-selection', function () {
    const $productDetail = $(this).closest('.product-detail');
    deselectStore($productDetail);
    $(document).trigger('store:afterRemoveStoreSelection', $productDetail.find('.quantity-select'));
  });
};

/**
 * trigger click on enter key with store search
 */
base.triggerClickOnEnter = function () {
  $('body').on('keydown', '#store-postal-code', function (event) {
    var keyCode = event.keyCode ? event.keyCode : event.which;
    if (keyCode == 13) {
      $('.btn-storelocator-search').trigger('click');
    }
  });
};


base.setMyHomeStore = storeLocator.setMyHomeStore();
base.selectStore = storeLocator.selectStore();
base.updateSelectStoreButton = storeLocator.updateSelectStoreButton();
base.updateBopisDefaulSetection = bopisSelectionByDefault();

module.exports = base;
