/* globals google */
'use strict';
var base = require('base/storeLocator/storeLocator');

/**
 * appends params to a url
 * @param {string} url - Original url
 * @param {Object} params - Parameters to append
 * @returns {string} result url with appended parameters
 */
function appendToUrl(url, params) {
  var newUrl = url;
  newUrl +=
    (newUrl.indexOf('?') !== -1 ? '&' : '?') +
    Object.keys(params)
      .map(function (key) {
        return key + '=' + encodeURIComponent(params[key]);
      })
      .join('&');

  return newUrl;
}

/**
 * Uses google maps api to render a map
 */
function maps() {
  var map;
  var infowindow = new google.maps.InfoWindow();

  // Init U.S. Map in the center of the viewport
  var latlng = new google.maps.LatLng(37.09024, -95.712891);
  var mapOptions = {
    scrollwheel: false,
    zoom: 4,
    center: latlng
  };

  map = new google.maps.Map($('.map-canvas')[0], mapOptions);
  var mapdiv = $('.map-canvas').attr('data-locations');

  mapdiv = JSON.parse(mapdiv);

  var bounds = new google.maps.LatLngBounds();

  // Customized google map marker icon with svg format
  var markerImg = {
    path:
      'M13.5,30.1460153 L16.8554555,25.5 L20.0024287,25.5 C23.039087,25.5 25.5,' +
      '23.0388955 25.5,20.0024287 L25.5,5.99757128 C25.5,2.96091298 23.0388955,0.5 ' +
      '20.0024287,0.5 L5.99757128,0.5 C2.96091298,0.5 0.5,2.96110446 0.5,5.99757128 ' +
      'L0.5,20.0024287 C0.5,23.039087 2.96110446,25.5 5.99757128,25.5 L10.1445445,' +
      '25.5 L13.5,30.1460153 Z',
    fillColor: '#0070d2',
    fillOpacity: 1,
    scale: 1.1,
    strokeColor: 'white',
    strokeWeight: 1,
    anchor: new google.maps.Point(13, 30),
    labelOrigin: new google.maps.Point(12, 12)
  };

  Object.keys(mapdiv).forEach(function (key) {
    var item = mapdiv[key];
    var lable = parseInt(key, 10) + 1;
    var storeLocation = new google.maps.LatLng(item.latitude, item.longitude);
    var marker = new google.maps.Marker({
      position: storeLocation,
      map: map,
      title: item.name,
      icon: markerImg,
      label: {
        text: lable.toString(),
        color: 'white',
        fontSize: '16px'
      }
    });

    marker.addListener('click', function () {
      infowindow.setOptions({
        content: item.infoWindowHtml
      });
      infowindow.open(map, marker);
    });

    // Create a minimum bound based on a set of storeLocations
    bounds.extend(marker.position);
  });
  // Fit the all the store marks in the center of a minimum bounds when any store has been found.
  if (mapdiv && mapdiv.length !== 0) {
    map.fitBounds(bounds);
  }
}

/**
 * Renders the results of the search and updates the map
 * @param {Object} data - Response from the server
 */
function updateStoresResults(data) {
  var $resultsDiv = $('.results');
  var $mapDiv = $('.map-canvas');
  var hasResults = data.stores.length > 0;

  if (!hasResults) {
    $('.store-locator-no-results').show();
  } else {
    $('.store-locator-no-results').hide();
  }

  $resultsDiv.empty().data('has-results', hasResults).data('radius', data.radius).data('search-key', data.searchKey);

  $mapDiv.attr('data-locations', data.locations);

  if ($mapDiv.data('has-google-api')) {
    maps();
  } else {
    $('.store-locator-no-apiKey').show();
  }

  if (data.storesResultsHtml) {
    $resultsDiv.append(data.storesResultsHtml);
  }
}

/**
 * Search for stores with new zip code
 * @param {HTMLElement} element - the target html element
 * @returns {boolean} false to prevent default event
 */
function search(element) {
  var dialog = element.closest('.in-store-inventory-dialog');
  var spinner = dialog.length ? dialog.spinner() : $.spinner();
  spinner.start();
  var $form = element.closest('.store-locator');
  var radius = $('#inStoreInventoryModal').find('#radius option:selected').text();
  if (radius !== undefined && radius.length) {
    radius = radius.split(' ')[0];
  }
  var url = $form.attr('action');
  var urlParams = {
    radius: radius
  };
  var payload = $form.is('form')
    ? $form.serialize()
    : {
        postalCode: $form.find('[name="postalCode"]').val()
      };
  // extra payload carrying the product ids with quantity in json serialized
  if (
    element.data('products') &&
    element.data('products') !== undefined &&
    element.data('products') !== 'null' &&
    element.data('products').indexOf('undefined') === -1
  ) {
    payload.products = element.data('products');
  }
  url = appendToUrl(url, urlParams);

  $.ajax({
    url: url,
    type: $form.attr('method'),
    data: payload,
    dataType: 'json',
    success: function (data) {
      spinner.stop();
      if ($('body').find('.product-detail').length) {
        $('body').trigger('adobe:bopusSearch', data);
      }
      if ($('body').find('.prdt-cart-details').length) {
        var pid = $('.btn-storelocator-search').data('search-pid');
        data.pid = pid;
        $('body').trigger('adobe:bopusCartSearch', data);
      }

      updateStoresResults(data);
      $('.select-store').prop('disabled', true);
    }
  });
  return false;
}

/**
 * CUSTOM EVENT TRIGGER
 * trigger a custom event when store is selected on the store locator modal
 */
base.selectStore = function () {
  $('.store-locator-container').on('click', '.select-store', function (e) {
    e.preventDefault();
    var selectedStore = $(':checked', '.results-card .results');
    var data = {
      storeID: selectedStore.val(),
      searchRadius: $('#radius').val(),
      searchPostalCode: $('.results').data('search-key').postalCode,
      storeDetailsHtml: selectedStore.siblings('label').find('.store-details').html(),
      storeDistance: selectedStore.data('store-distance') !== undefined ? selectedStore.data('store-distance') : '',
      event: e
    };
    /** ** CUSTOM EVENT TRIGGER ****/
    if ($('.btn-storelocator-search').data('product-pliuuid') !== undefined && $('.btn-storelocator-search').data('product-pliuuid') !== 'null') {
      // store select cart
      if ($('.btn-storelocator-search').data('shipto-selected') === 'shipto') {
        // update pli with store info if 'Ship to' is toggled
        data.pliUUID = $('.btn-storelocator-search').data('product-pliuuid');
        data.storeName = selectedStore.data('store-info') !== undefined ? selectedStore.data('store-info').name : null;
        data.inStoreFieldLabel = $('.btn-storelocator-search').data('instorefieldlabel');
        data.storeId = selectedStore.val();
        data.prodid = $('.btn-storelocator-search').data('search-pid');
        $('body').trigger('store:changeStore', data);
      } else if ($('.btn-storelocator-search').data('shipto-selected') === 'instore') {
        // // update pli with store info if 'Pick up in store' is toggled
        data.storeId = selectedStore.val();
        data.pliUUID = $('.btn-storelocator-search').data('product-pliuuid');
        data.url = $('.cart-options').data('toggle-url');
        data.savefromModal = true;
        data.selected = 'instore';
        data.prodid = $('.btn-storelocator-search').data('search-pid');
        $('body').trigger('store:cart', data);
        if ($('.btn-storelocator-search').closest('.prdt-cart-details').length) {
          $('body').trigger('adobe:bopusCartSelect', data);
        }
      }
    } else if ($('.btn-storelocator-search').data('source') === 'search') {
      // store select search page
      data.url = $('.store-refine').data('storeurl');
      data.name = selectedStore.data('store-info').name;
      $('body').trigger('store:selectedSearch', data);
    } else {
      // store select PDP
      $('body').trigger('store:selected', data);
    }
  });
};

/**
 * CLICK AND SUBMIT EVENT
 * event handler triggered with store search on store locator modal
 */
base.search = function () {
  $('.store-locator-container form.store-locator').submit(function (e) {
    e.preventDefault();
    search($(this));
  });
  $('.store-locator-container .btn-storelocator-search[type="button"]').click(function (e) {
    e.preventDefault();
    var input = $('#inStoreInventoryModal').find('#store-postal-code');
    var inputVal = input.val();
    // Some special characters to be allowed for City Search. SFDEV-7932
    var validate = /^[a-zA-ZÃãîéèêô'¨.0-9- ]*$/.test(inputVal) === false;
    if (validate) {
      $('#inStoreInventoryModal').find('.store-locator').addClass('error');
      var formData = {};
      formData.errorFields = ['postalCode'];
      formData.formName = 'bopus search';
      $('body').trigger('adobeTagManager:formError', formData);
    } else if (inputVal === '') {
      $('#inStoreInventoryModal').find('.store-locator').addClass('error');
      input.focus();
      var formData = {};
      formData.errorFields = ['postalCode'];
      formData.formName = 'bopus search';
      $('body').trigger('adobeTagManager:formError', formData);
    } else {
      search($(this));
      $('#inStoreInventoryModal').find('.store-locator').removeClass('error');
    }
  });
};
base.changeRadius = function () {
  $('.store-locator-container .radius').change(function () {
    // do nothing
  });
};
base.updateSelectStoreButton = function () {
  $('body').on('change', '.select-store-input', function () {
    var storeInfo = $(this).data('store-info');
    if (
      storeInfo &&
      storeInfo != '' &&
      storeInfo.availabilityMessage &&
      storeInfo.availabilityMessage != '' &&
      storeInfo.availabilityMessage.indexOf('Not') > -1
    ) {
      $('.select-store').prop('disabled', true);
    } else {
      $('.select-store').prop('disabled', false);
    }
  });
};

module.exports = base;
